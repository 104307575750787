import React, { useContext, useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';
import { Col, Row, Form, Select, message, Checkbox } from 'antd';
import './style.scss';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { isObject, keys as keycreate, sample } from 'lodash';
import { PrimaryButton } from '../../Commons';
import { requiredRule, flatten } from '../../Commons/utils';
import { api } from '../../Http';
import { fetchWorkflowById } from '../../Http/workflow';

const QuickbooksInvoiceForm = (props) => {
  const { sampleData, sampleData2, setActionData, mappings, additionalQuery, setAdditionalQuery, setMappings, setOrganisation, form } = useContext(WorkflowContext);
  const { id, next } = useContext(StepContext);
  const params = useParams();
  const [workflow, setWorkflow] = useState({});
  const [checkboxStatus, setCheckboxStatus] = useState();

  const [keys, setKeys] = useState([]);
  const [keys2, setKeys2] = useState([]);
  const [returnMap, setReturnMap] = useState(false);

  const sentToContactOpt = [
    { label: 'True', value: true },
    { label: 'False', value: false },
  ];

  const lineAmountTypesOpt = [
    { label: 'EXCLUSIVE', value: 'Exclusive' },
    { label: 'INCLUSIVE', value: 'Inclusive' },
    { label: 'NOTAX', value: 'NoTax' },
  ];
  const lineItemDetailTypeOpt = [
    { label: 'Sales Item Line Detail', value: 'SalesItemLineDetail' }
  ];

  const statusOpt = [
    { label: 'DRAFT', value: 'DRAFT' },
    { label: 'SUBMITTED', value: 'SUBMITTED' },
    { label: 'AUTHORISED', value: 'AUTHORISED' },
  ];

  useEffect(() => {
    if (sampleData) {
      setKeys(keycreate(sampleData));
    }
    if (sampleData2) {
      setKeys2(keycreate(sampleData2));
    }
    // console.log({mappings, sampleData, sampleData2});
    let tmp = {};
    if (sampleData && sampleData2) {

      mappings?.forEach((v) =>
        (v.type === 'TRIG')
          ?
          (
            tmp[v.field] = [`${v.valueStepOrder}.${v.value}`],
            tmp[v.label] = (v.valueStepOrder === 1) ? `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}` : `${v.valueStepOrder}.${v.value}: ${sampleData2[v.value]}`
          )
          :
          (v.type === 'ACT')
            ?
            (

              tmp[v.value] = [`${v.valueStepOrder}.${v.field}`],
              tmp[v.label] = (v.valueStepOrder === 1) ? `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}` : `${v.valueStepOrder}.${v.value}: ${sampleData2[v.value]}`
            )
            :
            (tmp[v.field] = `${v.value}`)
      );
    } else {
      if (!props.showerror){
        return
      }else{
        message.error('Please proceed step by step from the beginning', 3)
      }
    }
    if (tmp) {
      console.log({temFieldValue:tmp})
      form.setFieldsValue(tmp);
    }
  }, [sampleData, sampleData2]);

  useEffect(()=>{
    fetchWokflowByWorkflowId(params?.id);
  },[params?.id, checkboxStatus])

  const fetchWokflowByWorkflowId = async (workflowId)=>{
    const workflowSingleRes = await api.get(`/api/v1/workflows/${workflowId}/rawWorkflowById`);
    if(workflowSingleRes?.data.code === 0){
      setCheckboxStatus(workflowSingleRes?.data.data.asPdf)
      setWorkflow(workflowSingleRes?.data.data)
    }
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((rawFormData) => {
        let ACTION_DATA = {}; //Data to be send as a product of the trigger
        let MAPPINGS = [];

        let flatFormData = flatten({ ...rawFormData}); // add invoice type then flatten

        keycreate(flatFormData)
          .filter((k) => flatFormData[k] && !Array.isArray(flatFormData[k]))
          .map((key) => { //key is xero field like Contact.ContactID or LineItems[0].UnitAmount or LineItemID
            let val = flatFormData[key].split('.');// val is salesforce field like ['1', 'Contact_Xero_Id__c']
            let kname = val.length > 1 ? val[1] : val[0];
            let snum = val.length > 1 ? val[0] : undefined;

            let kval = snum && parseInt(snum) === 1 ? sampleData[kname]
              : snum && parseInt(snum) === 2 ? sampleData2[kname]
                : kname;

            ACTION_DATA = { ...ACTION_DATA, [key]: kval };
            MAPPINGS = snum ?
              (key === 'Id' || key === 'SyncToken')
                ?
                [...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum }]
                :
                [...MAPPINGS, { type: 'TRIG', field: key, value: kname, stepId: id, valueStepOrder: snum }]
              :
              (key === 'Id')
                ?
                [...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum }]
                :
                [...MAPPINGS, { type: 'CON', field: key, value: kname, stepId: id }];
            return key
          });
          
          console.log({ACTION_DATA});
          console.log({MAPPINGS});

          //lineitems query generation from form fields
          const lineItemFields = [];
          MAPPINGS.filter(item=>{
            if(item.field.includes("[0]") && !item.type.includes("CON")){
              lineItemFields.push(item.value)
            }
          })
          let fieldStr = lineItemFields.length > 0 && lineItemFields.join(",");
          fieldStr = fieldStr + ",Id";
          const newQuery = additionalQuery?.replace(/{strFields}/, fieldStr);
          setAdditionalQuery(newQuery);
          setActionData(ACTION_DATA);
          setMappings(MAPPINGS);
          next();
      })
      .catch((e) => console.log(e));
  };

  const RoundedSelect = (props) => {
    const { label, placeholder, name, required, specialOptions, specialOptionsOnly, hint, onSelect } = props;

    return (
      <Col span={22}>
        <div className='label'>
          {label} <span className={required ? 'fr' : 'hidden'}>(required)</span>
        </div>
        <Form.Item name={name} required={required} rules={required ? [requiredRule] : null}>
          <Select className='rounded' mode={specialOptions ? null : 'tags'}
            placeholder={placeholder} allowClear onSelect={onSelect}>

            {specialOptions && specialOptions.map((v) => <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>)}

            {!specialOptionsOnly &&
              keys.map(
                (v, k) =>
                  !isObject(sampleData[v]) && (
                    <Select.Option value={`1.${v}`} key={`1.${k}`}>
                      <div className='opt-div'>
                        <span className='key'>{`1.${v}:  `}</span>
                        {`${sampleData[v]}`}
                      </div>
                    </Select.Option>
                  )
              )}

            {!specialOptionsOnly &&
              keys2.map(
                (v, k) =>
                  !isObject(sampleData2[v]) && (
                    <Select.Option value={`2.${v}`} key={`2.${k}`}>
                      <div className='opt-div'>
                        <span className='key'>{`2.${v}:  `}</span>
                        {`${sampleData2[v]}`}
                      </div>
                    </Select.Option>
                  )
              )}
          </Select>
        </Form.Item>
        {hint && <div className='hint'>{hint}</div>}
      </Col>
    );
  };

  const onChange = (event) => {
    console.log({ event, form: form.getFieldsValue() });
  };

  return (
    <Row className='padded xinvform'>
      <div className='title'>Customize Quickbooks Invoice</div>
      <Col span={24}>

        <Form layout='vertical' form={form} scrollToFirstError={true} onFinish={handleSubmit} onValuesChange={onChange}>
          <Row hidden={returnMap} justify='center'>
            <RoundedSelect label='Customer Reference' name='CustomerRef.value' required={true} placeholder='Type or insert..' />
            <RoundedSelect label='Bill Email' name='BillEmail.Address' hint='The address format must follow the RFC 822 standard.Maximum of 100 chars' placeholder='Type or insert..' />
            <RoundedSelect label='Txn Date' name='TxnDate' placeholder='Type or insert..' />
            <RoundedSelect label='Due Date' name='DueDate.date' placeholder='Type or insert..' />
            <RoundedSelect label='Ship Date' name='ShipDate.date' placeholder='Type or insert..' />
            <RoundedSelect label='Ship From Address 1(Line1)' name='ShipFromAddr.Line1' placeholder='Type or insert..' />
            <RoundedSelect label='Ship From Address 1(City)' name='ShipFromAddr.City' placeholder='Type or insert..' />
            <RoundedSelect label='Ship From Address 1(PostalCode)' name='ShipFromAddr.PostalCode' placeholder='Type or insert..' />
            <RoundedSelect label='Ship From Address 1(Latitude' name='ShipFromAddr.Lat' placeholder='Type or insert..' />
            <RoundedSelect label='Ship From Address 1(Longitude)' name='ShipFromAddr.Long' placeholder='Type or insert..' />
            <RoundedSelect label='Ship From Address 1(Country Sub Division Code)' name='ShipFromAddr.CountrySubDivisionCode' placeholder='Type or insert..' />
          </Row>

          <fieldset hidden={returnMap} className='fieldset' style={{ border: '1px solid #e2e2e2' }}>
            <legend>Line Items</legend>
            <Row justify='center'>
              <RoundedSelect label='Line Item Detail Type' hint='You must provide Detail Type.'  name='Line[0].DetailType' placeholder='Choose value..' specialOptions={lineItemDetailTypeOpt} specialOptionsOnly />
              <RoundedSelect label='Amount' name='Line[0].Amount' placeholder='Type or insert..' hint='Max 15 digits in 10.5 format' required={true} />
              <RoundedSelect label='Description' name='Line[0].Description' hint='Free form text description of the line item that appears in the printed record.Max 4000 chars' placeholder='Type or insert..' />
              <RoundedSelect label='Sales Item Line Detail(Item Id)' name='Line[0].SalesItemLineDetail.ItemRef.value' required={true} placeholder='Type or insert..' />
              <RoundedSelect label='Sales Item Line Detail(Tax Inclusive Amount)' name='Line[0].SalesItemLineDetail.TaxInclusiveAmt'  placeholder='Type or insert..' />
              <RoundedSelect label='Sales Item Line Detail(Discount Amount)' name='Line[0].SalesItemLineDetail.DiscountAmt' placeholder='Type or insert..' />
              <RoundedSelect label='Sales Item Line Detail(Tax Code Reference)' name='Line[0].SalesItemLineDetail.TaxCodeRef.value'  placeholder='Type or insert..' />
              <RoundedSelect label='Sales Item Line Detail(Item Account Reference)' name='Line[0].SalesItemLineDetail.ItemAccountRef.value' placeholder='Type or insert..' />
              <RoundedSelect label='Sales Item Line Detail(Discount Rate)' name='Line[0].SalesItemLineDetail.DiscountRate' placeholder='Type or insert..' />
              <RoundedSelect label='Sales Item Line Detail(Quantity)' name='Line[0].SalesItemLineDetail.Qty' placeholder='Type or insert..' />
              <RoundedSelect label='Sales Item Line Detail(Unit Price)' name='Line[0].SalesItemLineDetail.UnitPrice' placeholder='Type or insert..' />
            </Row>
          </fieldset>

          <PrimaryButton hidden={returnMap} className='btn-long' size='large' htmlType='button' onClick={() => setReturnMap(true)}>
            Continue
          </PrimaryButton>

          <Row hidden={!returnMap} justify='center'>
            <RoundedSelect label='Invoice Id' name='Id' required={true} placeholder='Type or insert External_Id__c' />
            <RoundedSelect
              label='Sync  Token'
              name='SyncToken'
              placeholder='Type or insert..'
              required={true}
            />
            <PrimaryButton hidden={!returnMap} className='btn-long' size='large' htmlType='submit'>
              Continue
            </PrimaryButton>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default QuickbooksInvoiceForm;
