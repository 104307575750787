//components
import DashBoard from '../Layout/DashBoard';
import WorkFlow from '../Layout/WorkFlow';
import List from '../Layout/WorkFlow/WorkflowList';
import Logs from '../Layout/Logs';
import Profile from '../Layout/Profile';
import { ApiOutlined, ScheduleOutlined, HomeOutlined, AuditOutlined, RadiusSettingOutlined } from '@ant-design/icons';
import { Connection } from '../Layout/Connections/Connection';
import SubscriptionsPlan from '../Layout/Pricing/Subscriptions';
import BillingUsage from '../Layout/Pricing/Billing';
// import NoSubscriptionPage from '../Layout/NoSubscription';


const userRoutes = [
	{
		name: 'Dashboard',
		icon: <HomeOutlined/>,
		path: '/system/dashboard',
		component: DashBoard,
		items: []
	},
	{
		name: 'Profile',
		hidden: true,
		icon: <HomeOutlined/>,
		path: '/system/profile',
		component: Profile,
		items: []
	},
	{
		name: 'Workflows',
		icon: <RadiusSettingOutlined/>,
		path: '/system/workflows',
		component:  List,
		items: []
	},
	{
		name: 'Connections',
		icon: <ApiOutlined/>,
		path: '/system/connections',
		component: Connection,
		items: []
	},
	{
		hidden: true,
		path: '/system/workflows/:id(\\d+)/logs',
		component: Logs,
		items: []
	},
	{
		hidden: true,
		path: '/system/editor/:id(\\d+)',
		component: WorkFlow,
		items: []
	},
	{
		name: 'Subscription',
		hidden: false,
		icon: <AuditOutlined/>,
		path: '/system/subscription',
		component: BillingUsage,
		items: []
	},
	{
		name: 'Logs',
		icon: <ScheduleOutlined/>,
		path: '/system/workflows/logs',
		component: Logs,
		items: []
	},
	{
		name: 'Pricing Plan',
		hidden: true,
		icon: <AuditOutlined/>,
		path: '/system/pricing-plan',
		component: SubscriptionsPlan,
		items: []
	},
];

export { userRoutes };
