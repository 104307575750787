import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import queryString from 'query-string';
import jwt_decode from "jwt-decode";
import { browserName, browserVersion } from "react-device-detect";
import { Form, Input, Layout, Row, Col, Button, Card } from 'antd';
import styled from 'styled-components';
import loginBG from './images/bg.jpg';
import logo from './images/logo_v_png.png';
import { requiredRule } from '../../Commons/utils';
import { PrimaryButton } from '../../Commons';
import './style.scss';
import './xero.css';
import { api } from '../../Http';
import { Content } from 'antd/lib/layout/layout';
import { useSelector } from 'react-redux';
import { getSubscription } from '../../redux/slices/subscriptions';
import { ENVIRONMENT } from '../../Http/API';
import useIpAddress from '../../hooks/useIpAddress';

const Login = () => {
	const { Background } = StyledComponents;
	const usernameInputRef = useRef();
	const history = useHistory();
	const [cookie, setCookie] = useCookies(['Authorization']);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [error, setError] = useState('');
	const [ip] = useIpAddress();
	const subscription = useSelector(getSubscription)

	
	useEffect(() => {
		if (cookie.Authorization) {
			if (subscription?.active) {
				history.replace('/system/dashboard');
			} else {
				history.replace('/payment')
			}
		}
		usernameInputRef.current.focus();
	}, [isAuthenticated]);

	
	const subscriptionPlan = queryString.parse(window.location?.search);
	if(Object.keys(subscriptionPlan).length>0){
		localStorage.setItem("subscriptionPlan", JSON.stringify(subscriptionPlan))
	}
	const onFinish = (v) => {
		const newData = {...v, browserName: browserName+""+browserVersion, deviceIp: ip};
		api
			.post('/account/login', newData, { withCredentials: false })
			.then((resp) => {
				if (resp.data.code === 0) {
					const userdata = resp.data.data
					if(userdata?.userName){
						localStorage.setItem("userName", userdata?.userName);
						history.replace("/tfa-verifycode");
					}else{
						//if 2fa disabled the code will be executed bellow
						localStorage.setItem("Authorization", userdata.token)
						setCookie('Authorization', userdata.token, { path: '/' });
						setIsAuthenticated(true);
						localStorage.setItem("token", userdata.token)
						const token = localStorage.getItem("token");
						const decoded = token && jwt_decode(token);
						localStorage.setItem("email", decoded?.email)
						if(userdata.subscription === null){
							history.replace("/payment")
						}else{
							localStorage.setItem("expirationTime", decoded?.exp*1000);
							localStorage.setItem("subscription", JSON.stringify(userdata.subscription))
							localStorage.setItem("plan", JSON.stringify(userdata.plan))
							if(decoded.userType === 'CUST'){
								history.replace('/system/dashboard')
							}
							if(decoded.userType === 'ADM'){
								history.replace('/system/users')
							}
						}
					}
					
				} else {
					setError(resp.data.message);
				}
			})
			.catch(() => setError('Network Error'));
	};


	const handleXeroLogin = () => {
		const callback = ENVIRONMENT.XERO_SIGN_CALLBACK;
		const clientId = ENVIRONMENT.XERO_CLIENT_ID;
		window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${callback}&scope=openid profile email&state=123`
	}
	return (
		<Layout>
			<Content>
				<Background justify='center' align='middle'>
					<Col xs={22} sm={16} md={12} lg={10} xl={8} xxl={7}>
						<Card className='login-card' bordered={false}>
							<a href="https://nettverk.io/" target="_blank" rel='noreferrer'><img className='logo' src={logo} alt='logo'/></a>
							<div className={error ? 'error-message' : 'login-message'}>
								{error ? error : 'LOGIN TO YOUR ACCOUNT'}
							</div>

							<Form
								onFinish={(e) => onFinish(e)}
								style={{ textAlign: 'center', marginTop: '2rem' }}
							>
								<Form.Item name='userName' rules={[requiredRule]}>
									<Input
										className='txt-field'
										ref={usernameInputRef}
										size='large'
										placeholder='Username'
									/>
								</Form.Item>
								<Form.Item name='password' rules={[requiredRule]} style={{padding:0, marginBottom:0}}>
									<Input.Password
												className='txt-field'
												size='large'
												placeholder='Password'
											/>
								</Form.Item>
								<Form.Item style={{padding:0, marginTop:0}}>
									<div className='div-txt-forget-password'>
											<span>
													Forgot password? <Link to='/forgot-password'>Click Here</Link>
											</span>
									</div>
								</Form.Item>
								<Form.Item style={{padding:0, marginBottom:0}}>
										<PrimaryButton
											className='nv-btn'
											htmlType='submit'
											type='primary'
											size='large'
											block>
											Sign in
										</PrimaryButton>
								</Form.Item>
								<Form.Item style={{padding:0, marginTop:"0.5rem", marginBottom: "0.5rem"}}>
								<div className='div-txt-contact-us'>
										<span>Don't have an account yet?<a href='https://nettverk.io/contact-us/' target='__blank'> Contact Us</a></span>
									</div>
								</Form.Item>
							</Form>
							<div style={{textAlign:'center'}}>
								<div>
									<button id='xero' onClick={handleXeroLogin} className='sso-login-button'>
											<img src="data:image/svg+xml,%3Csvg viewBox='0 0 45 46' xmlns='http://www.w3.org/2000/svg'%3E %3Ctitle%3EXero%3C/title%3E %3Cpath fill='%2313B5EA' d='M22.457 45.49c12.402 0 22.456-10.072 22.456-22.495C44.913 10.57 34.86.5 22.457.5 10.054.5 0 10.57 0 22.995 0 35.418 10.054 45.49 22.457 45.49' /%3E %3Cpath fill='%23FFFFFF' d='M10.75 22.935l3.832-3.85a.688.688 0 0 0-.977-.965l-3.83 3.833-3.845-3.84a.687.687 0 0 0-.966.979l3.832 3.837-3.83 3.84a.688.688 0 1 0 .964.981l3.84-3.842 3.825 3.827a.685.685 0 0 0 1.184-.473.68.68 0 0 0-.2-.485l-3.83-3.846m22.782.003c0 .69.56 1.25 1.25 1.25a1.25 1.25 0 0 0-.001-2.5c-.687 0-1.246.56-1.246 1.25m-2.368 0c0-1.995 1.62-3.62 3.614-3.62 1.99 0 3.613 1.625 3.613 3.62s-1.622 3.62-3.613 3.62a3.62 3.62 0 0 1-3.614-3.62m-1.422 0c0 2.78 2.26 5.044 5.036 5.044s5.036-2.262 5.036-5.043c0-2.78-2.26-5.044-5.036-5.044a5.046 5.046 0 0 0-5.036 5.044m-.357-4.958h-.21c-.635 0-1.247.2-1.758.595a.696.696 0 0 0-.674-.54.68.68 0 0 0-.68.684l.002 8.495a.687.687 0 0 0 1.372-.002v-5.224c0-1.74.16-2.444 1.648-2.63.14-.017.288-.014.29-.014.406-.015.696-.296.696-.675a.69.69 0 0 0-.69-.688m-13.182 4.127c0-.02.002-.04.003-.058a3.637 3.637 0 0 1 7.065.055H16.2zm8.473-.13c-.296-1.403-1.063-2.556-2.23-3.296a5.064 5.064 0 0 0-5.61.15 5.098 5.098 0 0 0-1.973 5.357 5.08 5.08 0 0 0 4.274 3.767c.608.074 1.2.04 1.81-.12a4.965 4.965 0 0 0 1.506-.644c.487-.313.894-.727 1.29-1.222.006-.01.014-.017.022-.027.274-.34.223-.826-.077-1.056-.254-.195-.68-.274-1.014.156-.072.104-.153.21-.24.315-.267.295-.598.58-.994.802-.506.27-1.08.423-1.69.427-1.998-.023-3.066-1.42-3.447-2.416a3.716 3.716 0 0 1-.153-.58l-.01-.105h7.17c.982-.022 1.51-.717 1.364-1.51z' /%3E %3C/svg%3E" alt="Xero logo" className="xero-sso-logo" />
											<span>Sign In with Xero</span>
									</button>
								</div>
							</div>
						</Card>
					</Col>
				</Background>
			</Content>
		</Layout>
	);
};

export default Login;

const StyledComponents = {
	Background: styled(Row)`
		height: 100vh;
		background-image: url(${loginBG});
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	`,
	SubmitButton: styled(Button)``,
};
