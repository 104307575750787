import React from 'react';
import { ArrowDown } from '../../../Commons';
import { AppMap } from '../../../Commons/AppMapping';
import { FIND_SFDC_RECORD } from '../../../Commons/Events';
import Process from '../../Process';

/*
    CreateXeroInvoiceWorkflow with 3 Processess
	Main Processes: 
	1. Retrieve Opportunity Object
	2. Find Opportunity Line Items.
	3. Customize Invoice to be created.
*/
const CreateXeroInvoiceWorkflow = ({ workflowData }) => {
	const { step1, step2 } = workflowData;

	return (
		<React.Fragment>
			<Process
				app={AppMap[step1.eventCode]}
				stepData={workflowData.step1}
				message='When this happens...'
				order={1}
			/>
			<ArrowDown />
			<Process
				app={AppMap[FIND_SFDC_RECORD]}
				stepData={{
					appName: 'Salesforce',
					shortDesc: 'Find Record',
					appCode: 'SFDC',
					accountAuthorized: step1.accountAuthorized,
				}}
				message='then do this'
				order={2}
			/>
			<ArrowDown />
			<Process
				app={AppMap[step2.eventCode]}
				stepData={step2}
				message='then do this'
				order={3}
			/>
		</React.Fragment>
	);
};

export default CreateXeroInvoiceWorkflow;
