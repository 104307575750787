import { Mapping } from './Mapping.model';
import { Step } from './Step.model';


export class WorkflowData {
        #default = {
                workflowId: null,
                workflowName: null,
                webhookUrl: null,
                step1: new Step(),
                step2: new Step(),
                mappings: Mapping.List()
        }
        constructor(data) {
                console.log({data})
                if(!data) data = this.#default;
                this.workflowId = data?.workflowId;
                this.workflowName = data?.workflowName;
                this.webhookUrl = data?.webhookUrl;
                this.step1 = new Step(data?.step1);
                this.step2 = new Step(data?.step2);
                // this.mappings = this.#extractMapping(data?.mappings);
                this.mappings = Mapping.List(data?.mappings);
        }

        // #extractMapping(mappings=[]) {
        //         const mappingList = [new Mapping()];
        //         mappingList.shift();
        //         mappings.map(mapping => mappingList.push(new Mapping(mapping)));
        //         return mappingList;
        // }

        
        static List(initialValue = []) {
                const arr = [new this()];
                arr.shift();
                if(initialValue && initialValue.length > 0) initialValue.map(v => arr.push(v));
                return arr;
        }
}