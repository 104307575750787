import { QUICKBOOKS_UPDATE_RECORD, SFDC_UPDATE_CONTACT_RECORD, SFDC_UPDATE_INVOICE_RECORD, SFDC_UPDATE_PRODUCT_RECORD } from "../../../steps";

function getActionApp(eventCode){
    let actionEvent = null;
    switch (eventCode) {
        case "UQCUS":
            actionEvent = SFDC_UPDATE_CONTACT_RECORD;
            break;
        case "UQITM":
            actionEvent = SFDC_UPDATE_PRODUCT_RECORD;
            break;
        case "UQINV":
            actionEvent = SFDC_UPDATE_INVOICE_RECORD;
            break;
        default:
            break;
    }
    return actionEvent;
}
function getTriggerApp(eventCode){
    let actionEvent = null;
    if(eventCode === "UQCUS" || eventCode === "UQITM" || eventCode === "UQINV")
    {
        actionEvent = QUICKBOOKS_UPDATE_RECORD;     
    }
    return actionEvent;
}

export {
getActionApp,
getTriggerApp
}