import { HomeOutlined, PlusOutlined, ShareAltOutlined, UnorderedListOutlined, UserAddOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import Users from "./users";
import WorkFlows from "./workflows";
import Logs from "../Logs";
import AllWorkFlows from "./allWorkflows";
import Profile from "../Profile";
import UserRegister from "./userRegister";
import AllXeroConnections from "./allXeroConnections";
import XeroConnection from "./XeroConnection";


const adminRoutes = [
	{
		name: 'All Users',
		icon: <UsergroupAddOutlined />,
		path: '/system/users',
		component: Users,
		items: []
	},
	{
		name: 'All Workflows',
		icon: <ShareAltOutlined />,
		path: '/system/workflows-by-admin',
		component: AllWorkFlows,
		items: []
	},
	{
		hidden: true,
		path: '/system/workflows/user/:id',
		component: WorkFlows,
		items: []
	},
	{
		hidden: true,
		path: '/system/workflows/:id(\\d+)/logs',
		component: Logs,
		items: []
	},
	{
		name: 'Register A User',
		icon: <UserAddOutlined />,
		path: '/system/user_register',
		component: UserRegister,
		items: []
	},
	{
		name: 'Xero Admin',
		icon: <PlusOutlined />,
		path: '/system/xero_admin_connection',
		component: XeroConnection,
		items: []
	},
	{
		name: 'All Xero Clients',
		icon: <UnorderedListOutlined />,
		path: '/system/xero_client_connections',
		component: AllXeroConnections,
		items: []
	},
	{
		name: 'Profile',
		hidden: true,
		icon: <HomeOutlined/>,
		path: '/system/profile',
		component: Profile,
		items: []
	},
	
];

export { adminRoutes };