import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import queryString from 'query-string';
import jwt_decode from "jwt-decode";
import { browserName, browserVersion } from "react-device-detect";
import { Form, Input, Layout, Row, Col, Button, Card } from 'antd';
import styled from 'styled-components';
import loginBG from './images/bg.jpg';
import logo from './images/logo_v_png.png';
import { requiredRule } from '../../Commons/utils';
import { PrimaryButton } from '../../Commons';
import './style.scss';
import './xero.css';
import { api } from '../../Http';
import { Content } from 'antd/lib/layout/layout';

import useIpAddress from '../../hooks/useIpAddress';
import { useSelector } from 'react-redux';
import { getSubscription } from '../../redux/slices/subscriptions';

const VerifyCode = () => {
	const { Background } = StyledComponents;
	const passcodeInputRef = useRef();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const history = useHistory();
	const [qrCode, setQrCode] = useState(null);
	const [error, setError] = useState('');
	const [cookie, setCookie] = useCookies(['Authorization']);
	const [ip] = useIpAddress();
	const subscription = useSelector(getSubscription)

	
	useEffect(() => {
		if(!localStorage.getItem("userName")){
			history.replace('/login')
		}
		if (cookie.Authorization) {
			if (subscription?.active) {
				history.replace('/system/dashboard');
			} else {
				history.replace('/payment')
			}
		}
		getQrCode();
		passcodeInputRef.current.focus();
	}, []);

	const getQrCode = ()=>{
	const userName = localStorage.getItem("userName");
		api.
			get(`/account/qr-code?userName=${userName}`)
			.then((resp)=>{
				if(resp?.data?.code === 0){
					setQrCode(resp?.data.data)
				}
			})

	}
	const subscriptionPlan = queryString.parse(window.location?.search);
	if(Object.keys(subscriptionPlan).length>0){
		localStorage.setItem("subscriptionPlan", JSON.stringify(subscriptionPlan))
	}

	const onFinish = (v) => {
		const userName = localStorage.getItem("userName");
		const newData = {...v, userName: userName, browserName: browserName+""+browserVersion, deviceIp: ip};
		api
			.post('/account/verify-otp', newData)
			.then((resp) => {
				if (resp.data.code === 0) {
					const userdata = resp.data.data;
					localStorage.setItem("Authorization", userdata.token)
					setCookie('Authorization', userdata.token, { path: '/' });
					setIsAuthenticated(true);
					localStorage.setItem("token", userdata.token)
					const token = localStorage.getItem("token");
					const decoded = token && jwt_decode(token);
					localStorage.setItem("email", decoded?.email)
					if(userdata.subscription === null){
						history.replace("/payment")
					}else{
						localStorage.setItem("expirationTime", decoded?.exp*1000);
						localStorage.setItem("subscription", JSON.stringify(userdata.subscription))
						localStorage.setItem("plan", JSON.stringify(userdata.plan))
						if(decoded.userType === 'CUST'){
							history.replace('/system/dashboard')
						}
						if(decoded.userType === 'ADM'){
							history.replace('/system/users')
						}
					}
				} else {
					setError(resp.data.message);
				}
			})
			.catch(() => setError('Network Error'));
	};

	return (
		<Layout>
			<Content>
				<Background justify='center' align='middle'>
					<Col xs={22} sm={16} md={12} lg={10} xl={8} xxl={7}>
						<Card className='verifycode-card' bordered={false}>
							<a href="https://nettverk.io/" target="_blank" rel='noreferrer'><img className='logo' src={logo} alt='logo'/></a>
							<div className={error ? 'error-message' : 'login-message'}>
								{
									error ? error : qrCode ?'Connect an Authenticator App' : 'Verify Your Identity'
								}
							</div>
							<div style={{textAlign:'center'}}>
								{
									error ? 
									'' 
									: 
									qrCode ?
									(<div>
										<div>
											<p>Connect a third-party authenticator app to your Nettverk account
												 so you can use it to verify your identity.</p>
										</div>
										<div style={{width:"70%", margin:"auto", textAlign:'center'}}>
											<ol style={{display:'inline-block', textAlign:'left'}}>
												<li>Open an authenticator app.</li>
												<li>Scan this QR code with the authenticator app.</li>
												<li>Enter the code generated by the app.</li>
											</ol>
											
										</div>
									</div>
									)
									:
									(
									<div>
										<div>
											<p>You're trying to Log In to Nettverk. To make sure your your Nettverk account is secure, 
												we have to verify your identity.</p>
										</div>
										<div style={{width:"70%", margin:"auto", textAlign:'center'}}>
											<ol style={{display:'inline-block', textAlign:'left'}}>
												<li>Open an authenticator app.</li>
												<li>Enter the code generated by the app.</li>
											</ol>
											
										</div>
									</div>
									)
								}
							</div>
							<div style={{textAlign:'center'}}>
								{
									qrCode && <img style={{width:'30%', height: '30%'}} src={qrCode} alt='qr code'/>
								}
							</div>
							<Form
								onFinish={(e) => onFinish(e)}
								style={{ textAlign: 'center', marginTop: '2rem' }}
							>
								<Form.Item name='otp' rules={[requiredRule]}>
									<Input
										className='txt-field'
										ref={passcodeInputRef}
										size='large'
										placeholder='Verification Code'
									/>
								</Form.Item>
								
								<Form.Item style={{padding:0, marginBottom:0}}>
										<PrimaryButton
											className='nv-btn'
											htmlType='submit'
											type='primary'
											size='large'
											block>
											Verify
										</PrimaryButton>
								</Form.Item>
							</Form>
							<div style={{width:"70%", margin:" 20px auto 20px auto"}}>
								{
                                    qrCode && 
                                    <Link to="/tfa-verifysecret">
                                    	I Can't Scan the QR Code
                                   </Link>
                                }
							</div>
						</Card>
					</Col>
				</Background>
			</Content>
		</Layout>
	);
};

export default VerifyCode;

const StyledComponents = {
	Background: styled(Row)`
		height: 100vh;
		background-image: url(${loginBG});
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	`,
	SubmitButton: styled(Button)``,
};
