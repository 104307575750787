import { Log } from './Log';

export class LogData{
        limit = 10;
        page = 10;
        logs = Log.List();

        constructor(data) {
                if(data) {
                        this.limit = data.limit;
                        this.page = data.page;
                        this.logs = Log.List(data.logs);
                }
        }

        static List(initialValue = []) {
                const arr = [new this()];
                arr.shift();
                if(initialValue && initialValue.length > 0) initialValue.map(v => arr.push(v));
                return arr;
        }
}