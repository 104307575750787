import { createSlice } from '@reduxjs/toolkit';




const appsSlice = createSlice({
        name: 'apps',
        initialState: {
                apps: []
        },
        reducers: { 
                addApps: (state, {payload}) => {
                        state.apps = payload;
                },
                
        },
});

export const { addApps } = appsSlice.actions;
export const getAllApps = (state) => state.apps.apps;
export default appsSlice.reducer;