import { createSlice } from '@reduxjs/toolkit';
import { Connection } from '../../Http/models/connection/connection';




const connectionsSlice = createSlice({
        name: 'connections',
        initialState: {
                connections: Connection.List()
        },
        reducers: { 
                addConnections: (state, {payload}) => {
                        state.connections = [...payload];
                },
                removeConnection: (state, { payload }) => {
                        const id = payload;
                        const updatedData = [];
                        state.connections.map(ad => {
                                if(ad.id === id){
                                        ad.accessToken = null;
                                        ad.refreshToken = null;
                                        ad.instanceName = null;
                                        ad.status = '02';
                                }
                                updatedData.push(ad);
                                return ad
                        });
                        state.connections = Connection.List(updatedData);
                }
        },
});

export const { addConnections, removeConnection } = connectionsSlice.actions;
export const getAllConnections = (state) => {
        return Connection.List(state.connections.connections);
};
export default connectionsSlice.reducer;