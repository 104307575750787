import React, { useState, useEffect } from 'react';
import { Table, Button, Col, Modal, Select } from 'antd';
import { DisconnectOutlined, ApiOutlined } from '@ant-design/icons';
// import { useCookies } from 'react-cookie';
import { api } from '../../Http';
import salesforceLogo from '../../images/salesforce.png';
import quickbooksConnectionButton from '../../images/quickbooks-connect-button.svg';
import xeroforceConnectionButton from '../../images/xero-connect-blue-button.svg';
import xeroLogo from '../../images/xero.png';
import quickbooksLogo from '../../images/quickbooks-seeklogo.svg';
import { fetchConnections, fetchUser, removeConnectionById } from '../../Http/connections';
import { useDispatch, useSelector } from 'react-redux';
import { addConnections, getAllConnections, removeConnection } from '../../redux/slices/connections';
import { ENVIRONMENT } from '../../Http/API';

const callback = ENVIRONMENT;
// import ChooseAccount from '../Steps/ChooseAccount';

export const Connection = () => {

	const dispatch = useDispatch();
	const SFDC_SANDBOX = 'test';
	const SFDC_PROD = 'login';
	const SFDC_CALLBACK = callback.sfdc_callback;
	const SFDC_CLIENT_ID = callback.sfc_client_id;

	let environment = SFDC_PROD;
	// const [cookies] = useCookies(['Authorization']);
	// const [appData, setAppData] = useState(data);
	const appData = useSelector(getAllConnections);
	const [loading, setLoading] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [deleteModalConfirmLoading, setDeleteModalConfirmLoading] = useState(false);
	const [deleteId, setDeleteId] = useState(0);
	const [sfdcModalOpen, setSfdcModalOpen] = useState(false);
	const columns = [
		{
			title: 'App Name',
			dataIndex: 'app',
			key: 'acc_app',
			render: text => <a>{text === 'SFDC' ? <img width='40px' src={salesforceLogo} alt="Saleforce..."/> :text === 'QCKB' ? <img width='40px' src={quickbooksLogo} alt="Quickbooks..."/> : <img width='32px' src={xeroLogo} alt="Xero..." /> }</a>,
		},
		{
			title: 'Name',
			dataIndex: 'instanceName',
			render: text => <span>{text}</span>,
		},
		{
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			render: text => <span>{text === '01' ? 'Active' : 'Deactivated'}</span>
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, datum) => (
				<>
					{
						datum.refreshToken === null 
						? 
						<Button style={{padding:'0px'}} size="large" onClick={()=>addConnection(datum.app)}>
							{
								datum.app === 'XERO'
								?
								// <img style={{width:'100%', height: '100%'}} src="https://developer.xero.com/static/images/documentation/ConnectToXero2019/connect-blue.svg" alt="Xero..." />
								<img style={{width:'100%', height: '100%'}} src={xeroforceConnectionButton} alt="Xero..." />
								:
								datum.app === 'QCKB'
								?
								// <span style={{padding: '5px'}}><DisconnectOutlined /> Connect to Quickbooks</span>
								<img style={{width:'100%', height: '100%'}} src={quickbooksConnectionButton} alt="Quickbooks..." />
								:
								<span style={{display:'block', padding:'3px', backgroundColor:'#0D9DDA', color: 'white', width:'225px', height:'38px', borderRadius:'3px', fontSize:'18px', border:'none'}}>Connect to Salesforce</span>
								// <img style={{width:'100%', height: '100%'}} src={salesforceConnectionButton} alt="Salesforce..." />
							}
						</Button>
						:
						<Button style={{padding:0}} size="large" onClick={()=> deleteAppAccount(datum.id)}>
							{
								datum.app === 'XERO'
								?
								<img style={{width:'100%', height: '100%'}} src="https://developer.xero.com/static/images/documentation/ConnectToXero2019/disconnect-white.svg" alt="Xero..." />
								:
								datum.app === 'QCKB'
								?
								<span style={{padding: '5px 44px 5px 44px'}}>
									<img width='30px' src={quickbooksLogo} alt="Quickbooks..."/>  Disconnect
								</span>
								:
								<span style={{padding: '5px 44px 5px 44px'}}>
									<img width='30px' src={salesforceLogo} alt="Saleforce..."/>  Disconnect
								</span>
							}
						</Button>
					}

				</>

			),
		},
	];

	useEffect(()=>{
		getConnections();
	},[]);

	const getConnections = async () => {
		const user = await fetchUser();
		console.log({user});

		const connectionsRes = await fetchConnections();
		if(connectionsRes && connectionsRes.data) {
			console.log({connectionsResData: connectionsRes.data});
			// const updateData = [];
			connectionsRes.data.map(datum => {
				datum.key = datum.id;
			});
			// setAppData(updateData);
			dispatch(addConnections(connectionsRes.data));
		};
	};

	const disconnectItem = async (id) => {
		const connectionRes = await removeConnectionById(id);

		console.log({connectionRes});
		setDeleteModalOpen(false);
		setDeleteModalConfirmLoading(false);
		if(connectionRes && connectionRes.data) {
				dispatch(removeConnection(id));
				// setAppData(updatedData);
				setLoading(false);
				setDeleteId(0);
				//setRefetch(!refetch);
				console.log('disconnected');
		} else {
			console.log('not disconnected');
		}

	};

	const showPopconfirm = () => {
		setDeleteModalOpen(true);
		setLoading(true);
	};

	const handleOk = () => {
		const id = deleteId;
		//console.log({id});
		setLoading(false);
		setDeleteModalConfirmLoading(true);
		disconnectItem(id);
	};

	const handleCancel = () => {
		console.log('Clicked cancel button');
		setDeleteModalOpen(false);
		setLoading(false);
	};

	const deleteAppAccount = (id) => {
		showPopconfirm();
		setDeleteId(id);
		console.log({id});
		setLoading(true);
	};

	const addConnection = (app) => {
		app === 'XERO' ? openXeroConnection() : app === 'QCKB' ? openQuickbooksConnection() : setSfdcModalOpen(true);
		// if(app === 'XERO') openXeroConnection();
		// else if (app === 'SFDC') setSfdcModalOpen(true);
	};

	const openXeroConnection = () => {
		api
		.get('/api/xero')
		.then((resp) => {
			console.log({resp});
			if (resp.data.code === 0) {
				// TODO: check for new window allow
				console.log({xeroConnection: resp.data})
				const popWindow = window.open(
					resp.data.data,
					'newwindow',
					'width=800,height=600'
				);
				const syncWindow = setInterval(() => {
					if(popWindow.closed) {  
						getConnections();
						clearInterval(syncWindow); 
					}  
				}, 500);
			}
		})
		.catch((e) => console.log(e));

	};

	const openQuickbooksConnection = () => {
		
		api.get('/api/quickbooks')
			.then((resp) => {
				if (resp.data.code === 0) {
					const popWindow = window.open(
						resp.data.data,
						'newwindow',
						'width=800,height=600'
					);
					const syncWindow = setInterval(() => {
						if(popWindow.closed) {  
							getConnections();
							clearInterval(syncWindow); 
						}  
					}, 500);
				}
			})
			.catch((e) => console.log(e));
	}

	const openSaleForceConnection = (environment) => {
		console.log({environment});
		window.SFDC_ENV = environment;
		const url = `https://${environment}.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${SFDC_CLIENT_ID}&redirect_uri=${SFDC_CALLBACK}`;
		const popWindow = window.open(url, 'newwindow', 'width=1000,height=600');
		console.log({popWindow: popWindow});
		
		const syncWindow = setInterval(() => {
			if(popWindow.closed) {  
				getConnections();
				clearInterval(syncWindow); 
			}  
		}, 500); 

	};

	return (
		<Col span={24} 
		// className='padded'
		>
			<Modal title="Confirmation For Deleting" okType='danger' confirmLoading={deleteModalConfirmLoading} visible={deleteModalOpen} okText="Confirm Disconnect" onOk={handleOk} onCancel={handleCancel}>
				<p>Are you sure to disconnect this connection ?</p>
			</Modal>
			<Table loading={loading} columns={columns} dataSource={appData} />
			<Modal
				visible={sfdcModalOpen}
				title='Choose Environment'
				onCancel={() => setSfdcModalOpen(false)}
				onOk={() => {
					setSfdcModalOpen(false);
					openSaleForceConnection(environment);
				}}>
				<Select
					defaultValue={environment}
					onChange={(v) => (environment = v)}
					className='fill-width'>
					<Select.Option value={SFDC_PROD}>
						Production Environment
					</Select.Option>
					<Select.Option value={SFDC_SANDBOX}>
						Sandbox Environment
					</Select.Option>
				</Select>
			</Modal>
		</Col>
	);
};
