import React from 'react';
import { Row, Col } from 'antd';
const StepContainer = (props) => {
	const { title } = props;
	return (
		<Row className='padded'>
			<Col span={24}>
				<div className='wf-title'>{title}</div>
				<div className='mt-2'>{props.children}</div>
			</Col>
		</Row>
	);
};

export default StepContainer;
