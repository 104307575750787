/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Form, Select, Card } from 'antd';
import './style.scss';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { keys as keycreate, isObject } from 'lodash';
import { PrimaryButton } from '../../Commons';
import { api } from '../../Http';
import { requiredRule, flatten } from '../../Commons/utils';
import { useHistory } from 'react-router-dom';
const QuickbooksPaymentForm = () => {
    const { sampleData,
        sampleData2,
        SObject,
        setActionData,
        mappings,
        workflowId,
        additionalQuery,
        setMappings,
        //   setOrganisation, 
        form } = useContext(WorkflowContext);
    const {
        id,
        //  next 
    } = useContext(StepContext);

    const [keys, setKeys] = useState([]);
    // const [organisations, setOrganisations] = useState([]);
    const [returnMap, setReturnMap] = useState(false);
    const [message, setMessage] = useState(undefined);
    const history = useHistory()


    const handleSkip = (maps) => {
        api.post('/api/v1/workflows/mappings', { mappings: maps, sObject: SObject.name, workflowId: workflowId, additionalQuery: additionalQuery })
            .then(resp => {
                console.log({ resp })
                setMessage(resp.data.message)
            })
            .catch(e => console.log(e));

        setTimeout(() => {
            history.push('/system/workflows');
        }, 1000)
    };

    useEffect(() => {
        setKeys(keycreate(sampleData));
        console.log({ mappings, sampleData });
        let tmp = {};
        mappings?.forEach((v) =>
            (v.type === 'TRIG' || v.type === 'LKUP')
                ?
                (
                    tmp[v.value] = [`${v.valueStepOrder}.${v.field}`],
                    tmp[v.label] = `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}`
                )
                :
                (v.type === 'ACT')
                    ?
                    (

                        tmp[v.field] = [`${v.valueStepOrder}.${v.value}`],
                        tmp[v.label] = `${v.valueStepOrder}.${v.field}: ${sampleData[v.value]}`
                    )
                    :
                    (tmp[v.field] = `${v.value}`)
        );
        if (tmp) {
            form.setFieldsValue(tmp);
        }
    }, []);

    const handleSubmit = () => {
        form
            .validateFields()
            .then((rawFormData) => {
                let ACTION_DATA = {}; //Data to be send as a product of the trigger
                let MAPPINGS = [];

                let flatFormData = flatten({ ...rawFormData });

                keycreate(flatFormData)
                    .filter((k) => flatFormData[k])
                    .map((key) => {
                        if (Array.isArray(rawFormData[key]) && rawFormData[key].length === 0) {
                            // skip
                        } else {
                            let val = flatFormData[key].split('.');
                            let kname = val.length > 1 ? val[1] : val[0];
                            let snum = val.length > 1 ? val[0] : undefined;

                            let kval = snum && parseInt(snum) === 1 ? sampleData[kname]
                                : snum && parseInt(snum) === 2 ? sampleData2[kname]
                                    : kname;

                            ACTION_DATA = { ...ACTION_DATA, [key]: kval };
                            MAPPINGS = snum ?
                                (key === 'Id')
                                    ? [...MAPPINGS, { type: 'ACT', value: kname, field: key, stepId: id, valueStepOrder: snum }]
                                    : [...MAPPINGS, { type: 'TRIG', value: key, field: kname, stepId: id, valueStepOrder: snum }]

                                :
                                (key === 'Id')
                                    ? [...MAPPINGS, { type: 'ACT', value: kname, field: key, stepId: id, valueStepOrder: snum }]
                                    : [...MAPPINGS, { type: 'CON', value: key, field: kname, stepId: id }];
                        }
                        return key
                    });
                MAPPINGS.find(value => value.value === "Line[0].LinkedTxn[0].TxnId").type = "LKUP"
                console.log({MAPPINGS})
                setActionData(ACTION_DATA);
                setMappings(MAPPINGS);
                handleSkip(MAPPINGS)
                // next();
            })
            .catch((e) => console.log(e));
    };

    const RoundedSelect = (props) => {
        const { label, placeholder, name, required, specialOptions, specialOptionsOnly, hint, onSelect } = props;

        return (
            <Col span={22}>
                <div className='label'>
                    {label} <span className={required ? 'fr' : 'hidden'}>(required)</span>
                </div>
                <Form.Item name={name} required={required} rules={required ? [requiredRule] : null}>
                    <Select className='rounded' mode={specialOptions ? null : 'tags'}
                        placeholder={placeholder} allowClear onSelect={onSelect}>

                        {specialOptions && specialOptions.map((v) => <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>)}

                        {!specialOptionsOnly &&
                            keys.map(
                                (v, k) =>
                                    !isObject(sampleData[v]) && (
                                        <Select.Option value={`1.${v}`} key={`1.${k}`}>
                                            <div className='opt-div'>
                                                <span className='key'>{`1.${v}:  `}</span>
                                                {`${sampleData[v]}`}
                                            </div>
                                        </Select.Option>
                                    )
                            )}
                    </Select>
                </Form.Item>
                {hint && <div className='hint'>{hint}</div>}
            </Col>
        );
    };

    // LKUP

    return (
        <Row className='padded xinvform'>
            <div className='title'> {returnMap ? 'Return Mapping' : 'Create Quickbooks To Salesforce Field Mapping'} </div>
            <Col span={24}>

                <Form layout='vertical'
                    form={form}
                    scrollToFirstError={true}
                    onFinish={handleSubmit}
                    onValuesChange={() => console.log({ formData: form.getFieldsValue() })}

                >
                    <Row hidden={returnMap} justify='center'>
                        <RoundedSelect label='Customer Reference(Contact Id)' name='CustomerRef.value' placeholder='Type or insert..' required />
                        <RoundedSelect label='Total Amount' name='TotalAmt' placeholder='Type or insert..' required />
                        <RoundedSelect label='Transaction Date' name='TxnDate' placeholder='Type or insert..'/>
                        <RoundedSelect label='Unapplied Amount' name='UnappliedAmt' placeholder='Type or insert..' hint='The amount that has not been applied to pay amounts owed for sales transactions.' />
                        <RoundedSelect label='Private Note' name='PrivateNote' placeholder='Type or insert..' />
                        <RoundedSelect label='LookUp(Line[0].LinkedTxn[0].TxnId)' name='Line[0].LinkedTxn[0].TxnId' placeholder='Type or insert..' required />
                        <PrimaryButton onClick={() => setReturnMap(true)} className='btn-long' size='large' htmlType='button'>
                            Continue
                        </PrimaryButton>
                    </Row>
                    <Row hidden={!returnMap} justify='center'>
                        <Row className={message ? null : 'hidden'} justify='center'>
                            <Col span={22} className="with-prompt">
                                <Card className={'message'}>{message}</Card>
                            </Col>
                        </Row>
                        <RoundedSelect
                            label='Payment Id'
                            name='Id'
                            placeholder='Type or insert..'
                        />
                        <PrimaryButton className='btn-long' size='large' htmlType='submit'>
                            Continue
                        </PrimaryButton>

                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

export default QuickbooksPaymentForm;