import { Col, Table, Row, Button, Result, Select, Modal, Divider, List, DatePicker, Input, Form  } from 'antd';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { TableWrapper } from '../../Commons';
import { useHistory } from 'react-router-dom';
import { fetchLogs, fetchLogsByWorkflowId } from '../../Http/logs/log';
import { startCase } from 'lodash';
import './style.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;
      
const Logs = (props) => {
	const [data, setData] = useState([]);
	const [datum, setDatum] = useState(null);
	const [page, setPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState(undefined);
	const [error, setError] = useState(false);
	const history = useHistory();
	const [isModalVisible, setIsModalVisible] = useState(false);
	// const [dateRange, setDateRange] = useState({to: new Date().toISOString(), from: new Date().toISOString()});
	const [form] = Form.useForm();

	useEffect(() => {
		let workflowId = props.match.params.id;
		 workflowId ? getLogsByWorkflowId(workflowId) : getAllLogs();
	}, [page]);

	const getLogsByWorkflowId = async (workflowId, params={}) => {
		setLoading(true);
		params.page = page;
		const logRes = await fetchLogsByWorkflowId(workflowId, {...params});
		if(logRes) setLoading(false);
		if(logRes.code === 0){
			setData(logRes.data.logs);
			setError(false);
			if (!limit) setLimit(logRes.data.limit);
		} else {
			setError(true);
		}
	};
	const getAllLogs = async (params={}) => {
		setLoading(true);
		params.page = page;
		const logRes = await fetchLogs({...params});
		if(logRes) setLoading(false);
		if(logRes.code === 0){
			setData(logRes.data.logs);
			setError(false);
			if (!limit) setLimit(logRes.data.limit);
		} else {
			setError(true);
		}
	};

	const extractCols = () => {
		if (!data || data.length === 0) return [];

		let jsonKeys = Object.keys(data[0]);
		let columns = [];

		jsonKeys.forEach((v) => {
			console.log({v});
			if(v === 'sourceData' || v === 'responseData') {
				// escape
			} else {
				let cell = {
					title: v==='triggerId' ? 'Source Record Id' : v==='actionId' ? 'Desctination Record Id' : v==='remarks' ? 'Status' : startCase(v) ,
					dataIndex: v,
					key: v,
					align: 'center',
					ellipsis: true,
				};
				columns.push(cell);
			}
		});
		const action = {
			title: 'Action',
			key: 'operation',
			fixed: 'right',
			width: 75,
			render: (record) => <Button style={{background: '#252B6A', borderColor: '#252B6A'}} type="primary" danger onClick={()=>openViewModal(record)}>View Details</Button>,
		};
		columns.push(action);
		console.log({columns});

		return columns;
	};

	const openViewModal = (record) => {
		setDatum(record);
		setIsModalVisible(true);
	};


	const extractRows = () => {
		if (!data || data.length === 0) return [];
		let rows = [];
		data.forEach((v, k) => {
			rows.push({ ...v, key: k });
		});
		return rows;
	};

	const CustomPagination = () => {
		let style = {
			rowStyle: { margin: '.5rem' },
			colStyle: { marginRight: '.5rem' },
		};
		return (
			<Row justify='end' style={style.rowStyle}>
				<Col style={style.colStyle}>
					<Button disabled={page < 1} onClick={() => setPage(page - 1)}>
						<CaretLeftOutlined />
					</Button>
				</Col>
				<Col>
					<Button
						disabled={data.length < limit}
						onClick={() => setPage(page + 1)}>
						<CaretRightOutlined />
					</Button>
				</Col>
			</Row>
		);
	};

	const getListOfEntries = (object) => {
		let list = [];
		for(const [key, value] of Object.entries(object)){
			list.push({key, value});
		}
		return list;
	};

	const getDateBetween = (event) => {
		const to = new Date(event[0]._d);
		const from = new Date(event[1]._d);
		return {to, from};
	};

	const onFinishFilter = (value) => {
		const params = {};
		if(value.triggerId) params.triggerId = value.triggerId;
		if(value.actionId) params.actionId = value.actionId;
		if(value.status) params.status = value.status;
		if(value.dateRange) {
			const dateRange = getDateBetween(value.dateRange);
			params.to = dateRange.to;
			params.from = dateRange.from;
		}
		const workflowId = props.match.params.id;
		if(workflowId){
			params.workflowId = workflowId;
			getLogsByWorkflowId(workflowId, {...params})
		}else{
			getAllLogs({...params});
		}
	}

	return (
		
		<>
		<div style={{justifyContent: 'start', display: 'flex', width: '100%'}}>
			<Form
				form={form}
				name="advanced_search"
				className="ant-advanced-search-form"
				onFinish={onFinishFilter}
				style={{width: '100%'}}
				>
				<Row gutter={24}>
					<Col span={4} style={{paddingLeft: '20px'}}>Source Record Id : </Col>
					<Col span={5}>Desctination Record Id : </Col>
					<Col span={4}>Status : </Col>
					<Col span={7}>Select Date Range : </Col>
				</Row>
				<Row gutter={24}>
					<Col span={4} style={{paddingLeft: '20px'}}>
						<Form.Item name="triggerId">
							<Input placeholder='Source Record Id' />
						</Form.Item>
					</Col>
					<Col span={5}>
						<Form.Item name="actionId" >
							<Input placeholder='Desctination Record Id' />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item name="status" >
							<Select
								placeholder="Select status"
								allowClear
							>
								<Option value="SUCCESS">SUCCESS</Option>
								<Option value="FAILED">FAILED</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item name="dateRange">
							<RangePicker onChange={(event)=> getDateBetween(event)} />
						</Form.Item>
					</Col>
					<Col span={4} style={{textAlign: 'right'}}>
						<Button type="primary" htmlType="submit">
							Search
						</Button>
						<Button style={{margin: '0 8px'}} onClick={() => {form.resetFields()}}>
							Clear
						</Button>
					</Col>
				</Row>
				{/* <Row>
					<Col span={24} style={{textAlign: 'right'}}>
						<Button type="primary" htmlType="submit">
							Search
						</Button>
						<Button style={{margin: '0 8px'}} onClick={() => {form.resetFields()}}>
							Clear
						</Button>
					</Col>
				</Row> */}
			</Form>
				{/* <div>
					<Input  />
				</div>
				<div>
					<RangePicker onChange={(event)=> getDateBetween(event)} />
				</div> */}
			</div>
			<Divider style={{marginTop:"0px"}} />
			
			<TableWrapper
			//  className='wrapper'
			row
			 >
				{!error ? (
					<>
					<Modal className="log-modal" title={<Divider orientation="center">Log Details</Divider>} width={1050} visible={isModalVisible} onOk={()=>setIsModalVisible(false)} onCancel={()=>setIsModalVisible(false)}>
							{
								datum
								?
								(
									<>
									
										<List
										size="small"
										dataSource={getListOfEntries(datum)}
										renderItem={(item) => 
											item.key !== 'key'
											? 
											<List.Item key={item.key}>
												<p style={{textTransform: 'capitalize', width: '15%'}}>
												{ 
													item.key === 'triggerId' ? 'Source Record Id' : item.key==='actionId' ? 'Desctination Record Id' : item.key 
												}
											: </p>
												<p style={{paddingLeft: '20px', width: '85%'}}>{item.value}</p>
											</List.Item>
											: 
											<></>
										}
										/>
									</>
								)
								: ''
							}
					</Modal>
						<Table
							loading={loading}
							scroll={{ x: 'max-content' }}
							pagination={false}
							dataSource={extractRows()}
							columns={extractCols()}></Table>
						<CustomPagination />
					</>
				) : (
					<Result
						status='404'
						title='404'
						subTitle='Sorry, the page you visited does not exist.'
						extra={
							<Button
								type='primary'
								onClick={() => history.replace('/system/workflows')}>
								Back Home
							</Button>
						}
					/>
				)}
			</TableWrapper>
		</>
	);
};

export default Logs;
