/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Form, Select } from 'antd';
import './style.scss';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { PrimaryButton } from '../../Commons';
import { requiredRule, flatten } from '../../Commons/utils';
import { useDispatch, useSelector } from 'react-redux';
import { addMappings, getMappings } from '../../redux/slices/mapping';
import { fetchXeroOrgs } from '../../Http/xero';
import { OrganizationXero } from '../../Http/models/xero/Organization.model';
import { keys as keycreate, isObject } from 'lodash';


const CustomerForm = () => {
	const {
		sampleData,
		sampleData2,
		setActionData,
		mappings,
		setMappings,
		setOrganisation,
		form
	} = useContext(WorkflowContext);
	const { id, next } = useContext(StepContext);
	const [keys, setKeys] = useState([]);
	const [organisations, setOrganisations] = useState(OrganizationXero.List());
	const [returnMap, setReturnMap] = useState(false);

	const addressType = [
		{ label: 'Postal Address', value: 'POBOX' },
		{ label: 'Physical Address', value: 'STREET' },
	];

	const dispatch = useDispatch();
	const getAllMappings = useSelector(getMappings);

	useEffect(() => {
		setKeys(keycreate(sampleData));
		console.log({ mappings, sampleData, sampleData2, getAllMappings });
		let tmp = {};
		mappings?.forEach((v) =>
			(v.type === 'TRIG')
				?
				(
					tmp[v.field] = [`${v.valueStepOrder}.${v.value}`],
					tmp[v.label] = `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}`
				)
				:
				(v.type === 'ACT')
					?
					(

						tmp[v.value] = [`${v.valueStepOrder}.${v.field}`],
						tmp[v.label] = `${v.valueStepOrder}.${v.field}: ${sampleData[v.value]}`
					)
					:
					(tmp[v.field] = `${v.value}`)
		);
		if (tmp) {
			form.setFieldsValue(tmp);
		}
	}, []);

	useEffect(() => {
		//fetch organisations
		getXeroOrgs();
	}, []);

	const getXeroOrgs = async () => {
		const orgsRes = await fetchXeroOrgs();
		setOrganisations(orgsRes.data);
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((rawFormData) => {
				let ACTION_DATA = {}; //Data to be send as a product of the trigger
				let MAPPINGS = [];

				let flatFormData = flatten({ ...rawFormData });
				console.log({ flatFormData });

				keycreate(flatFormData)
					.filter((k) => flatFormData[k] && !Array.isArray(flatFormData[k]))
					.map((key) => {
						// console.log({key});
						let val = flatFormData[key].split('.');
						// console.log({val});
						let kname = val.length > 1 ? val[1] : val[0];
						// console.log({kname});
						let snum = val.length > 1 ? val[0] : undefined;
						// console.log({snum});
						let kval =
							snum && parseInt(snum) === 1
								? sampleData[kname]
								: snum && parseInt(snum) === 2
									? sampleData2[kname]
									: kname;
						// console.log({kval});
						ACTION_DATA = { ...ACTION_DATA, [key]: kval };
						// console.log({ACTION_DATA});
						MAPPINGS =
							snum
								?
								key === 'SyncToken'
								?
								[...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum },]
								:
								key === 'Id'
								?
								[...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum },]
								:
								[...MAPPINGS, {type: 'TRIG', field: key, value: kname, stepId: id, valueStepOrder: snum,},]
								:
								key === 'Id'
								?
								[...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id },]
								:
								[...MAPPINGS, { type: 'CON', field: key, value: kname, stepId: id },]
							;
						// console.log({MAPPINGS});
					});

				setActionData(ACTION_DATA);
				// console.log({ACTION_DATA});
				setMappings(MAPPINGS);
				// console.log({MAPPINGS});
				dispatch(addMappings(MAPPINGS));
				next();
			})
			.catch((e) => console.log(e));
	};

	const RoundedSelect = (props) => {
		const {
			label,
			placeholder,
			name,
			required,
			specialOptions,
			specialOptionsOnly,
			hint,
			onSelect,
		} = props;

		return (
			<Col span={22}>
				<div className='label'>
					{label} <span className={required ? 'fr' : 'hidden'}>(required)</span>
				</div>
				<Form.Item
					name={name}
					required={required}
					rules={required ? [requiredRule] : null}>
					<Select
						className='rounded'
						mode={specialOptions ? null : 'tags'}
						placeholder={placeholder}
						allowClear
						onSelect={onSelect}>
						{specialOptions &&
							specialOptions.map((v) => (
								<Select.Option key={v.value} value={v.value}>
									<span className="naim">{v.label}</span>
								</Select.Option>
							))}

						{!specialOptionsOnly &&
							keys.map(
								(v, k) =>
									!isObject(sampleData[v]) && (
										<Select.Option value={`1.${v}`} key={`1.${k}`}>
											<div className='opt-div'>
												<span className='key'>{`1.${v}:  `}</span>
												{`${sampleData[v]}`}
											</div>
										</Select.Option>
									)
							)}
					</Select>
				</Form.Item>
				{hint && <div className='hint'>{hint}</div>}
			</Col>
		);
	};

	return (
		<Row className='padded xinvform'>
			<div className='title'> {returnMap ? 'Return Mapping' : 'Customize Customer'} </div>
			<Col span={24}>
				<Form
					layout='vertical'
					form={form}
					scrollToFirstError={true}
					onFinish={handleSubmit}
					onValuesChange={() => {
						if (form.getFieldsValue().organisation) {
							setOrganisation(form.getFieldsValue().organisation)
						}
					}}
					initialValues={{
						'Phones[0].PhoneType': 'DEFAULT',
						'Phones[1].PhoneType': 'FAX',
						'Phones[2].PhoneType': 'MOBILE',
						'Phones[3].PhoneType': 'DDI',
					}}>
					<Row hidden={returnMap} justify='center'>
						<RoundedSelect
							label='Company Name'
							name='CompanyName'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Display Name'
							name='DisplayName'
							required={true}
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Title'
							name='Title'
							placeholder='Type or insert..'
							required={true}
						/>
						<RoundedSelect
							label='Given Name(First Name)'
							name='GivenName'
							placeholder='Type or insert..'
							required={true}
						/>
						<RoundedSelect
							label='Middle Name'
							name='MiddleName'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Family Name(Last Name)'
							name='FamilyName'
							placeholder='Type or insert..'
							required={true}
						/>
						<RoundedSelect
							label='Suffix'
							name='Suffix'
							placeholder='Type or insert..'
							required={true}
						/>
						<RoundedSelect
							label='Fully Qualified Name'
							name='FullyQualifiedName'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Primary Email Address'
							name='PrimaryEmailAddr.Address'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Primary Phone'
							name='PrimaryPhone.FreeFormNumber'
							placeholder='Type or insert..'
						/>
						
						<RoundedSelect
							label='Bill Address(Country Sub Division Code)'
							name='BillAddr.CountrySubDivisionCode'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Bill Address(City)'
							name='BillAddr.City'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Bill Address(Postal Code)'
							name='BillAddr.PostalCode'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Bill Address(Line1)'
							name='BillAddr.Line1'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Bill Address(Country)'
							name='BillAddr.Country'
							placeholder='Type or insert..'
						/>

						<PrimaryButton onClick={() => setReturnMap(true)} className='btn-long' size='large' htmlType='button'>
							Continue
						</PrimaryButton>
					</Row>
					<Row hidden={!returnMap} justify='center'>
						<RoundedSelect
							label='Customer Id'
							name='Id'
							placeholder='Type or insert..'
							required={true}
						/>
						<RoundedSelect
							label='Sync  Token'
							name='SyncToken'
							placeholder='Type or insert..'
							required={true}
						/>
						<PrimaryButton className='btn-long' size='large' htmlType='submit'>
							Continue
						</PrimaryButton>
					</Row>

				</Form>
			</Col>
		</Row>
	);
};

export default CustomerForm;
