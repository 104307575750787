/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { Row, Col, Form, Select, Spin } from 'antd';
import { Loading3QuartersOutlined, RightOutlined } from '@ant-design/icons';
import { PrimaryButton, SecondaryButton, TableWrapper } from '../Commons';
import { fetchApps, fetchTriggerEvents, fetchActionEvents, createWorkFlow } from '../Http/workflow';
import { useHistory } from 'react-router-dom';
// import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addApps, getAllApps } from '../redux/slices/apps';

const antIcon = (
	<Loading3QuartersOutlined
	  style={{
		fontSize: 24,
	  }}
	  spin
	/>
  );
const AddWorkflow = () => {
	const [loading, setLoading] = useState(false);
	const [eventVisible, setEventVisible] = useState(false);
	const [btnVisible, setBtnVisible] = useState(false);
	const [triggerEvents, setTriggerEvents] = useState([]);
	const [actionEvents, setActionEvents] = useState([]);
	const [form] = Form.useForm();
	const history = useHistory();
	const dispatch = useDispatch();
	const apps = useSelector(getAllApps);
	const [formupdates, setFormUpdates] = useState(null)

	const actiontriggeritems = [
		{ trigger: "FITM", action: "NSPE" },
		{ trigger: "XNINV", action: "SFCSIN" },
		{ trigger: "XIUP", action: "UREC" },
		{ trigger: "XQUP", action: "USFQ" },
		{ trigger: "XNPR", action: "SFNPR" },
		{ trigger: "XNCRN", action:"SFCRN"},
		{ trigger: "QNPR", action:"SFSQP"},
		{ trigger: "SFIPDF", action:"XIPDF"}

	]
	const actiontriggeritemsforquickbooks = [
		{ trigger: "QNPR", action:"SFSQP"},
		{ trigger: "UQCUS", action:"UREC"},
		{ trigger: "UQITM", action:"UREC"},
		{ trigger: "UQINV", action:"UREC"}

	]

	useEffect(() => {
		getApps();
	}, []);

	/**
	 * set workflow.apps state
	 */
	const getApps = async () => {
		const appsRes = await fetchApps();
		dispatch(addApps(appsRes.data));
	};

	/**
	 * handle multiple forms || 
	 * useForm from ant design || 
	 * formItemNames: origin | destination | trigger | action
	 */
	const handleChange = () => {
		const formVal = form.getFieldsValue();
		const { origin, destination, trigger, action } = formVal;

		setFormUpdates(formVal)
		if (!origin || !destination) {
			form.setFieldsValue({ origin, destination });
			setEventVisible(false);
			setBtnVisible(false);
		} else if (!trigger || !action) {
			setEventVisible(true);
			setBtnVisible(false);
		} else {
			setBtnVisible(true);
		}
	};

	/**
	 * sets the list of triggerable events for the given appName from the response
	 * @param {String} appName 'SFDC' || 'XERO' || 'QCKB'
	 */
	const getTriggerEvents = async (appName) => {
		const triggerEvents = await fetchTriggerEvents(appName);
		console.log({triggerEvents})
		
		let data = triggerEvents?.data;
		if(appName === 'SFDC'){
			data = triggerEvents?.data.filter(i=> 
				i.eventCode === 'NREC' || 
				i.eventCode === 'SFIPDF');
		}else if(appName === 'XERO'){
			data = triggerEvents?.data.filter(i => 
				i.eventCode === 'XQUP' || 
				i.eventCode === 'XIUP' || 
				i.eventCode === 'FITM' || 
				i.eventCode === 'XNPR' || 
				i.eventCode === 'XNCRN'|| 
				i.eventCode === 'XIPDF'
			);
		}
		else if(appName === 'QCKB'){
			data = triggerEvents?.data.filter(
				i => i.eventCode === 'QNPR'|| 
				i.eventCode === 'UQCUS'|| 
				i.eventCode === 'UQITM'|| 
				i.eventCode === 'UQINV'
			);
		}

		// if(appName === 'SFDC'){
		// 	data = triggerEvents?.data.filter(i=> i.shortDesc === 'New Record' || i.eventCode === 'SFIPDF');
		// }else if(appName === 'XERO'){
		// 	data = triggerEvents?.data.filter(i => i.shortDesc === 'Update Quote' || i.shortDesc === 'Update Invoice' || i.shortDesc === 'New or modify Xero Item' || i.shortDesc === 'New Payment Record' || i.shortDesc === 'New Credit Note Record'|| i.eventCode === 'XIPDF');
		// }
		
		// else if(appName === 'QCKB'){
		// 	data = triggerEvents?.data.filter(
		// 		i => i.shortDesc === 'New Quickbooks Payment Record'|| 
		// 		i.shortDesc === 'Updated Quickbooks Customer Record'|| 
		// 		i.shortDesc === 'Updated Quickbooks Item Record'|| 
		// 		i.shortDesc === 'Updated Quickbooks Invoice Record'
		// 	);
		// }
		setTriggerEvents(data);

	};

	/**
	 * sets the list of triggerable events for the given appName from the response
	 * @param {String} appName 'SFDC' || 'XERO' || 'QCKB'
	 */
	const getActionEvents = async (appName) => {
		console.log({appName})
		const actionEvents = await fetchActionEvents(appName);
		console.log("----------")
		console.log({actionEvents})

		const newActionevent = 
		// appName === "XERO" ? actionEvents.data.map(item => ({
		// 	...item,
		// 	orderId: item.id === 6 ? 0 :
		// 		item.id === 7 ? 1 :
		// 			item.id === 5 ? 2 :
		// 				item.id === 14 ? 3 :
		// 					item.id === 19 ? 4 :
		// 					undefined
		// }))
		// .sort((a,b) => a.orderId - b.orderId) 
		// : 
		actionEvents.data

		setActionEvents(newActionevent);
	};

	/**
	 * ChooseApp ||
	 * parent component AddWorkflow ||
	 * formItem - origin & destination
	 * @returns Component
	 */
	const ChooseApp = () => {
		return (
			<Row justify='center' 
			// className='wf-row'
			>
				<Col span={5}>
					<label className='connect-app-label'>Connect this app...</label>
					<Form.Item name='origin'>
						<Select
							// className='select-app'
							style={{backgroundColor:'red !important'}}
							className='select-app-field'
							placeholder='Search for an app'
							showSearch
							allowClear
							onChange={handleChange}
							onSelect={getTriggerEvents}>
							{apps &&
								apps?.map((v, k) => (
									<Select.Option value={v.code} key={k}>
										{v.name}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
				</Col>
				{/* <RightOutlined className='right-arrow' /> */}
				<Col span={1}></Col>
				<Col span={5}>
					<label className='connect-app-label'>with this one</label>
					<Form.Item name='destination'>
						<Select
							// className='select-app'
							className='select-app-field'
							placeholder='Search for an app'
							showSearch
							allowClear
							onChange={handleChange}
							onSelect={getActionEvents}>
							{apps &&
								apps.filter(value => value.code !== formupdates?.origin).map((v, k) => (
									<Select.Option value={v.code} key={k}>
										{v.name}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
				</Col>
			</Row>
		);
	};

	/**
	 * ChooseEvent || 
	 * parent component - AddWorkflow ||
	 * formItem - trigger & action
	 * @returns Component
	 */
	const ChooseEvent = () => {
		return (
			<Row justify='center' className={eventVisible ? 'wf-row' : 'hidden'}>
				<Col span={5}>
					<label className='connect-app-label'>When this happens...</label>
					<Form.Item name='trigger'>
						<Select
							className='select-action-field'
							placeholder='Search for an app'
							showSearch
							allowClear
							onChange={handleChange}>
							{triggerEvents &&
								triggerEvents.filter(value => value.id !== 8).map((v, k) => (
									<Select.Option value={v.eventCode} key={k}>
										{v.shortDesc}

									</Select.Option>
								))}
						</Select>
					</Form.Item>
				</Col>
				{/* <RightOutlined className='right-arrow' /> */}
				<Col span={1}></Col>
				<Col span={5}>
					<label className='connect-app-label'>then do this</label>
					<Form.Item name='action'>
						<Select
							className='select-action-field'
							placeholder='Select an action'
							showSearch
							allowClear
							onChange={handleChange}>
							{actionEvents && formupdates?.origin === "XERO" ?
								actionEvents.filter(value => value.eventCode === actiontriggeritems.find(value => value.trigger === formupdates?.trigger)?.action).map((v, k) => (
									<Select.Option value={v.eventCode} key={k}>
										{v.shortDesc}
									</Select.Option>
								)) :
								formupdates?.origin === "QCKB" ?
								actionEvents.filter(value => value.eventCode === actiontriggeritemsforquickbooks.find(value => value.trigger === formupdates?.trigger)?.action).map((v, k) => (
									<Select.Option value={v.eventCode} key={k}>
										{v.shortDesc}
									</Select.Option>
								)):
								 actionEvents.map((v, k) => (
									<Select.Option value={v.eventCode} key={k}>
										{v.shortDesc}

									</Select.Option>
								))}
						</Select>
					</Form.Item>
				</Col>
			</Row>
		);
	};

	/**
	 * ActionItems || 
	 * parent component - AddWorkflow ||
	 * form submitting button
	 * @returns Component
	 */
	const ActionItems = () => {
		const handleCancel = () => {
			form.setFieldsValue({
				...form.getFieldsValue,
				trigger: undefined,
				action: undefined,
			});
			setBtnVisible(false);
			setEventVisible(false);
		};
		const submitWorkFlow = async () => {
			// getting flow information
			const triggerId = triggerEvents.find(value => value.eventCode === form.getFieldsValue().trigger).id
			const actionId = actionEvents.find(value => value.eventCode === form.getFieldsValue().action).id
			const formFields = form.getFieldsValue();
			formFields.action = actionId;
			formFields.trigger = triggerId;
			setLoading(true);
			// starting flow creation
			const newWorkFlowRes = await createWorkFlow(formFields);

			if(newWorkFlowRes.data){
				setLoading(false)
			}
			console.log({newWorkFlowRes});
			if (newWorkFlowRes.code === 0) history.push(`/system/editor/${newWorkFlowRes.data}`);
		};

		return (
			<Row justify='center' className={btnVisible ? null : 'hidden'}>
				<Col span={10}>
					<Row justify='center'>
						<Col className='btn'>
							<SecondaryButton
								size='large'
								className='btn'
								onClick={handleCancel}>
								Cancel
							</SecondaryButton>
						</Col>
						<Col className='btn'>
							<PrimaryButton
								size='large'
								className='btn'
								onClick={submitWorkFlow}>
								{loading ?<Spin indicator={antIcon} /> : "Create"}
							</PrimaryButton>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};

	return (
		// <TableWrapper style={{ paddingTop: '1px' }} className='wrapper common-style'>
		<TableWrapper id='create-workflow-card-style'>	
			<div className='work-flow-create-title'>Create your own workflow</div>
			<div className='work-flow-create-message-1'>
				Know exactly what you want to build?
			</div>
			<div className='work-flow-create-message-2'>
			Select the apps you want to connect
				to start your custom setup.{' '}
			</div>
			<Row justify='center'>
				<Col span={24}>
					<Form layout='vertical' size='large' form={form}>
						<ChooseApp />
						<ChooseEvent />
						<ActionItems />
					</Form>
				</Col>
			</Row>
		</TableWrapper>
	);
};

export default memo(AddWorkflow);
