import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Statistic } from 'antd';
import './style.scss';
import ColumnChart from '../../Components/charts/ColumnChart';
import { ArrowUpOutlined, ArrowDownOutlined, DashboardOutlined, PieChartOutlined  } from '@ant-design/icons';
import useTransaction from '../../hooks/useTransaction';
import { fetchWorkFlows } from '../../Http/workflow';

const DashBoard = () => {
	
	const[transactions, loadingTransaction] =useTransaction();
	const [workFlows, setWorkFlows]= useState(null);
	const plan = JSON.parse(localStorage.getItem("plan"));

	useEffect(()=>{
		fetchAllWorkFlows();
	},[])
	const fetchAllWorkFlows = async()=>{
		const result = await fetchWorkFlows();
		if(result?.code === 0){
			setWorkFlows(result?.data);
		}
	}
	
	//the commented code bellow is replaced by hook

	// const [loadingTransaction, setLoadingTransaction] = useState(false);
	// const [transactions, setTransactions] = useState(new Transaction());
	// useEffect(()=>{
	// 	getTransactions();
	// },[])
	
	// const getTransactions = async () => {
	// 	setLoadingTransaction(true);
	// 	const transactionRes = await fetchTransactions();
	// 	if(transactionRes.code === 0){
	// 		setTransactions(transactionRes.data);
	// 	}
	// 	setLoadingTransaction(false);
	// }

	const transactionFilter = (action) => {
		let response = null;
		const transactionByWorkflow = transactions.transactionsByWorkflow;
		switch(action){
			case 'active':
				response = workFlows?.filter(t => t.status === "02").length;
				break;
			case 'inactive':
				response = workFlows?.filter(t => t.status === "01").length;
				break;
			case 'transactions':
				response = transactionByWorkflow.reduce((total, currentValue) => total + currentValue['transactions'], 0);
				break;
			default:
				response = 0;
		}
		return response;
	}

	return (
		<>
			<Col span={24} className="">
				<Row gutter={16}>
					<Col span={6}>
						<Card>
							<Statistic 
								loading={loadingTransaction} 
								title="Active Workflows" 
								value={transactionFilter('active')} 
								valueStyle={{
									color: '#3f8600',
								}}
								prefix={<ArrowUpOutlined />} />
						</Card>
					</Col>
					<Col span={6}>
						<Card>
							<Statistic 
								loading={loadingTransaction} 
								title="Inactive Workflows" 
								value={transactionFilter('inactive')} 
								valueStyle={{
									color: '#cf1322',
								}}
								prefix={<ArrowDownOutlined />} />
						</Card>
					</Col>
					<Col span={6}>
						<Card>
							<Statistic 
								loading={loadingTransaction} 
								title="Consumed transactions" 
								value={transactionFilter('transactions')} 
								valueStyle={{
									color: '#6294FA'
								}}
								prefix={<PieChartOutlined />} 
							/>
						</Card>
					</Col>
					<Col span={6}>
						<Card>
							<Statistic loading={loadingTransaction} title="Total transactions" value={plan?.noOftransaction ? plan.noOftransaction : 0} prefix={<DashboardOutlined />} />
						</Card>
					</Col>
				</Row>
			</Col>
			<Col span={12} className="padded">
				<ColumnChart 
					data={transactions.transactionsByWorkflow} 
					xField={'workflowName'} 
					yField={'transactions'} 
					sliderStart={0}
					sliderEnd={1}
				/>
			</Col>
			<Col span={12} className="padded">
				<ColumnChart 
					data={transactions.transactionsByDate} 
					xField={'scheduleStartTime'} 
					yField={'transactions'} 
					sliderStart={0}
					sliderEnd={1}
				/>
			</Col>
		</>
	);
}
export default DashBoard;
