import { api, FormatResponse } from '../index';
import { Connection } from '../models/connection/connection';
const CONNECTION_URL = '/api/v1/connections';

export const fetchConnections = async () => {
        const connectionRes = await api.get(`${CONNECTION_URL}`);
        return new ConnectionListResFormat(connectionRes);
};

export const fetchUser = async () => {
        const connectionRes = await api.get('/account/details');
        return new FormatResponse(connectionRes);
};

export const removeConnectionById = async (id) => {
        const connectionRes = await api.put(`${CONNECTION_URL}/${id}`);
        return new ConnectionResFormat(connectionRes);
};


class ConnectionResFormat extends FormatResponse {
        constructor(response){
                super(response);
                this.data = new Connection(this.data);
        }
}

class ConnectionListResFormat extends FormatResponse {
        constructor(response){
                super(response);
                this.data = Connection.List(this.data);
        }
}