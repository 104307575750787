import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Form, Select } from 'antd';
import './style.scss';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { keys as keycreate, isObject } from 'lodash';
import { PrimaryButton } from '../../Commons';
import { api } from '../../Http';
import { requiredRule, flatten } from '../../Commons/utils';
import { bool } from 'prop-types';
const ItemForm = () => {
  const { sampleData, sampleData2, setActionData, mappings, setMappings, setOrganisation, form } = useContext(WorkflowContext);
  const { id, next } = useContext(StepContext);

  const [keys, setKeys] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [returnMap, setReturnMap] = useState(false);

  const [trackedInventory, setTrackedInventory] = useState(false)

  const taxTypes = [
    { label: 'GST on Income (OUTPUT) ', value: 'OUTPUT' },
    { label: 'GST on Expenses (INPUT)', value: 'INPUT' },
    { label: 'GST Free Expenses (EXEMPTEXPENSES)', value: 'EXEMPTEXPENSES' },
    { label: 'GST Free Income (EXEMPTOUTPUT)', value: 'EXEMPTOUTPUT' },
    { label: 'BAS Excluded (BASEXCLUDED)', value: 'BASEXCLUDED' },
    { label: 'GST on Imports (GSTONIMPORTS)', value: 'GSTONIMPORTS' },
  ];

  const boolOptions = [
    { label: 'True', value: true },
    { label: 'False', value: false },
  ];


  // useEffect(() => {
  //   setKeys(keycreate(sampleData));
  // }, []);

  useEffect(() => {
    //fetch organisations
    api
      .get('/api/xero/organisations')
      .then((resp) => {
        setOrganisations(resp.data.data);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    sampleData !== undefined && setKeys(keycreate(sampleData));
    let tmp = {};

    if(sampleData !== undefined){
      mappings?.forEach((v) =>
        (v?.type === 'TRIG')
          ?
          (
            tmp[v?.field] = [`${v?.valueStepOrder}.${v?.value}`],
            tmp[v?.label] = `${v?.valueStepOrder}.${v?.value}: ${sampleData[v?.value]}`
          )
          :
          (v.type === 'ACT')
            ?
            (

              tmp[v?.value] = [`${v?.valueStepOrder}.${v?.field}`],
              tmp[v?.label] = `${v?.valueStepOrder}.${v?.field}: ${sampleData[v?.value]}`
            )
            :
            (tmp[v?.field] = `${v?.value}`)
      );
      if (tmp) {
        form.setFieldsValue(tmp);
      }

    }
    form.setFieldValue('IsTrackedAsInventory', trackedInventoryValueController());
  }, []);
  
  const trackedInventoryValueController = ()=>{
    let tmp = mappings?.length > 0 && mappings.find(m=>m.field === 'IsTrackedAsInventory')?.value === 'true' ? true : false;
    if(tmp){
      setTrackedInventory(true);
    }
    return tmp;
  }
  
  const handleSubmit = () => {
    form
      .validateFields()
      .then((rawFormData) => {
        let ACTION_DATA = {}; //Data to be send as a product of the trigger
        let MAPPINGS = [];

        let flatFormData = flatten({ ...rawFormData });


        keycreate(flatFormData)
          .filter((k) => flatFormData[k])
          .map((key) => {
            if (Array.isArray(rawFormData[key]) && rawFormData[key].length === 0) {
              // skip
            } else {
              let val = typeof flatFormData[key] !== 'boolean' && flatFormData[key]?.split('.');
              let kname = val.length > 1 ? val[1] : val[0];
              let snum = val.length > 1 ? val[0] : undefined;

              let kval = snum && parseInt(snum) === 1 ? sampleData[kname]
                : snum && parseInt(snum) === 2 ? sampleData2[kname]
                  : kname;

              ACTION_DATA = { ...ACTION_DATA, [key]: kval };
              MAPPINGS = snum ?
                (key === 'ItemID')
                  ? [...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum }]
                  : [...MAPPINGS, { type: 'TRIG', field: key, value: kname, stepId: id, valueStepOrder: snum }]

                :
                (key === 'ItemID')
                  ? [...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum }]
                  : [...MAPPINGS, { type: 'CON', field: key, value: kname, stepId: id }];
            }

            return key
          });
        // 
        if (flatFormData.IsTrackedAsInventory
          !== undefined && flatFormData.IsTrackedAsInventory === true) {
          ACTION_DATA.IsTrackedAsInventory = true
          const newmappings = MAPPINGS.find(value => value.field === "IsTrackedAsInventory")
          newmappings.value = true;
          newmappings.valueStepOrder = '1'

        }
        console.log({ ACTION_DATA, MAPPINGS });
        setActionData(ACTION_DATA);
        setMappings(MAPPINGS);
        next();
      })
      .catch((e) => console.log(e));
  };

  const RoundedSelect = (props) => {
    const { label, placeholder, name, required, specialOptions, specialOptionsOnly, hint, onSelect } = props;

    return (
      <Col span={22}>
        <div className='label'>
          {label} <span className={required ? 'fr' : 'hidden'}>(required)</span>
        </div>
        <Form.Item name={name} required={required} rules={required ? [requiredRule] : null}>
          <Select className='rounded' mode={specialOptions ? null : 'tags'}
            placeholder={placeholder} allowClear onSelect={onSelect}>

            {specialOptions && specialOptions.map((v) => <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>)}

            {!specialOptionsOnly &&
              keys.map(
                (v, k) =>
                  !isObject(sampleData[v]) && (
                    <Select.Option value={`1.${v}`} key={`1.${k}`}>
                      <div className='opt-div'>
                        <span className='key'>{`1.${v}:  `}</span>
                        {`${sampleData[v]}`}
                      </div>
                    </Select.Option>
                  )
              )}
          </Select>
        </Form.Item>
        {hint && <div className='hint'>{hint}</div>}
      </Col>
    );
  };

  return (
    <Row className='padded xinvform'>
      <div className='title'> {returnMap ? 'Return Mapping' : 'Customize Item'} </div>
      <Col span={24}>
        <Row hidden={returnMap} justify='center'>
          <RoundedSelect
            label='Organisation'
            name='Organisation'
            required={true}
            placeholder='Choose value'
            specialOptions={organisations}
            specialOptionsOnly={true}
            onSelect={(v) => {
              setOrganisation(v);
            }}
          />
        </Row>

        <Form layout='vertical'
          form={form}
          scrollToFirstError={true}
          onFinish={handleSubmit}
          onValuesChange={() => {

            if (form.getFieldsValue().IsTrackedAsInventory === true) {
              form.resetFields(['SalesDetails.AccountCode', 'PurchaseDetails.AccountCode'])
              setTrackedInventory(true)
            } else {
              form.resetFields(['InventoryAssetAccountCode', 'COGSAccountCode'])
              setTrackedInventory(false)
            }

            if (form.getFieldsValue().organisation) {
              setOrganisation(form.getFieldsValue().organisation)
            }
          }}

        >
          <Row hidden={returnMap} justify='center'>
            <RoundedSelect label='Is Tracked As Inventory' name='IsTrackedAsInventory' placeholder='Choose one..' specialOptions={boolOptions} specialOptionsOnly />
            {trackedInventory === true && <RoundedSelect label='Inventory Asset AccountCode' name='InventoryAssetAccountCode' placeholder='Type or insert..' required />}
            {trackedInventory === true && <RoundedSelect label='COGS AccountCode' name='PurchaseDetails.COGSAccountCode' placeholder='Type or insert..' required />}
            <RoundedSelect label='Name' name='Name' placeholder='Type or insert..' />
            <RoundedSelect label='Description' name='Description' placeholder='Type or insert..' />
            <RoundedSelect label='Item Code' name='Code' placeholder='Type or insert..' required />

            <RoundedSelect label='Price - Sales Details' name='SalesDetails.UnitPrice' placeholder='Type or insert..' />
            <RoundedSelect label='Tax Type - Sales Details' name='SalesDetails.TaxType' placeholder='Type or insert..' specialOptions={taxTypes} />
            {trackedInventory === false && <RoundedSelect label='Account Code - Sales Details' name='SalesDetails.AccountCode' placeholder='Type or insert..' />}
            <RoundedSelect label='Is Sold' name='IsSold' placeholder='Choose one..' specialOptions={boolOptions} specialOptionsOnly />


            <RoundedSelect label='Price - Purchase Details' name='PurchaseDetails.UnitPrice' placeholder='Type or insert..' />
            <RoundedSelect label='Tax Type - Purchase Details' name='PurchaseDetails.TaxType' placeholder='Type or insert..' specialOptions={taxTypes} />
            {trackedInventory === false && <RoundedSelect label='Account Code - Purchase Details' name='PurchaseDetails.AccountCode' placeholder='Type or insert..' />}
            <RoundedSelect label='Is Purchased' name='IsPurchased' placeholder='Choose one..' specialOptions={boolOptions} specialOptionsOnly />

            <RoundedSelect label='Purchase Description' name='PurchaseDescription' placeholder='Type or insert..' />

            <PrimaryButton onClick={() => setReturnMap(true)} className='btn-long' size='large' htmlType='button'>
              Continue
            </PrimaryButton>
          </Row>
          <Row hidden={!returnMap} justify='center'>
            <RoundedSelect
              label='ItemID'
              name='ItemID'
              placeholder='Type or insert..'
            />
            <PrimaryButton className='btn-long' size='large' htmlType='submit'>
              Continue
            </PrimaryButton>

          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default ItemForm;