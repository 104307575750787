export const creditNoteTypes = () => {
        return XeroConstants.List([
                { label: 'ACCPAYCREDIT', value: 'ACCPAYCREDIT' },
                { label: 'ACCRECCREDIT', value: 'ACCRECCREDIT' }
        ]);
};

export const creditNoteStatusCode = () => {
        return XeroConstants.List([
                { label: 'DRAFT', value: 'DRAFT' },
                { label: 'SUBMITTED', value: 'SUBMITTED' },
                { label: 'AUTHORISED', value: 'AUTHORISED' },
                { label: 'PAID', value: 'PAID' }
        ]);
};


export const creditNoteLineAmountTypes = () => {
        return XeroConstants.List([
                { label: 'EXCLUSIVE', value: 'Exclusive' },
                { label: 'INCLUSIVE', value: 'Inclusive' },
                { label: 'NOTAX', value: 'NoTax' }
        ]);
};

export const XeroSentToContactOpt = () => {
        return XeroConstants.List([
                { label: 'True', value: true },
                { label: 'False', value: false },
        ]);
};


export class XeroConstants {

        label = '';
        value = '';

        constructor(org=null) {
                if(org) {
                        this.label = org.label;
                        this.value = org.value;
                }
        }

        
        static List(initialValue = []) {
                const arr = [new this()];
                arr.shift();
                if(initialValue && initialValue.length > 0) initialValue.map(v => arr.push(v));
                return arr;
        }
}
