import { UserOutlined } from '@ant-design/icons'
import { Input, message, Modal } from 'antd'
import { useState, useCallback, useEffect } from 'react'
import { getActiveSubscription, getallsubscriptions } from '../../Http/pricing'
import CardInput from '../InitialPayment/CardInput'
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { api } from '../../Http'
import './style.scss'
import { StripePaymentCard } from '../../Components/StripePaymentCard'

const SubscriptionsPlan = () => {

    const [loading, setLoading] = useState(false);
    const [initChangeSubscription, setInitChangeSubscription] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const email = localStorage.getItem("email")
    const stripe = useStripe();
    const elements = useElements();
    const subs = JSON.parse(localStorage.getItem('subscription'))
    const planinfo = JSON.parse(localStorage.getItem("plan"))
    const [plan, setPlan] = useState(null)


    const [data, setData] = useState([])
    const fetchSubscriptions = useCallback(async () => {
        const response = await getallsubscriptions()
        setData(response.data)
    }, [])

    useEffect(() => {
        fetchSubscriptions()
    }, [fetchSubscriptions])

    const showModal = () => {
        setIsModalOpen(true);
    };


    const handleSubmitSubscription = async () => {
        setLoading(true)
        setInitChangeSubscription(true);
    }

    const subscriptionChanged = (success, result) => {
        setLoading(false)
        setInitChangeSubscription(false);
        setIsModalOpen(false);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };



    return (
        <div className="pricingplan">
            <div className="wrapper">
                <div>
                    <div className="title-text">Pricing Plan</div>
                    <div className="container">
                        <div className="grid">
                            {data && data.map((item, index) => {
                                return (
                                    <div className="card">
                                        <h2 className="card_title">{item.name}</h2>
                                        <span className="pricing">{item.currentprice}$</span><span className="small">/monthly</span>
                                        <br />
                                        <span>{item.noOftransaction} Transactions</span>
                                        <hr />
                                        <ul className="features">
                                            <li>Two way data integration</li>
                                            <li>Create Invoices from opportunities or Orders</li>
                                            <li>Sync Items from Saleforce Product</li>
                                        </ul>
                                        {item.subId === subs.subscriptionId || item.name === planinfo.name ? subs && subs.active !== 0 ?
                                            <button disabled className="cta_btn_disabled ">Activated Plan</button> : <button className="cta_btn_disabled" onClick={() => { setPlan(item.subplanid); showModal() }}>Reactive Plan</button> :
                                            <button className="cta_btn" onClick={() => { setPlan(item.subplanid);showModal() }}>Buy Now</button>}
                                    </div>
                                )
                            })}


                            <div className="card">
                                <h2 className="card_title">Custom</h2>
                                {/* <span className="pricing">On Demand</span> */}
                                <span>Price On Demand</span>
                                <br />
                                {/* <span>Save $9</span> */}
                                <hr />
                                <ul className="features">
                                    <li>On Demand</li>
                                    <li>On Demand</li>
                                    <li>On Demand</li>
                                </ul>
                                <a href="mailto: support@nettverk.io" className="cta_btn">Contact Us</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* Payment Modal */}
            <Modal title="Subscription Payment" visible={isModalOpen} okText="Subscribe" onOk={handleSubmitSubscription} onCancel={handleCancel} confirmLoading={loading}>

                {/* <Input placeholder='Enter Email Addresss' size='large' prefix={<UserOutlined />} disabled defaultValue={email} />
                <div className="card_details">
                    <CardInput />
                </div> */}
                <StripePaymentCard
                    initSumbit={initChangeSubscription}
                    onComplete={subscriptionChanged}
                    modalView={true}
                    priceId={plan}
                />
            </Modal>
        </div>

    )
}


export default SubscriptionsPlan