import { api, FormatResponse } from '../index';
import { WorkflowData } from '../models/WorkflowData.model';
const BASE_ENDPOINT ='/api/v1/workflows';

export const fetchApps = async () => {
        const appsRes = await api.get(`${BASE_ENDPOINT}/apps`);
        return new FormatResponse(appsRes);
};

export const fetchTriggerEvents = async (appName) => {
        const triggerEventsRes = await api.get(`${BASE_ENDPOINT}/triggers`, { params: { app: appName } });
        console.log({triggerEventsRes})
        return new FormatResponse(triggerEventsRes);
};

export const fetchActionEvents = async (appName) => {
        const actionEventsRes = await api.get(`${BASE_ENDPOINT}/actions`, { params: { app: appName } });
        console.log({actionEventsRes})
        return new FormatResponse(actionEventsRes);
                        
};

export const createWorkFlow = async (formValues) => {
        const newWorkflowRes = await api.post(`${BASE_ENDPOINT}`, formValues);
        return new FormatResponse(newWorkflowRes);
};

export const fetchWorkFlows = async () => {
        const workflowRes = await api.get(`${BASE_ENDPOINT}`);
        return new FormatResponse(workflowRes);
};

export const deleteWorkFlowById = async (id) => {
        const workflowRes = await api.delete(`${BASE_ENDPOINT}/${id}`);
        return new FormatResponse(workflowRes);
};
export const deleteWorkFlowByAdmin = async (id) => {
        const workflowRes = await api.delete(`/api/v1/procedure/workflow/${id}`);
        return new FormatResponse(workflowRes);
};

export const toggleStatus = async (id) => {
        const workflowRes = await api.patch(`${BASE_ENDPOINT}/${id}/status`);
        return new FormatResponse(workflowRes);
};

export const fetchWorkflowById = async (workflowId) => {
        const workflowSingleRes = await api.get(`/api/v1/workflows/${workflowId}`);
        return new WorkflowDataFormat(workflowSingleRes);
};
export const fetchWorkflowsByUserId = async (userId) => {
        const workflowSingleRes = await api.get(`/api/v1/workflows/userId/${userId}`);
        return workflowSingleRes;
};

export const fetchWorkflowsByAdmin = async (params) => {
        const workflowRes = await api.get(`/api/v1/workflows/fetch-workflows-by-admin`,{params});
        return workflowRes;
};

export const copyWorkFlow = async(formValues)=>{
        const workflow = await api.post(`/api/v1/workflows/copy-work-flow`, formValues);
        return new FormatResponse(workflow);
}


class WorkflowDataFormat extends FormatResponse {
        constructor(response) {
                super(response);
                this.data = new WorkflowData(this.data);
        }
}
