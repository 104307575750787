export class OrganizationXero {

        label = null;
        value = '';

        constructor(org=null) {
                if(org) {
                        this.label = org.label;
                        this.value = org.value;
                }
        }

        
        static List(initialValue = []) {
                const arr = [new this()];
                arr.shift();
                if(initialValue && initialValue.length > 0) initialValue.map(v => arr.push(v));
                return arr;
        }
}
