import { useEffect, useState } from 'react';
import { Button, Input, message} from 'antd';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import CardInput from './../Layout/InitialPayment/CardInput';
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { getActiveSubscription } from '../Http/pricing';
import { api } from '../Http';
import { useDispatch } from 'react-redux';
import { addSubscriptions } from '../redux/slices/subscriptions';
import './style.css';
import { useHistory } from 'react-router-dom';
import { Cookies } from 'react-cookie';
const cookie = new Cookies();

export const StripePaymentCard = ({priceId, showSubscriptionButton, showLogOutButton, initSumbit, updatePaymentMethod, modalView, onComplete}) => {
        console.log({priceId})

        const stripe = useStripe();
        const elements = useElements();
        const [cardEmail, setCardEmail] = useState(localStorage.getItem("email"));
        const [cardName, setCardName] = useState('');
        const [loading, setLoading] = useState(false);
        const dispatch = useDispatch();
        const history = useHistory();

        useEffect(()=>{
                if(initSumbit){
                        handleSubmit();
                }
        },[initSumbit])

        const handleSubmit = async () => {
                setLoading(true)
                if (!stripe || !elements) {
                    return;
                } else {
                        const result = await stripe.createPaymentMethod({
                                type: 'card',
                                card: elements.getElement(CardElement),
                                billing_details: {
                                        email: cardEmail,
                                }
                        });

                        if (result.error) {
                                console.log(result.error.message);
                                return;
                        }

                        if(!updatePaymentMethod){
                                const body = {
                                        paymentMethodId: result?.paymentMethod.id,
                                        priceId,
                                        cardEmail,
                                        cardName,
                                }
                
                                const response = await api.post('/api/v1/stripe/create-subscription', body)
                                if (response && response.status) {
                                    const activesub = await getActiveSubscription();
                                    dispatch(addSubscriptions(activesub.data))
                                    localStorage.setItem("subscription", JSON.stringify(activesub.data.subscription))
                                    localStorage.setItem("plan", JSON.stringify(activesub.data.plan))
                                    setLoading(false);
                                    message.success("Subscription Created Succcessfully")
                                    onComplete(true, response.data);
                                    history.push("/system/dashboard")
                                } else {
                                        onComplete(false, response.data);
                                }
                        } else {
                                const body = {
                                        paymentMethodId: result?.paymentMethod.id,
                                        cardEmail,
                                        cardName,
                                    }
                                    console.log({body})
                                    const response = await api.post('/api/v1/stripe/update-payment-method', body)
                                    if (response) {
                                        message.success("Payment Method Updated Successfully")
                                        onComplete(true, response.data);
                                    } else{
                                        onComplete(false, response.data);
                                    }
                        }
                }
        }
   
        const handleInputChange = (input) => {
                switch(input.target.name){
                        case 'cardEmail':
                                setCardEmail(input.target.value)
                                break;
                        case 'cardName':
                                setCardName(input.target.value)
                                break;
                        default:
                                break;
                }
        }
        const handleLogout = () => {
                cookie.remove('Authorization', { path: '/' });
                history.push('/login');
                dispatch(addSubscriptions({}))
                localStorage.clear()
        };


        return (
                <div className={modalView ? '' : 'main-div'}>
                        <h1 hidden={modalView} className='trail_text'>Enter Payment Information</h1>
                        <form autoComplete='off'>
                                <Input onChange={handleInputChange} name="cardEmail" placeholder='Email Addresss' size='large' prefix={<MailOutlined />} defaultValue={cardEmail} />
                                <br/>
                                <br/>
                                <Input onChange={handleInputChange} name="cardName" placeholder='Name On Card' size='large' prefix={<UserOutlined />} />
                                <br/>
                                <br/>
                                <div className="card_details">
                                        <CardInput />
                                </div>
                                <div className='button-margin'>
                                        <Button hidden={!showSubscriptionButton} onClick={handleSubmit} loading={loading} type="primary">Subscribe</Button>
                                        <Button hidden={!showLogOutButton} onClick={() => handleLogout()} style={{ marginLeft: "1rem" }} type="default">Logout</Button>
                                </div>
                        </form>
                </div>
        )
}
