import React from 'react';
import ChooseAccount from './Layout/Steps/ChooseAccount';
import SelectTriggerObject from './Layout/Salesforce/SelectTriggerObject';
import CustomizeRecordToFind from './Layout/Salesforce/CustomizeRecordToFind';
import TestTrigger from './Layout/Salesforce/TestTrigger';
import FindData from './Layout/Salesforce/FindData';
import InvoiceForm from './Layout/Xero/InvoiceForm';
import QuoteForm from './Layout/Xero/QuoteForm';
import CreditNoteForm from './Layout/Xero/CreditNoteForm';
import SendData from './Layout/Xero/SendData';
import { api } from './Http';
import ContactForm from './Layout/Xero/ContactForm';
import ItemForm from './Layout/Xero/ItemForm';
import { ENVIRONMENT } from './Http/API';
import { AddWebhook } from './Layout/Salesforce/AddWebhook';
import FindItemData from './Layout/Xero/FindItemData';
import SalesItemForm from './Layout/Salesforce/SalesItemForm';
import CustomFindWithTrigger from './Layout/Salesforce/CustomFindWithTrigger';
import InvoiceUpdateForm from './Layout/Xero/InvoiceUpdateForm';
import PaymentCustomizeRecordToFind from './Layout/Salesforce/PaymentCustomFindWithTrigger';
import SalesPaymentItemForm from './Layout/Salesforce/SalesPaymentItemForm';
import PurchaseOrderForm from './Layout/Xero/PurchaseOrderForm';
import CreditNoteCustomFindWithTrigger from './Layout/Salesforce/CreaditNoteCustomFindWIthTrigger';
import CreditNoteSelectTriggerObject from './Layout/Salesforce/CreditNoteSelectTriggerObject';
import CreditNoteTestTrigger from './Layout/Salesforce/CreditNoteTestTrigger';
import SalesCreditMapping from './Layout/Salesforce/SalesCreditMapping';
import CreditNoteFindData from './Layout/Salesforce/CreditNoteFindData';
import FindCreditNoteLineItem from './Layout/Salesforce/FindCreditNoteLineItem';
import QuoteUpdateForm from './Layout/Xero/QuoteUpdateForm';
import CustomFindQuoteWithTrigger from './Layout/Salesforce/CustomFindQuoteWithTrigger';
import CustomerForm from './Layout/Quickbooks/CustomerForm';
import SendDataToQuickbooks from './Layout/Quickbooks/SendDataToQuickbooks';
import QuickbooksItemForm from './Layout/Quickbooks/QuickbooksItemForm';
import QuickbooksInvoiceForm from './Layout/Quickbooks/QuickbooksInvoiceForm';
import QuickbooksPaymentForm from './Layout/Quickbooks/QuickbooksPaymentForm';
import PaymentCustomFindWithTriggerForQuickbooks from './Layout/Salesforce/PaymentCustomFindWithTriggerForQuickbooks';
import ContactUpdateForm from './Layout/Quickbooks/ContactUpdateForm';
import SalesObjDataFindForQuickbooks from './Layout/Salesforce/SalesObjDataFindForQuickbooks';
import ProductUpdateForm from './Layout/Quickbooks/ProductUpdateForm';
import QuickbooksInvoiceUpdateForm from './Layout/Quickbooks/QuickbooksInvoiceUpdateForm';
import InvoicePdfForm from './Layout/Xero/InvoicePdfForm';

const callback = ENVIRONMENT;

const SFDC_CALLBACK = callback.sfdc_callback;
const SFDC_CLIENT_ID = callback.sfc_client_id;

const SFDC_COMMON = [
	{
		title: 'Choose Account',
		content: () => (
			<ChooseAccount
				authRequestFunction={(environment, cb) => {
					window.SFDC_ENV = environment;
					const url = `https://${environment}.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${SFDC_CLIENT_ID}&redirect_uri=${SFDC_CALLBACK}`;
					const popWindow = window.open(url, 'newwindow', 'width=1000,height=600');
					const syncWindow = setInterval(() => {
						console.log('open');
						if (popWindow.closed) {
							cb();
							console.log('closed');
							clearInterval(syncWindow);
						}
					}, 500);
				}}
			/>
		),
	},
];

const XERO_COMMON = [
	{
		title: 'Choose Account',
		content: () => (
			<ChooseAccount
				authRequestFunction={(cb) => {
					api
						.get('/api/xero')
						.then((resp) => {
							if (resp.data.code === 0) {
								const popWindow = window.open(
									resp.data.data,
									'newwindow',
									'width=800,height=600'
								);
								const syncWindow = setInterval(() => {
									console.log('open');
									if (popWindow.closed) {
										cb();
										console.log('closed');
										clearInterval(syncWindow);
									}
								}, 500);
							}
						})
						.catch((e) => console.log(e));
				}}
			/>
		),
	},
];

const QUICKBOOKS_COMMON = [
	{
		title: 'Choose Account',
		content: () => (
			<ChooseAccount
				authRequestFunction={(cb) => {
					api
						.get('/api/quickbooks')
						.then((resp) => {
							if (resp.data.code === 0) {
								const popWindow = window.open(
									resp.data.data,
									'newwindow',
									'width=800,height=600'
								);
								const syncWindow = setInterval(() => {
									console.log('open');
									if (popWindow.closed) {
										cb();
										console.log('closed');
										clearInterval(syncWindow);
									}
								}, 500);
							}
						})
						.catch((e) => console.log(e));
				}}
			/>
		),
	},
];

const SFDC_NEW_RECORD = {
	steps: [
		...SFDC_COMMON,
		{
			title: 'Customize Record',
			content: () => <SelectTriggerObject />,
		},
		{
			title: 'Find Data',
			content: () => <TestTrigger showNext={true} />,
		},
		{
			title: 'Connect Webhook',
			content: () => <AddWebhook />
		}
	],
};

const SFDC_FIND_RECORD = {
	steps: [
		{
			title: 'Customize Record',
			content: () => <CustomizeRecordToFind />,
		},
		{
			title: 'Find Data',
			content: () => <FindData />,
		},
	],
};

const XERO_CREATE_SALES_INVOICE = {
	steps: [
		...XERO_COMMON,
		{
			title: 'Customize Record',
			content: () => <InvoiceForm />,
		},
		{
			title: 'Send Data',
			content: () => <SendData delegateToUri='/api/xero/delegate' />,
		},
	],
};

const XERO_CREATE_CREDIT_NOTE = {
	steps: [
		...XERO_COMMON,
		{
			title: 'Customize Record',
			content: () => <CreditNoteForm />,
		},
		{
			title: 'Send Data',
			content: () => <SendData delegateToUri='/api/xero/delegate' />,
		},
	],
};

const XERO_CREATE_OR_UPDATE_CONTACT = {
	steps: [
		...XERO_COMMON,
		{
			title: 'Customize Record',
			content: () => <ContactForm />,
		},
		{
			title: 'Send Data',
			content: () => <SendData delegateToUri='/api/xero/delegate' />,
		},
		// {
		// 	title: 'Return Map',
		// 	content: () => <ContactForm returnMap={true} />,
		// },
		// {
		// 	title: 'Send Return Data',
		// 	content: () => <SendData delegateToUri='/api/xero/delegate' />,
		// },
	],
};

const XERO_CREATE_OR_UPDATE_PURCHASE_ORDER = {
	steps: [
		...XERO_COMMON,
		{
			title: 'Customize Record',
			content: () => <PurchaseOrderForm />,
		},
		{
			title: 'Send Data',
			content: () => <SendData delegateToUri='/api/xero/delegate' />,
		},
	],
};

const XERO_CREATE_OR_UPDATE_ITEMS = {
	steps: [
		...XERO_COMMON,
		{
			title: 'Customize Record',
			content: () => <ItemForm />,
		},
		{
			title: 'Send Data',
			content: () => <SendData delegateToUri='/api/xero/delegate' />,
		},
	],
};

const XERO_TRIGGER_INVOICE_RECORD = {
	steps: [
		...XERO_COMMON,

		{
			title: 'Customize Record',
			content: () => <InvoiceForm showerror={false} />,
		},
		{
			title: 'Send Data',
			content: () => <SendData delegateToUri='/api/xero/delegate' />,
		},
	]
}

const XERO_NEW_MODIFY_ITEM = {
	steps: [
		...XERO_COMMON,
		{
			title: 'Find Data',
			content: () => <FindItemData />,
		},
	]
}

const XERO_NEW_MODIFY_INVOICE = {
	steps: [
		...XERO_COMMON,
		{
			title: 'Customize Record',
			content: () => <CustomizeRecordToFind />,
		},
		{
			title: 'Find Data',
			content: () => <FindData />,
		},
	]
}

const SALES_FORCE_CREATE_NEW_SALES_INVOICE = {
	steps: [
		...SFDC_COMMON,

	]
}

const SALES_FORCE_CREATE_NEW_PRODUCT_RECORD = {
	steps: [
		...SFDC_COMMON,
		{
			title: 'Customize Record',
			content: () => <SelectTriggerObject />,
		},
		{
			title: 'Find Data',
			content: () => <TestTrigger showNext={true} />,
		},
		{
			title: 'Customize Record',
			content: () => <SalesItemForm />,
		}
	]
}

const SFDC_UPDATE_RECORD = {
	steps: [
		...SFDC_COMMON,
		{
			title: 'Customize Record',
			content: () => <CustomFindWithTrigger />,
		},
		{
			title: 'Invoice Mapping',
			content: () => <InvoiceUpdateForm />,
		},
	],
};

const XERO_UPDATE_INVOICE_RECORD = {
	steps: [
		...XERO_COMMON,
	],
}

const XERO_NEW_PAYMENT_RECORD = {
	steps: [
		...XERO_COMMON,
		{
			title: 'Find Data',
			content: () => <FindItemData />,
		},
	]
}

const SFDC_CREATE_OR_UPDATE_PAYMENT = {
	steps: [
		...SFDC_COMMON,

		{
			title: 'Find Salesforce Object',
			content: () => <SelectTriggerObject />,
		},
		{
			title: 'Find Data',
			content: () => <TestTrigger showNext={true} />,
		},
		{
			title: 'Find Parent Record',
			content: () => <PaymentCustomizeRecordToFind />,
		},
		{
			title: 'Payment Mapping',
			content: () => <SalesPaymentItemForm />,
		},
	]
}

const NEW_XERO_CREDIT_NOTE = {
	steps: [
		...XERO_COMMON,
		{
			title: 'Find Data',
			content: () => <FindItemData />,
		},
	]
}

const SFDC_CREATE_UPDATE_CREDIT_NOTE = {
	steps: [
		...SFDC_COMMON,

		{
			title: 'Find Parent Salesforce Object',
			content: () => <CreditNoteSelectTriggerObject />,
		},
		{
			title: 'Find Data',
			content: () => <CreditNoteTestTrigger showNext={true} />,
		},
		{
			title: 'Find Child Salesforce Object',
			content: () => <FindCreditNoteLineItem />,
		},
		{
			title: 'Find Data',
			content: () => <CreditNoteFindData showNext={true} />,
		},
		{
			title: 'Find Credit Note Parent Record',
			content: () => <CreditNoteCustomFindWithTrigger />,
		},
		{
			title: 'Customize Record',
			content: () => <SalesCreditMapping />,
		},
	]
}

const XERO_CREATE_QUOTE = {
	steps: [
		...XERO_COMMON,
		{
			title: 'Customize Record',
			content: () => <QuoteForm />,
		},
		{
			title: 'Send Data',
			content: () => <SendData delegateToUri='/api/xero/delegate' />,
		},
	]
}

const XERO_UPDATE_QUOTE_RECORD = {
	steps: [
		...XERO_COMMON,
	],

};

const SFDC_UPDATE_QUOTE = {
	steps: [
		...SFDC_COMMON,
		{
			title: 'Customize Record',
			content: () => <CustomFindQuoteWithTrigger />,
		},
		{
			title: 'Quote Mapping',
			content: () => <QuoteUpdateForm />,
		},
	],
}
const QUICKBOOKS_UPDATE_RECORD = {
	steps: [
		...QUICKBOOKS_COMMON,
	],
}
const SFDC_UPDATE_CONTACT_RECORD = {
	steps: [
		...SFDC_COMMON,
		{
			title: 'Customize Record',
			content: () => <SalesObjDataFindForQuickbooks />,
		},
		{
			title: 'Contact Mapping',
			content: () => <ContactUpdateForm />,
		},
	],
};
const SFDC_UPDATE_PRODUCT_RECORD = {
	steps: [
		...SFDC_COMMON,
		{
			title: 'Customize Record',
			content: () => <SalesObjDataFindForQuickbooks />,
		},
		{
			title: 'Product Mapping',
			content: () => <ProductUpdateForm />,
		},
	],
};
const SFDC_UPDATE_INVOICE_RECORD = {
	steps: [
		...SFDC_COMMON,
		{
			title: 'Customize Record',
			content: () => <SalesObjDataFindForQuickbooks />,
		},
		{
			title: 'Invoice Mapping',
			content: () => <QuickbooksInvoiceUpdateForm />,
		},
	],
};
////pdf
const SFDC_TRIGGER_XERO_PDF = {
	steps: [
		...SFDC_COMMON,
		{
			title: 'Customize Record',
			content: () => <SelectTriggerObject />,
		},
		{
			title: 'Find Data',
			content: () => <TestTrigger showNext={true} />,
		},
		{
			title: 'Connect Webhook',
			content: () => <AddWebhook />
		}
	],
}

const XERO_CREATE_INVOICE_PDF = {
	steps: [
		...XERO_COMMON,

		{
			title: 'Customize Record',
			content: () => <InvoicePdfForm showerror={false} />,
		},
		{
			title: 'Send Data',
			content: () => <SendData delegateToUri='/api/xero/delegate' />,
		},
	]
}
/////pdf

const QUICKBOOKS_CREATE_OR_UPDATE_CUSTOMER = {
	steps: [
		...QUICKBOOKS_COMMON,
		{
			title: 'Customize Record',
			content: () => <CustomerForm />,
		},
		{
			title: 'Send Data',
			content: () => <SendDataToQuickbooks delegateToUri='/api/quickbooks/delegate' />,
		}
	],
}

const QUICKBOOKS_CREATE_OR_UPDATE_ITEM = {
	steps: [
		...QUICKBOOKS_COMMON,
		{
			title: 'Customize Record',
			content: () => <QuickbooksItemForm />,
		},
		{
			title: 'Send Data',
			content: () => <SendDataToQuickbooks delegateToUri='/api/quickbooks/delegate' />,
		}
	],
}

const QUICKBOOKS_CREATE_INVOICE = {
	steps: [
		...QUICKBOOKS_COMMON,
		{
			title: 'Customize Record',
			content: () => <QuickbooksInvoiceForm />,
		},
		{
			title: 'Send Data',
			content: () => <SendDataToQuickbooks delegateToUri='/api/quickbooks/delegate' />,
		}
	],
}
const QUICKBOOKS_NEW_PAYMENT_RECORD = {
	steps: [
		...QUICKBOOKS_COMMON
	],
}
const SFDC_SYNC_QUICKBOOK_PAYMENT = {
	steps: [
		...SFDC_COMMON,

		{
			title: 'Find Salesforce Object',
			content: () => <SelectTriggerObject />,
		},
		{
			title: 'Find Data',
			content: () => <TestTrigger showNext={true} />,
		},
		{
			title: 'Find Parent Record',
			content: () => <PaymentCustomFindWithTriggerForQuickbooks />,
		},
		{
			title: 'Payment Mapping',
			content: () => <QuickbooksPaymentForm />,
		},
	]
}

export {
	SFDC_NEW_RECORD,
	SFDC_FIND_RECORD,
	XERO_UPDATE_INVOICE_RECORD,
	SFDC_UPDATE_RECORD,
	XERO_CREATE_SALES_INVOICE,
	XERO_CREATE_OR_UPDATE_CONTACT,
	XERO_CREATE_OR_UPDATE_ITEMS,
	XERO_CREATE_CREDIT_NOTE,
	XERO_CREATE_OR_UPDATE_PURCHASE_ORDER,

	XERO_TRIGGER_INVOICE_RECORD,
	XERO_NEW_MODIFY_ITEM,
	XERO_NEW_MODIFY_INVOICE,
	SALES_FORCE_CREATE_NEW_SALES_INVOICE,
	SALES_FORCE_CREATE_NEW_PRODUCT_RECORD,
	XERO_NEW_PAYMENT_RECORD,
	SFDC_CREATE_OR_UPDATE_PAYMENT,
	//for xero pdf sync to sf
	SFDC_TRIGGER_XERO_PDF,
	XERO_CREATE_INVOICE_PDF,
	// for xero to salesforce credit note
	NEW_XERO_CREDIT_NOTE,
	SFDC_CREATE_UPDATE_CREDIT_NOTE,
	// for creating quote
	XERO_CREATE_QUOTE,
	// for xero to salesforce updating quote 
	XERO_UPDATE_QUOTE_RECORD,
	SFDC_UPDATE_QUOTE,
	// for sf to quickbooks create or update customer
	QUICKBOOKS_CREATE_OR_UPDATE_CUSTOMER,
	// for sf to quickbooks create or update item
	QUICKBOOKS_CREATE_OR_UPDATE_ITEM,
	// for sf to quickbooks create invoice
	QUICKBOOKS_CREATE_INVOICE,
	// for quickbook to sf payment sync
	QUICKBOOKS_NEW_PAYMENT_RECORD,
	SFDC_SYNC_QUICKBOOK_PAYMENT,
	//for qucikbook to sf customer/contact update, item/product, invoice
	QUICKBOOKS_UPDATE_RECORD,
	SFDC_UPDATE_CONTACT_RECORD,
	SFDC_UPDATE_PRODUCT_RECORD,
	SFDC_UPDATE_INVOICE_RECORD,
};
