import React from 'react';
import { useEffect } from 'react';
import { ENVIRONMENT } from '../../Http/API';

const XeroStoreCallBack = () => {
    useEffect(()=>{
      handleXeroLoing();
    },[])
    const handleXeroLoing = () => {
		const callback = ENVIRONMENT.XERO_SIGN_CALLBACK_WITH_ORIGIN;
		const clientId = ENVIRONMENT.XERO_CLIENT_ID;
		window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${callback}&scope=openid profile email offline_access accounting.settings&state=123`
	}
    return (
        <div>
            
        </div>
    );
};

export default XeroStoreCallBack;