const toPascal = (camel) => {
	return camel.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
		return str.toUpperCase();
	});
};

const padLeadingZeros = (num) => {
	let s = `${num}`;
	let size = 4;
	while (s.length < size) s = `0${s}`;
	return s;
};

const requiredRule = { required: true, message: 'This field is required' };

const floatRule = {
	asyncValidator: (rule, value) => {
		return new Promise((resolve, reject) => {
			let regex = new RegExp('^(-?)(0|([1-9][0-9]*))(\\.[0-9]+)?$');
			if (!regex.test(value)) {
				reject('Must contain numbers only'); // reject with error message
			} else {
				resolve();
			}
		});
	},
};

const intRule = {
	asyncValidator: (rule, value) => {
		return new Promise((resolve, reject) => {
			let regex = new RegExp('^[0-9]+$');
			if (!regex.test(value)) {
				reject('Must contain whole numbers only'); // reject with error message
			} else {
				resolve();
			}
		});
	},
};

const arrApps = [
	{ name: 'Salesforce', code: 'SFDC' },
	{ name: 'Xero', code: 'XERO' },
];

const arrEvents = [
	{ name: 'New Record', query: 'SAMPLE QUERY', code: 'NRC' },
	{ name: 'Create Sales Invoice', query: 'SAMPLE QUERY', code: 'CSI' },
];

const unflatten = (data) => {
	'use strict';
	if (Object(data) !== data || Array.isArray(data)) return data;
	var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
		resultholder = {};
	for (var p in data) {
		var cur = resultholder,
			prop = '',
			m;
		while ((m = regex.exec(p))) {
			cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
			prop = m[2] || m[1];
		}
		cur[prop] = data[p];
	}
	return resultholder[''] || resultholder;
};

const flatten = (data) => {
	var result = {};

	function recurse(cur, prop) {
		if (Object(cur) !== cur) {
			result[prop] = cur;
		} else if (
			Array.isArray(cur) &&
			cur.length > 0 &&
			typeof cur[0] !== 'object'
		) {
			result[prop] = cur.join();
		} else if (Array.isArray(cur)) {
			for (var i = 0, l = cur.length; i < l; i++)
				recurse(cur[i], `${prop}[${i}]`);
			if (l == 0) result[prop] = [];
		} else {
			var isEmpty = true;
			for (var p in cur) {
				isEmpty = false;
				recurse(cur[p], prop ? `${prop}.${p}` : p);
			}
			if (isEmpty && prop) result[prop] = {};
		}
	}
	recurse(data, '');
	return result;
};

const copyTextToClipboard = async (text) => {
	if ('clipboard' in navigator) {
	  return await navigator.clipboard.writeText(text);
	} else {
	  return document.execCommand('copy', true, text);
	}
};

// const flatten = (data) => {
//   var result = {};

//   function recurse(cur, prop) {
//     if (Object(cur) !== cur) {
//       result[prop] = cur;
//     } else if (Array.isArray(cur)) {
//       for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], `${prop}[${i}]`);
//       if (l == 0) result[prop] = [];
//     } else {
//       var isEmpty = true;
//       for (var p in cur) {
//         isEmpty = false;
//         recurse(cur[p], prop ? `${prop}.${p}` : p);
//       }
//       if (isEmpty && prop) result[prop] = {};
//     }
//   }
//   recurse(data, '');
//   return result;
// };


function traverseAndFlatten(currentNode, target, flattenedKey) {
	for (var key in currentNode) {
		if (currentNode.hasOwnProperty(key)) {
			var newKey;
			if (flattenedKey === undefined) {
				newKey = key;
			} else {
				newKey = flattenedKey + '.' + key;
			}

			var value = currentNode[key];
			if (typeof value === "object") {
				traverseAndFlatten(value, target, newKey);
			} else {
				target[newKey] = value;
			}
		}
	}
}

function flatten2(obj) {
	var flattenedObject = {};
	traverseAndFlatten(obj, flattenedObject);
	return flattenedObject;
}

export {
	toPascal,
	padLeadingZeros,
	requiredRule,
	intRule,
	floatRule,
	arrApps,
	arrEvents,
	flatten,
	flatten2,
	unflatten,
	copyTextToClipboard
};
