import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Form, Select, message } from 'antd';
import './style.scss';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { isObject, keys as keycreate, sample } from 'lodash';
import { PrimaryButton } from '../../Commons';
import { requiredRule, flatten } from '../../Commons/utils';
import { fetchXeroAccounts, fetchXeroBrandingTheme, fetchXeroCurrencies, fetchXeroItems, fetchXeroOrgs, fetchXeroTaxRates } from '../../Http/xero';

const PurchaseOrderForm = (props) => {
  const { sampleData, sampleData2, setActionData, mappings, setMappings, setOrganisation, form } = useContext(WorkflowContext);
  const { id, next } = useContext(StepContext);

  const [keys, setKeys] = useState([]);
  const [keys2, setKeys2] = useState([]);
  const [accountOpt, setAccountOpt] = useState([]);
  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [brandingThemeOpt, setBrandingThemeOpt] = useState([]);
  const [itemCodeOpt, setItemCodeOpt] = useState([]);
  const [taxRatesOpt, setTaxRatesOpt] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [returnMap, setReturnMap] = useState(false);

  const sentToContactOpt = [
    { label: 'True', value: true },
    { label: 'False', value: false },
  ];

  const lineAmountTypesOpt = [
    { label: 'EXCLUSIVE', value: 'Exclusive' },
    { label: 'INCLUSIVE', value: 'Inclusive' },
    { label: 'NOTAX', value: 'NoTax' },
  ];

  const statusOpt = [
    { label: 'DRAFT', value: 'DRAFT' },
    { label: 'SUBMITTED', value: 'SUBMITTED' },
    { label: 'AUTHORISED', value: 'AUTHORISED' },
    { label: 'BILLED', value: 'BILLED' },
    { label: 'DELETED', value: 'DELETED' },
  ];

  useEffect(() => {
    if (sampleData) {
      setKeys(keycreate(sampleData));
    }
    if (sampleData2) {
      setKeys2(keycreate(sampleData2));
    }
    // console.log({mappings, sampleData, sampleData2});
    let tmp = {};
    if (sampleData && sampleData2) {

      mappings?.forEach((v) =>
        (v.type === 'TRIG')
          ?
          (
            tmp[v.field] = [`${v.valueStepOrder}.${v.value}`],
            tmp[v.label] = (v.valueStepOrder === 1) ? `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}` : `${v.valueStepOrder}.${v.value}: ${sampleData2[v.value]}`
          )
          :
          (v.type === 'ACT')
            ?
            (

              tmp[v.value] = [`${v.valueStepOrder}.${v.field}`],
              tmp[v.label] = (v.valueStepOrder === 1) ? `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}` : `${v.valueStepOrder}.${v.value}: ${sampleData2[v.value]}`
            )
            :
            (tmp[v.field] = `${v.value}`)
      );
    } else {
      if (!props.showerror){
        return
      }else{
        message.error('Please proceed step by step from the beginning', 3)
      }
    }
    if (tmp) {
      form.setFieldsValue(tmp);
    }
  }, [sampleData, sampleData2]);

  useEffect(() => {
    fetchAccountOpt();
    fetchCurrencyOpt();
    fetchBrandingThemeOpt();
    fetchItemCodeOpt();
    fetchTaxRatesOpt();
    fetchOrganisations();
  }, []);

  const fetchAccountOpt = async () => setAccountOpt(await (await fetchXeroAccounts()).data);
  const fetchCurrencyOpt = async () => setCurrencyOpt(await (await fetchXeroCurrencies()).data);
  const fetchBrandingThemeOpt = async () => setBrandingThemeOpt(await (await fetchXeroBrandingTheme()).data);
  const fetchItemCodeOpt = async () => setItemCodeOpt(await (await fetchXeroItems()).data);
  const fetchTaxRatesOpt = async () => setTaxRatesOpt(await (await fetchXeroTaxRates()).data);
  const fetchOrganisations = async () => setOrganisations(await (await fetchXeroOrgs()).data);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((rawFormData) => {
        let ACTION_DATA = {}; //Data to be send as a product of the trigger
        let MAPPINGS = [];

        let flatFormData = flatten({ ...rawFormData, Type: 'ACCREC' }); // add invoice type then flatten



        keycreate(flatFormData)
          .filter((k) => flatFormData[k] && !Array.isArray(flatFormData[k]))
          .map((key) => {
            let val = flatFormData[key].split('.');
            let kname = val.length > 1 ? val[1] : val[0];
            let snum = val.length > 1 ? val[0] : undefined;

            let kval = snum && parseInt(snum) === 1 ? sampleData[kname]
              : snum && parseInt(snum) === 2 ? sampleData2[kname]
                : kname;

            ACTION_DATA = { ...ACTION_DATA, [key]: kval };
            MAPPINGS = snum ?
              (key === 'PurchaseOrderID')
                ?
                [...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum }]
                :
                [...MAPPINGS, { type: 'TRIG', field: key, value: kname, stepId: id, valueStepOrder: snum }]
              :
              (key === 'PurchaseOrderID')
                ?
                [...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum }]
                :
                [...MAPPINGS, { type: 'CON', field: key, value: kname, stepId: id }];
            return key
          });
        setActionData(ACTION_DATA);
        setMappings(MAPPINGS);
        next();
      })
      .catch((e) => console.log(e));
  };

  const RoundedSelect = (props) => {
    const { label, placeholder, name, required, specialOptions, specialOptionsOnly, hint, onSelect } = props;

    return (
      <Col span={22}>
        <div className='label'>
          {label} <span className={required ? 'fr' : 'hidden'}>(required)</span>
        </div>
        <Form.Item name={name} required={required} rules={required ? [requiredRule] : null}>
          <Select className='rounded' mode={specialOptions ? null : 'tags'}
            placeholder={placeholder} allowClear onSelect={onSelect}>

            {specialOptions && specialOptions.map((v) => <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>)}

            {!specialOptionsOnly &&
              keys.map(
                (v, k) =>
                  !isObject(sampleData[v]) && (
                    <Select.Option value={`1.${v}`} key={`1.${k}`}>
                      <div className='opt-div'>
                        <span className='key'>{`1.${v}:  `}</span>
                        {`${sampleData[v]}`}
                      </div>
                    </Select.Option>
                  )
              )}

            {!specialOptionsOnly &&
              keys2.map(
                (v, k) =>
                  !isObject(sampleData2[v]) && (
                    <Select.Option value={`2.${v}`} key={`2.${k}`}>
                      <div className='opt-div'>
                        <span className='key'>{`2.${v}:  `}</span>
                        {`${sampleData2[v]}`}
                      </div>
                    </Select.Option>
                  )
              )}
          </Select>
        </Form.Item>
        {hint && <div className='hint'>{hint}</div>}
      </Col>
    );
  };

  const onChange = (event) => {
    console.log({ event, form: form.getFieldsValue() });
  };

  return (
    <Row className='padded xinvform'>
      <div className='title'>Customize Purchase Order</div>
      <Col span={24}>
        <Row hidden={returnMap} justify='center'>
          <RoundedSelect
            label='Organisation'
            name='Organisation'
            required={true}
            placeholder='Choose value'
            specialOptions={organisations}
            specialOptionsOnly={true}
            onSelect={(v) => {
              setOrganisation(v);
            }}
          />
        </Row>

        <Form layout='vertical' form={form} scrollToFirstError={true} onFinish={handleSubmit} onValuesChange={onChange}>
          <Row hidden={returnMap} justify='center'>
            <RoundedSelect label='Contact' name='Contact.ContactID' required={true} placeholder='Type or insert..' />
            <RoundedSelect label='Purchase Order Number' hint="Unique alpha numeric code identifying purchase order" name='PurchaseOrderNumber' placeholder='Type or insert..' />
            <RoundedSelect label='Status' hint='Defaults to Draft if not specified.' name='Status' placeholder='Choose value..' specialOptions={statusOpt} />
            <RoundedSelect label='Delivery Address' name='DeliveryAddress' hint='Delivery address is used to deliver purchase items' placeholder='Type or insert..' />
            <RoundedSelect label='Delivery Date' name='DeliveryDate' placeholder='Type or insert..' />
            <RoundedSelect label='Delivery Instructions' name='DeliveryInstructions' placeholder='Type or insert..' />
            <RoundedSelect label='Expected Arrival Date' name='ExpectedArrivalDate' placeholder='Type or insert..' />
            <RoundedSelect label='Telephone' name='Telephone' hint="The phone number for the person accepting the delivery" placeholder='Type or insert..' />
            <RoundedSelect label='Order Date' hint='Defaults to creation date if not specified.' name='Date' placeholder='Type or insert..' />
            <RoundedSelect label='Currency' hint='Default currency for organization will be used if not specified.' name='CurrencyCode' placeholder='Choose value..' specialOptions={currencyOpt} />
            <RoundedSelect label='Branding Theme' hint='The branding theme to use for this invoice.' name='BrandingThemeId' placeholder='Choose value..' specialOptions={brandingThemeOpt} />

            <RoundedSelect label='Reference' name='Reference' hint='An additional reference number' placeholder='Type or insert..' />
            <RoundedSelect
              label='Sent to Contact'
              hint="Mark purchase order as 'sent' (note: does not actually send an email). This can be set only on purchase orders that have been approved or billed"
              name='SentToContact'
              placeholder='Type or insert..'
              specialOptions={sentToContactOpt}
            />
            <RoundedSelect
              label='Line Items Type'
              hint="Line amounts are exclusive of tax by default if you don't specify this element."
              name='LineAmountTypes'
              placeholder='Choose value..'
              specialOptions={lineAmountTypesOpt}
            />
          </Row>

          <fieldset hidden={returnMap} className='fieldset' style={{ border: '1px solid #e2e2e2' }}>
            <legend>Line Items</legend>
            <Row justify='center'>
              <RoundedSelect label='Item Code' hint='You must provide either an Item Code or both a Description and a Unit Price.' name='LineItems[0].ItemCode' placeholder='Choose value..' specialOptions={itemCodeOpt} />
              <RoundedSelect label='Description' hint='If no Item Code is provided, this field is required.' name={'LineItems[0].Description'} placeholder='Type or insert..' />
              <RoundedSelect label='Quantity' name='LineItems[0].Quantity' required={true} placeholder='Type or insert..' />
              <RoundedSelect label='Unit Price' hint='If no Item Code is provided, this field is required.' name='LineItems[0].UnitAmount' required={true} placeholder='Type or insert..' />
              <RoundedSelect label='Discount%' name='LineItems[0].DiscountRate' placeholder='Type or insert..' />
              <RoundedSelect label='Account' name='LineItems[0].AccountCode' placeholder='Choose value..' specialOptions={accountOpt} />
              <RoundedSelect
                label='Tax Rate'
                hint='Only specify if you want to override the default tax type for the account above.'
                name={['LineItems[0].TaxtType']}
                placeholder='Choose value..'
                specialOptions={taxRatesOpt}
              />

              {/* <RoundedSelect label='Tracking' name='tracking' required={false} placeholder='Type or insert..' /> */}
            </Row>
          </fieldset>

          <PrimaryButton hidden={returnMap} className='btn-long' size='large' htmlType='button' onClick={() => setReturnMap(true)}>
            Continue
          </PrimaryButton>

          <Row hidden={!returnMap} justify='center'>

            <RoundedSelect label='PurchaseOrderID' name='PurchaseOrderID' required={true} placeholder='Type or insert..' />
            <PrimaryButton hidden={!returnMap} className='btn-long' size='large' htmlType='submit'>
              Continue
            </PrimaryButton>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default PurchaseOrderForm;
