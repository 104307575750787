import React, { useEffect, useCallback, useState } from 'react';
import { getUserProfile, updateUserProfile } from '../../Http/users';
import { api } from '../../Http';
import { Button, Form, Input, Col, Row, message, Select } from 'antd';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import countriesJson from'../../data/countries.json'
const cookie = new Cookies();


const Profile = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [changeLoading, setchangeLoading] = useState(false);
    const [resp, setResp] = useState(undefined);
    const history = useHistory();
    const [countries, setCountries]= useState([]);

    const fetchData = useCallback(async () => {
        const response = await getUserProfile();
        if (response) {
            setData(response.data)
            setLoading(false);
        }
    }, []);
    



    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(()=>{
        let tmp = [];
        countriesJson?.map(name=>{
            tmp.push({value:name.name?.common, label:name.name?.common})
        })
        setCountries(tmp);

    },[])


    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
        cpassword: {

        }
    };

    const onFinish = async (values) => {
        console.log({values})
        setLoading(true);
        const response = await updateUserProfile(values.user);
        console.log(response)
        if (response.code === 0) {
            message.success(response.message);
            // setData(response.data);
            setLoading(false);
            const prevEmail = localStorage.getItem("email");
            if(prevEmail !== values?.user?.email){
                cookie.remove('Authorization', { path: '/' });
                localStorage.removeItem("Authorization");
                localStorage.removeItem("token");
                localStorage.removeItem("email");
                history.replace('/login');
            }
        }else {
            message.error(response.message);
        }
    };


    const handleChangePassword = async () => {
        setchangeLoading(true);
        api
            .get('/account/change-password/url', { withCredentials: false, params: { email: data.email } })
            .then((resp) => {
                setchangeLoading(false);
                if (resp.data.code === 0) {
                    setResp({ msg: resp.data.message, success: true });
                } else {
                    setResp({ msg: resp.data.message, success: false });
                }
            })
            .catch(() => setResp({ msg: 'Network Error', success: false }));
    }

console.log({countries})
    return (
        <Col span={10} offset={1}>
            {data?.firstName ?
                <div className='wrapper'>
                    <h2>User Profile</h2>
                    <Form layout="vertical" name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                        <Form.Item name={['user', 'firstName']} label="First Name" rules={[{ required: true }]} initialValue={data?.firstName}>
                            <Input placeholder='first name' />
                        </Form.Item>
                        <Form.Item name={['user', 'lastName']} label="Last Name" rules={[{ required: true }]} initialValue={data?.lastName}>
                            <Input placeholder='last name' />
                        </Form.Item>
                        <Form.Item name={['user', 'userName']} label="Username" initialValue={data?.userName}>
                            <Input placeholder='username' readOnly={true} />
                        </Form.Item>
                        <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email' }]} initialValue={data?.email}>
                            <Input placeholder='email' />
                        </Form.Item>
                        {/* <Form.Item name={['user', 'country']} label="Country" rules={[{ required: true }]} initialValue={data?.country}>
                            <Input placeholder='Country' />
                        </Form.Item> */}
                        <Form.Item name={['user', 'country']} label="Country" rules={[{ required: true }]}>
										<Select
                                            defaultValue={data?.country}
											size={'middle'}
											showSearch
											placeholder="Select Users Country" 
											style={{textAlign:'left'}}
											optionFilterProp="children" 
											// className='txt-field'
											filterOption={(input, option) => (option?.label ?? '').includes(input)}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
											}
											options={countries}
										/>
										
									</Form.Item>
                        {/* <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item> */}
                        <Form.Item>
                            <Row>
                                <Col>
                                    <Button loading={loading || changeLoading} type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Col>
                                <Col offset={1}>
                                    <Button disabled={changeLoading} type='secondary' onClick={() => handleChangePassword()}>
                                        Change Password
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                    {resp && <Row className={resp.success ? 'success-message' : 'error-message'}>{resp.msg}</Row>}
                </div> : null}
        </Col>
    );
};


export default Profile;