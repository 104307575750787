import React, { useState, useEffect } from 'react';
import { Table, Button, Col, Modal } from 'antd';
import { api } from '../../Http';
import xeroforceConnectionButton from '../../images/xero-connect-blue-button.svg';
import xeroLogo from '../../images/xero.png';
import { fetchConnections, fetchUser, removeConnectionById } from '../../Http/connections';
import { useDispatch, useSelector } from 'react-redux';
import { addConnections, getAllConnections, removeConnection } from '../../redux/slices/connections';

const XeroConnection = () => {

	const dispatch = useDispatch();
	const appData = useSelector(getAllConnections);
	const [loading, setLoading] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [deleteModalConfirmLoading, setDeleteModalConfirmLoading] = useState(false);
	const [deleteId, setDeleteId] = useState(0);

	const columns = [
		{
			title: 'App Name',
			dataIndex: 'app',
			key: 'acc_app',
			render: text => <a>{text === 'XERO' && <img width='32px' src={xeroLogo} alt="Xero..." /> }</a>,
		},
		{
			title: 'Name',
			dataIndex: 'instanceName',
			render: text => <span>{text}</span>,
		},
		{
			title: 'status',
			dataIndex: 'status',
			key: 'status',
			render: text => <span>{text === '01' ? 'Active' : 'Deactivated'}</span>
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, datum) => (
				<>
					{
						datum.refreshToken === null 
						? 
						<Button style={{padding:'0px'}} size="large" onClick={()=>addConnection(datum.app)}>
							{
								datum.app === 'XERO'
								&&
								// <img style={{width:'100%', height: '100%'}} src="https://developer.xero.com/static/images/documentation/ConnectToXero2019/connect-blue.svg" alt="Xero..." />
								<img style={{width:'100%', height: '100%'}} src={xeroforceConnectionButton} alt="Xero..." />
								
							}
						</Button>
						:
						<Button style={{padding:0}} size="large" onClick={()=> deleteAppAccount(datum.id)}>
							{
								datum.app === 'XERO'
								&&
								<img style={{width:'100%', height: '100%'}} src="https://developer.xero.com/static/images/documentation/ConnectToXero2019/disconnect-white.svg" alt="Xero..." />
								
							}
						</Button>
					}

				</>

			),
		},
	];

	useEffect(()=>{
		getConnections();
	},[]);

	const getConnections = async () => {
		const user = await fetchUser();
		console.log({user});

		const connectionsRes = await fetchConnections();
		if(connectionsRes && connectionsRes.data) {
			connectionsRes.data.map(datum => {
				datum.key = datum.id;
			});
			dispatch(addConnections(connectionsRes.data));
		};
	};

	const disconnectItem = async (id) => {
		const connectionRes = await removeConnectionById(id);
		setDeleteModalOpen(false);
		setDeleteModalConfirmLoading(false);
		if(connectionRes && connectionRes.data) {
				dispatch(removeConnection(id));
				setLoading(false);
				setDeleteId(0);
				console.log('disconnected');
		} else {
			console.log('not disconnected');
		}

	};

	const showPopconfirm = () => {
		setDeleteModalOpen(true);
		setLoading(true);
	};

	const handleOk = () => {
		const id = deleteId;
		setLoading(false);
		setDeleteModalConfirmLoading(true);
		disconnectItem(id);
	};

	const handleCancel = () => {
		console.log('Clicked cancel button');
		setDeleteModalOpen(false);
		setLoading(false);
	};

	const deleteAppAccount = (id) => {
		showPopconfirm();
		setDeleteId(id);
		setLoading(true);
	};

	const addConnection = (app) => {
		app === 'XERO' && openXeroConnection();
	};

	const openXeroConnection = () => {
		api
		.get('/api/xero')
		.then((resp) => {
			console.log({resp});
			if (resp.data.code === 0) {
				// TODO: check for new window allow
				console.log({xeroConnection: resp.data})
				const popWindow = window.open(
					resp.data.data,
					'newwindow',
					'width=800,height=600'
				);
				const syncWindow = setInterval(() => {
					if(popWindow.closed) {  
						getConnections();
						clearInterval(syncWindow); 
					}  
				}, 500);
			}
		})
		.catch((e) => console.log(e));

	};

	return (
		<Col span={24} 
		>
			<Modal title="Confirmation For Deleting" okType='danger' confirmLoading={deleteModalConfirmLoading} visible={deleteModalOpen} okText="Confirm Disconnect" onOk={handleOk} onCancel={handleCancel}>
				<p>Are you sure to disconnect this connection ?</p>
			</Modal>
			<Table 
				loading={loading} 
				columns={columns} 
				dataSource={appData.filter(v=>v.app==="XERO")} 
			/>
		</Col>
	);
};
export default XeroConnection;
