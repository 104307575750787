/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Form, Select } from 'antd';
import './style.scss';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { PrimaryButton } from '../../Commons';
import { requiredRule, flatten } from '../../Commons/utils';
import { useDispatch, useSelector } from 'react-redux';
import { addMappings, getMappings } from '../../redux/slices/mapping';
import { fetchXeroOrgs } from '../../Http/xero';
import { OrganizationXero } from '../../Http/models/xero/Organization.model';
import { keys as keycreate, isObject } from 'lodash';


const ContactForm = () => {
	const {
		sampleData,
		sampleData2,
		setActionData,
		mappings,
		setMappings,
		setOrganisation,
		form
	} = useContext(WorkflowContext);
	const { id, next } = useContext(StepContext);
	const [keys, setKeys] = useState([]);
	const [organisations, setOrganisations] = useState(OrganizationXero.List());
	const [returnMap, setReturnMap] = useState(false);

	const addressType = [
		{ label: 'Postal Address', value: 'POBOX' },
		{ label: 'Physical Address', value: 'STREET' },
	];

	const dispatch = useDispatch();
	const getAllMappings = useSelector(getMappings);

	useEffect(() => {
		setKeys(keycreate(sampleData));
		console.log({ mappings, sampleData, sampleData2, getAllMappings });
		let tmp = {};
		mappings?.forEach((v) =>
			(v.type === 'TRIG')
				?
				(
					tmp[v.field] = [`${v.valueStepOrder}.${v.value}`],
					tmp[v.label] = `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}`
				)
				:
				(v.type === 'ACT')
					?
					(

						tmp[v.value] = [`${v.valueStepOrder}.${v.field}`],
						tmp[v.label] = `${v.valueStepOrder}.${v.field}: ${sampleData[v.value]}`
					)
					:
					(tmp[v.field] = `${v.value}`)
		);
		if (tmp) {
			form.setFieldsValue(tmp);
		}
	}, []);

	useEffect(() => {
		//fetch organisations
		getXeroOrgs();
	}, []);

	const getXeroOrgs = async () => {
		const orgsRes = await fetchXeroOrgs();
		setOrganisations(orgsRes.data);
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((rawFormData) => {
				let ACTION_DATA = {}; //Data to be send as a product of the trigger
				let MAPPINGS = [];

				let flatFormData = flatten({ ...rawFormData });
				console.log({ flatFormData });

				keycreate(flatFormData)
					.filter((k) => flatFormData[k] && !Array.isArray(flatFormData[k]))
					.map((key) => {
						// console.log({key});
						let val = flatFormData[key].split('.');
						// console.log({val});
						let kname = val.length > 1 ? val[1] : val[0];
						// console.log({kname});
						let snum = val.length > 1 ? val[0] : undefined;
						// console.log({snum});
						let kval =
							snum && parseInt(snum) === 1
								? sampleData[kname]
								: snum && parseInt(snum) === 2
									? sampleData2[kname]
									: kname;
						// console.log({kval});
						ACTION_DATA = { ...ACTION_DATA, [key]: kval };
						// console.log({ACTION_DATA});
						MAPPINGS =
							snum
								?
								key === 'ContactID'
									?
									[
										...MAPPINGS,
										{ type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum },
									]
									:
									[
										...MAPPINGS,
										{
											type: 'TRIG',
											field: key,
											value: kname,
											stepId: id,
											valueStepOrder: snum,
										},
									]
								:
								key === 'ContactID'
									?
									[
										...MAPPINGS,
										{ type: 'ACT', field: kname, value: key, stepId: id },
									]
									:
									[
										...MAPPINGS,
										{ type: 'CON', field: key, value: kname, stepId: id },
									]
							;
						// console.log({MAPPINGS});
					});

				setActionData(ACTION_DATA);
				// console.log({ACTION_DATA});
				setMappings(MAPPINGS);
				// console.log({MAPPINGS});
				dispatch(addMappings(MAPPINGS));
				next();
			})
			.catch((e) => console.log(e));
	};

	const RoundedSelect = (props) => {
		const {
			label,
			placeholder,
			name,
			required,
			specialOptions,
			specialOptionsOnly,
			hint,
			onSelect,
		} = props;

		return (
			<Col span={22}>
				<div className='label'>
					{label} <span className={required ? 'fr' : 'hidden'}>(required)</span>
				</div>
				<Form.Item
					name={name}
					required={required}
					rules={required ? [requiredRule] : null}>
					<Select
						className='rounded'
						mode={specialOptions ? null : 'tags'}
						placeholder={placeholder}
						allowClear
						onSelect={onSelect}>
						{specialOptions &&
							specialOptions.map((v) => (
								<Select.Option key={v.value} value={v.value}>
									<span className="naim">{v.label}</span>
								</Select.Option>
							))}

						{!specialOptionsOnly &&
							keys.map(
								(v, k) =>
									!isObject(sampleData[v]) && (
										<Select.Option value={`1.${v}`} key={`1.${k}`}>
											<div className='opt-div'>
												<span className='key'>{`1.${v}:  `}</span>
												{`${sampleData[v]}`}
											</div>
										</Select.Option>
									)
							)}
					</Select>
				</Form.Item>
				{hint && <div className='hint'>{hint}</div>}
			</Col>
		);
	};

	return (
		<Row className='padded xinvform'>
			<div className='title'> {returnMap ? 'Return Mapping' : 'Customize Contact'} </div>
			<Col span={24}>

				<Form
					layout='vertical'
					form={form}
					scrollToFirstError={true}
					onFinish={handleSubmit}
					onValuesChange={() => {
						if (form.getFieldsValue().organisation) {
							setOrganisation(form.getFieldsValue().organisation)
						}
					}}
					initialValues={{
						'Phones[0].PhoneType': 'DEFAULT',
						'Phones[1].PhoneType': 'FAX',
						'Phones[2].PhoneType': 'MOBILE',
						'Phones[3].PhoneType': 'DDI',
					}}>
					<Row hidden={returnMap} justify='center'>

						<RoundedSelect
							label='Contact Name'
							name='Name'
							required={true}
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Account Number'
							name='BankAccountDetails'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Primary Person - First Name'
							name='FirstName'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Primary Person - Last Name'
							name='LastName'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Primary Person - Email'
							name='EmailAddress'
							placeholder='Type or insert..'
						/>

						<Form.Item name='Phones[0].PhoneType' className='hidden' />
						<RoundedSelect
							label='Phone - Country Code'
							name='Phones[0].PhoneCountryCode'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Phone - Area Code'
							name='Phones[0].PhoneAreaCode'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Phone Number'
							name='Phones[0].PhoneNumber'
							placeholder='Type or insert..'
						/>

						<Form.Item name='Phones[1].PhoneType' className='hidden' />
						<RoundedSelect
							label='Fax - Country Code'
							name='Phones[1].PhoneCountryCode'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Fax - Area Code'
							name='Phones[1].PhoneAreaCode'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Fax - Number'
							name='Phones[1].PhoneNumber'
							placeholder='Type or insert..'
						/>

						<Form.Item name='Phones[2].PhoneType' className='hidden' />
						<RoundedSelect
							label='Mobile - Country Code'
							name='Phones[2].PhoneCountryCode'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Mobile - Area Code'
							name='Phones[2].PhoneAreaCode'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Mobile - Number'
							name='Phones[2].PhoneNumber'
							placeholder='Type or insert..'
						/>

						<Form.Item name='Phones[3].PhoneType' className='hidden' />
						<RoundedSelect
							label='Direct Dial - Country Code'
							name='Phones[3].PhoneCountryCode'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Direct Dial - Area Code'
							name='Phones[3].PhoneAreaCode'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Direct Dial - Number'
							name='Phones[3].PhoneNumber'
							placeholder='Type or insert..'
						/>

						<RoundedSelect
							label='Address Type Of'
							name='Addresses[0].AddressType'
							placeholder='Postal Address'
							specialOptions={addressType}
						/>
						<RoundedSelect
							label='Address - Attention'
							name='Addresses[0].AttentionTo'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Address - Line 1'
							name='Addresses[0].AddressLine1'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Address - Line 2'
							name='Addresses[0].AddressLine2'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Address - City/Town'
							name='Addresses[0].City'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Address - State/Region'
							name='Addresses[0].Region'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Address - Postal/Zip Code'
							name='Addresses[0].PostalCode'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Address - Country'
							name='Addresses[0].Country'
							placeholder='Type or insert..'
						/>
						<RoundedSelect
							label='Tax Number'
							name='TaxNumber'
							placeholder='Type or insert..'
							hint='Also known as the ABN (Australia), GST Number (New Zealand), VAT Number (UK) or Tax ID Number (US and global) depending on which regionalized version of Xero you use.'
						/>

						<PrimaryButton onClick={() => setReturnMap(true)} className='btn-long' size='large' htmlType='button'>
							Continue
						</PrimaryButton>
					</Row>
					<Row hidden={!returnMap} justify='center'>
						<RoundedSelect
							label='ContactID'
							name='ContactID'
							placeholder='Type or insert..'
						/>
						<PrimaryButton className='btn-long' size='large' htmlType='submit'>
							Continue
						</PrimaryButton>
					</Row>

				</Form>
			</Col>
		</Row>
	);
};

export default ContactForm;
