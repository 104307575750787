import {  CopyOutlined } from "@ant-design/icons";
import { Button, Row, Col } from "antd"
import { useEffect, useState } from "react"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import StepContainer from "../../Components/StepContainer";
import { createSalesforceWebhookUrl } from "../../Http/webhook/salesforceWebhook";
import { getLatestWorkflow, updateWebhookId } from "../../redux/slices/workflow/latestWorkflow";


export const AddWebhook = () => {
        const [visible, setVisible] = useState(true);
        const [buttonLoading, setButtonLoading] = useState(false);
        const latestWorkflow = useSelector(getLatestWorkflow);
        const dispatch = useDispatch();

        useEffect(()=>{
                // document.querySelector('.ant-layout-content')?.scrollIntoView({ behavior: 'smooth' });
        },[])

        const enableWebhookHandler = async () =>{
                setButtonLoading(true);
                const workflowId = latestWorkflow.workflowId;
                const webhookUrlRes = await createSalesforceWebhookUrl(workflowId);
                dispatch(updateWebhookId({webhookUrl: webhookUrlRes.data}));
                setVisible(false);
                setButtonLoading(false);
        }
        const copyToClipboard = (url)=>{
                navigator.clipboard.writeText(url);
                toast.success("The Webhook Url has Copied!");
        }

        return (
                <StepContainer title='Connect to Salesforce outbound messages'>
                        <ToastContainer />
                        <Row justify='center' hidden={latestWorkflow.webhookUrl}>
                                <Button loading={buttonLoading} type="primary" size='large' onClick={enableWebhookHandler}>
                                        Enable Webhook
                                </Button>
                        </Row>

                        <Row justify='center' hidden={!latestWorkflow.webhookUrl} className='code-bash'>
                                <Col span={23}>
                                        <div>{latestWorkflow.webhookUrl}</div>
                                </Col>
                                <Col span={1}>
                                        <Button style={{borderRadius: '10px'}} icon={<CopyOutlined />} onClick={()=>copyToClipboard(latestWorkflow.webhookUrl)}></Button>
                                </Col>
                        </Row>
                </StepContainer>
        )
}
