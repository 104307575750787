import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Layout, Row, Col, Button, Card, Spin } from 'antd';
import styled from 'styled-components';
import loginBG from './images/bg.jpg';
import logo from './images/logo_v_png.png';
import { requiredRule } from '../../Commons/utils';
import { SecondaryButton, PrimaryButton } from '../../Commons';
import './style.scss';
import { api } from '../../Http';
import { Content } from 'antd/lib/layout/layout';

const ForgotPass = () => {
  const { Background } = StyledComponents;
  const history = useHistory();
  const [resp,setResp] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const onFinish = (v) => {
    setLoading(true);
    api
      .get('/account/change-password/url', { withCredentials: false , params:{email: v.email}})
      .then((resp) => {
        setLoading(false);
        if(resp.data.code === 0){
            setResp({msg:resp.data.message, success:true});
        }else{
            setResp({msg:resp.data.message, success:false});
        }
      })
      .catch(() => setResp({msg:'Network Error', success:false}));
  };

  return (
    <Layout>
      <Content>
        <Background justify='center' align='middle'>
          <Col xs={22} sm={16} md={12} lg={10} xl={8} xxl={7}>
            <Card className='forget-pass-card' bordered={false} title="Request for change password.">
              <Spin spinning={loading} tip='Requesting..'>
              <a href="https://nettverk.io/" target="_blank" rel='noreferrer'><img className='logo' src={logo} alt='logo'/></a>

                {resp && <div className={resp.success ? 'success-message' : 'error-message'}>{resp.msg}</div>}
                
                <Form onFinish={(e) => onFinish(e)} style={{ textAlign: 'center', marginTop: '2rem' }}>
                  <Form.Item name='email' rules={[requiredRule]}>
                    <Input className='txt-field' size='large' placeholder='Email address' />
                  </Form.Item>

                  <Form.Item>
                    <SecondaryButton className='nv-btn' htmlType='submit' type='primary' size='large' block>
                        Send
                    </SecondaryButton>
                  </Form.Item>


                  <Form.Item>
                    <PrimaryButton
                      className='nv-btn'
                      type='primary'
                      size='large'
                      block
                      onClick={() => {
                        history.push('');
                      }}
                    >
                      Login 
                    </PrimaryButton>
                  </Form.Item>
                </Form>
              </Spin>
            </Card>
          </Col>
        </Background>
      </Content>
    </Layout>
  );
};

export default ForgotPass;

const StyledComponents = {
  Background: styled(Row)`
    height: 100vh;
    background-image: url(${loginBG});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `,
  SubmitButton: styled(Button)``,
};
