import React, { useEffect, useCallback, useContext, useState } from 'react';
import { Row, Col, Select, message, Button } from 'antd';
import { SecondaryButton } from '../../Commons';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { delegateSaleForce } from '../../Http/salesforce/delegate';
import { findXeroItemObject } from '../../Http/xero';
import { flatten2 } from '../../Commons/utils';

const FindItemData = ({ showNext }) => {
    const { SObject, sampleData, setSampleData } = useContext(WorkflowContext);
    const [recentItems, setRecentItems] = useState(undefined);
    const { next } = useContext(StepContext);
    const [visible, setVisible] = useState(false);
    const [sampleData2, setSampleData2] = useState()

    const params = (uri) => ({ uri: uri });


    const fetchitemobjectdata = useCallback(async () => {
        const response = await findXeroItemObject()
        if (response && response.code === 0) {
            setRecentItems(response.data.Items)
            // setSampleData( flatten2(response?.data?.Items[0]))
            setSampleData2(response?.data?.Items[0])
        }
    }, [])

    useEffect(() => {
        fetchitemobjectdata()
    }, [fetchitemobjectdata])

    const handleSelect = (k) => {
        const delegateSalesForceRes = recentItems.find((value, index) => index === k)
        // if (delegateSalesForceRes) setSampleData(flatten2(delegateSalesForceRes));
        if (delegateSalesForceRes) setSampleData2(delegateSalesForceRes);
    };

    return (
        <StepContainer title='Find Data'>
            <Row justify='center' className={visible ? null : 'hidden'}>
                {recentItems && (
                    <Col span={22}>
                        <div>{`( ${recentItems.length} Data Found )`}</div>
                        <Select
                            className='fill-width'
                            defaultValue={0}
                            onSelect={handleSelect}>
                            {recentItems.map((v, k) => (
                                <Select.Option value={k} key={k}>
                                    {v.Name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                )}
                <Col span={22} className='mt-2 json-view'>
                    <pre>{JSON.stringify(sampleData2, null, 2)}</pre>
                </Col>
            </Row>

            <Row justify='center' style={{ marginTop: '10px' }} className={visible ? null : 'hidden'}>
                <SecondaryButton hidden={!showNext} size='large' onClick={() => next()}>
                    Continue
                </SecondaryButton>
            </Row>

            <Row justify='center' className={visible ? 'hidden' : null}>
                <SecondaryButton size='large' onClick={() => setVisible(true)}>
                    Test Trigger
                </SecondaryButton>
            </Row>
        </StepContainer>
    );
};

export default FindItemData;
