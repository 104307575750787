import { createSlice } from '@reduxjs/toolkit';
import { WorkflowData } from '../../../Http/models/WorkflowData.model';

const latestWorkflow = createSlice({
        name: 'latestWorkflow',
        initialState: {
                latestWorkflow: new WorkflowData(),
        },
        reducers: {
                addLatestWorkflow: (state, { payload }) => {
                        state.latestWorkflow = new WorkflowData(payload);
                },
                updateWebhookId: (state, {payload}) => {
                        const {webhookUrl} = payload;
                        const newState = {...state.latestWorkflow, webhookUrl: webhookUrl};
                        state.latestWorkflow = new WorkflowData(newState);
                },
                updateStepAuthorization: (state, { payload }) =>{
                        if(state.latestWorkflow.step1.appCode === payload.appCode){
                                state.latestWorkflow.step1.accountAuthorized = payload.accountAuthorized;
                        }else if(state.latestWorkflow.step2.appCode === payload.appCode){
                                state.latestWorkflow.step2.accountAuthorized = payload.accountAuthorized;  
                        }
                }
        }
});

export const getLatestWorkflow = (state) => {
        return new WorkflowData(state.latestWorkflow.latestWorkflow);
};
export const { addLatestWorkflow, updateWebhookId } = latestWorkflow.actions;
export default latestWorkflow.reducer;