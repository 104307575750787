import { createSlice } from '@reduxjs/toolkit';

const subscriptions = createSlice({
    name: 'subscriptions',
    initialState: {
        subscriptions: {}
    },
    reducers: {
        addSubscriptions: (state, { payload }) => {
            state.subscriptions = payload;
        },
        
    }
});

export const getSubscription = (state) => {
    return (state.subscriptions.subscriptions);
};

export const { addSubscriptions } = subscriptions.actions;

export default subscriptions.reducer;