import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Row, Col, Select, Form } from 'antd';
import { PrimaryButton } from '../../Commons';
import { isSuccess } from '../../Http';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { keys as keycreate, isObject } from 'lodash';
import './style.scss';
import { requiredRule } from '../../Commons/utils';
import { fetchSalesForceObjects } from '../../Http/salesforce/triggerObject';
import { delegateSaleForce } from '../../Http/salesforce/delegate';

const PaymentCustomFindWithTriggerForQuickbooks = () => {
    const { appName, next } = useContext(StepContext);
    const { sampleData, setSampleData2, setAdditionalQuery } = useContext(
        WorkflowContext
    );
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [fields, setFields] = useState([]);
    const [keys, setKeys] = useState([]);
    const [form] = Form.useForm();
    const optionsnew = [{key: "Invoice Id", value:"Line[0].LinkedTxn[0].TxnId"}]

    //old code
    const getSalesForceObjects = useCallback(async () => {
        const salesforceObjectsRes = await fetchSalesForceObjects();
        console.log({ salesforceObjectsRes })
        if (isSuccess(salesforceObjectsRes)) {
            setOptions(salesforceObjectsRes.data);
            setLoading(false);
            return;
        }
        // } else getSalesForceObjects();
    }, []);

    useEffect(() => {
        getSalesForceObjects()
    }, [getSalesForceObjects])
    //old code

    useEffect(() => {
        setKeys(keycreate(sampleData));
    }, [sampleData]);

    const handleSelect = (k) => {
        const params = { uri: options[k].urls.describe };
        delegateSaleForce(params)
            .then((resp) => {
                if (isSuccess(resp)) {
                    let fields = resp.data.fields.map((v) => v.name);
                    setFields(fields);
                }
            })
            .catch((delegateError) => console.log({ delegateError }));
    };


    const handleSubmit = () => {
        form
            .validateFields()
            .then((val) => {

                console.log(val);
                console.log(options[val.sobjects].name)
                const newQuery = `${options[val.sobjects].name},${val.field},${val.searchValue}`
                setAdditionalQuery(newQuery);
                next();
            })
            .catch((e) => console.log(e));
    };

    const CustomSelect = (props) => {
        const { title, name, onSelect } = props;
        return (
            <Row>
                <div>
                    {title}
                    <span className='required'>(required)</span>
                </div>
                <Col span={24}>
                    <Form.Item name={name} rules={[requiredRule]}>
                        <Select
                            className='fill-width rounded'
                            loading={loading}
                            onSelect={onSelect}
                            placeholder='Select'
                            showSearch
                            optionFilterProp='children'>
                            {props.children}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    return (
        <StepContainer title='Find Parent Record'>
            <Form form={form} layout='horizontal'>
                <CustomSelect
                    name='sobjects'
                    title={`Parent ${appName} Object: `}
                    onSelect={handleSelect}>
                    {options.map((v, k) => (
                        <Select.Option value={k} key={k}>
                            {v.label}
                        </Select.Option>
                    ))}
                </CustomSelect>

                <CustomSelect name='field' title='Parent Field to search by :'>
                    {fields.map((v, k) => (
                        <Select.Option value={v} key={k}>
                            {v}
                        </Select.Option>
                    ))}
                </CustomSelect>
                <CustomSelect name='searchValue' title='Quickbooks field '>
                    {optionsnew.map((v, k) => (
                        <Select.Option value={v.value} key={k}>
                            {v.key}
                        </Select.Option>
                    ))}
                </CustomSelect>
            </Form>

            <Row justify='center' className='mt-2'>
                <PrimaryButton className='btn-long' size='large' onClick={handleSubmit}>
                    Continue
                </PrimaryButton>
            </Row>
        </StepContainer>
    );
};

export default PaymentCustomFindWithTriggerForQuickbooks;
