export class Step {
        #default = {
                appCode: null,
                appName: null,
                shortDesc: null,
                longDesc: null,
                workflowId: null,
                accountId: null,
                eventId: null,
                eventCode: null,
                object: null,
                query: null,
                httpMethod: null,
                id: null,
                accountAuthorized: null,
                order: null,
        }

        constructor(step=null) {
                if(!step) step = this.#default;
                this.appCode = step?.appCode;
                this.appName = step?.appName;
                this.shortDesc = step?.shortDesc;
                this.longDesc = step?.longDesc;
                this.workflowId = step?.workflowId;
                this.accountId = step?.accountId;
                this.eventId = step?.eventId;
                this.eventCode = step?.eventCode;
                this.object = step?.object;
                this.query = step?.query;
                this.httpMethod = step?.httpMethod;
                this.id = step?.id;
                this.accountAuthorized = step?.accountAuthorized;
                this.order = step?.order;
        }

        
        static List(initialValue = []) {
                const arr = [new this()];
                arr.shift();
                if(initialValue && initialValue.length > 0) initialValue.map(v => arr.push(v));
                return arr;
        }
}
