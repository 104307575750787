import { useEffect, useState } from 'react'
import { fetchTransactions } from '../Http/transaction/Transaction';
import { Transaction } from '../Http/models/Transaction.model';

function useTransaction() {
  const [loadingTransaction, setLoadingTransaction] = useState(false);
	const [transactions, setTransactions] = useState(new Transaction());

	useEffect(()=>{
		getTransactions();
	},[])
	
	const getTransactions = async () => {
		setLoadingTransaction(true);
		const transactionRes = await fetchTransactions();
		if(transactionRes.code === 0){
			setTransactions(transactionRes.data);
		}
		setLoadingTransaction(false);
	}
  return [transactions, loadingTransaction]
}

export default useTransaction;