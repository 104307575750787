import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { Form, Input, Layout, Row, Col, Button, Card, Spin, Checkbox, Select } from 'antd';
import styled from 'styled-components';
import loginBG from './images/bg.jpg';
import logo from './images/logo_v_png.png';
import { requiredRule } from '../../Commons/utils';
import { SecondaryButton, DisabledButton } from '../../Commons';
import countriesJson from'../../data/countries.json'
import './style.scss';
import { api } from '../../Http';
import { Content } from 'antd/lib/layout/layout';

const Signup = () => {
	const { Background } = StyledComponents;
	const emailInputRef = useRef();
	const history = useHistory();
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [terms, setTerms] = useState(false);
	const [countries, setCountries]= useState([]);
	useEffect(()=>{
        let tmp = [];
        countriesJson?.map(name=>{
            tmp.push({value:name.name?.common, label:name.name?.common})
        })
        setCountries(tmp);

    },[])
	useEffect(()=>{
		const token = localStorage.getItem("token");
		const decoded = token && jwt_decode(token);
		if(decoded?.userType !== 'ADM'){
			history.replace('/login');
		}
	},[])

	const onFinish = (v) => {
		setLoading(true);
		api
		.post('/account/signup', v, { withCredentials: false })
		.then((resp) => {
			if (resp.data.code === 0) {
				history.replace("/system/users")
			} else {
				setError(resp.data.message);
				setLoading(false);
			}
		})
		.catch(() => setError('Network Error'));
		
	};

	return (
		<Layout>
			<Content>
				<Background justify='center' align='middle'>
					<Col xs={22} sm={16} md={12} lg={10} xl={8} xxl={7}>
						<Card className='signup-card' bordered={false}>
							{/* <Spin spinning={loading} tip='Creating Account..'> */}
							<Spin spinning={loading} tip='Resistering User..'>
								<a href="https://nettverk.io/" target="_blank" rel='noreferrer'><img className='logo' src={logo} alt='logo'/></a>
								<div className={error ? 'error-message' : 'login-message'}>
									{/* {error ? error : 'Create An Account'} */}
									{error ? error : 'Register A User'}
								</div>

								<Form
									onFinish={(e) => onFinish(e)}
									style={{ textAlign: 'center', marginTop: '2rem' }}>
									<Form.Item name='firstName' rules={[requiredRule]}>
										<Input
											className='txt-field'
											size='large'
											placeholder='First Name'
										/>
									</Form.Item>
									<Form.Item name='lastName' rules={[requiredRule]}>
										<Input
											className='txt-field'
											size='large'
											placeholder='Last Name'
										/>
									</Form.Item>
									<Form.Item name='userName' rules={[requiredRule]}>
										<Input
											className='txt-field'
											ref={emailInputRef}
											size='large'
											placeholder='Username'
										/>
									</Form.Item>
									<Form.Item name='country'  rules={[requiredRule]}>
										<Select
											size={'large'}
											showSearch
											placeholder="Select Users Country" 
											style={{textAlign:'left'}}
											optionFilterProp="children" 
											className='txt-field'
											filterOption={(input, option) => (option?.label ?? '').includes(input)}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
											}
											options={countries}
										/>
										
									</Form.Item>
									<Form.Item name='email' rules={[requiredRule]}>
										<Input
											className='txt-field'
											ref={emailInputRef}
											size='large'
											placeholder='Email'
										/>
									</Form.Item>
									<Form.Item name='password' rules={[requiredRule]}>
										<Input.Password
											className='txt-field'
											size='large'
											placeholder='Password'
										/>
									</Form.Item>
									<Form.Item
										name="confirm"
										dependencies={['password']}
										hasFeedback
										rules={[
											{
												required: true,
												message: 'Please confirm your password!',
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue('password') === value) {
														return Promise.resolve();
													}
													return Promise.reject(new Error('The two passwords that you entered do not match!'));
												},
											}),
										]}
									>
										<Input.Password className='txt-field' placeholder='Confirm Password' size='large' />
									</Form.Item>
									
									<div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
										<Checkbox onChange={() => setTerms(!terms)}>I agree with the <a href="https://nettverk.io/msa/">Terms and Conditions</a></Checkbox>
									</div>


									<Form.Item>
										{terms ?
											<SecondaryButton

												className='nv-btn'
												htmlType='submit'
												type='primary'
												size='large'
												block>
												Register
											</SecondaryButton> :
											<DisabledButton className='nv-btn'
												disabled
												htmlType='submit'
												type='primary'
												size='large'
												block >
												Register
											</DisabledButton>}

									</Form.Item>

									{/* <span className='div-txt'>Already have an account ?</span>

									<Form.Item>
										<PrimaryButton
											className='nv-btn'
											htmlType='submit'
											type='primary'
											size='large'
											block
											onClick={() => {
												history.push('/');
											}}>
											Sign in
										</PrimaryButton>
									</Form.Item> */}
								</Form>
							</Spin>
						</Card>
					</Col>
				</Background>
			</Content>
		</Layout>
	);
};

export default Signup;

const StyledComponents = {
	Background: styled(Row)`
		height: 100vh;
		background-image: url(${loginBG});
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	`,
	SubmitButton: styled(Button)``,
};
