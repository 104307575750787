
export class TransactionsByWorkflow{

        #default = {
                workflowId: 0,
                workflowUser: 0,
                workflowName: '',
                workflowStatus: '',
                workflowFrequency: 0,
                workflowCreatedBy: 0,
                workflowDateCreated: '',
                scheduleId: 0,
                scheduleStartTime: '',
                workflowLastUpdated: '',
                transactions: 0,
        }

        constructor(data){
                if(!data) data = this.#default;
                this.workflowId = data.workflowId;
                this.workflowUser = data.workflowUser;
                this.workflowName = data.workflowName;
                this.workflowStatus = data.workflowStatus;
                this.workflowFrequency = data.workflowFrequency;
                this.workflowCreatedBy = data.workflowCreatedBy;
                this.workflowDateCreated = data.workflowDateCreated;
                this.scheduleId = data.scheduleId;
                this.scheduleStartTime = data.scheduleStartTime;
                this.workflowLastUpdated = data.workflowLastUpdated;
                this.transactions = data.transactions;
        }

        static List(initialValue=[]){
                const arr = [new this()];
                arr.shift();
                if(initialValue && initialValue.length > 0) initialValue.map(v => arr.push(v));
                return arr;
        }
}


export class TransactionsByDate{

        #default = {
                workflowId: 0,
                workflowUser: 0,
                workflowName: '',
                workflowStatus: '',
                workflowFrequency: 0,
                workflowCreatedBy: 0,
                workflowDateCreated: '',
                scheduleId: 0,
                scheduleStartTime: '',
                workflowLastUpdated: '',
                transactions: 0,
        }

        constructor(data){
                if(!data) data = this.#default;
                this.workflowId = data.workflowId;
                this.workflowUser = data.workflowUser;
                this.workflowName = data.workflowName;
                this.workflowStatus = data.workflowStatus;
                this.workflowFrequency = data.workflowFrequency;
                this.workflowCreatedBy = data.workflowCreatedBy;
                this.workflowDateCreated = data.workflowDateCreated;
                this.scheduleId = data.scheduleId;
                this.scheduleStartTime = data.scheduleStartTime;
                this.workflowLastUpdated = data.workflowLastUpdated;
                this.transactions = data.transactions;
        }

        static List(initialValue=[]){
                const arr = [new this()];
                arr.shift();
                if(initialValue && initialValue.length > 0) initialValue.map(v => arr.push(v));
                return arr;
        }
}


export class Transaction{
        #default = {
                transactionsByWorkflow: [],
                transactionsByDate: []
        }

        constructor(data){
                if(!data) data = this.#default;
                
                this.transactionsByWorkflow = TransactionsByWorkflow.List(data.transactionsByWorkflow);
                this.transactionsByDate = TransactionsByDate.List(data.transactionsByDate);
        }
}