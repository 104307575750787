import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Select, Form, message } from 'antd';
import { PrimaryButton } from '../../Commons';
import { isSuccess } from '../../Http';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { keys as keycreate, isObject } from 'lodash';
import './style.scss';
import { requiredRule } from '../../Commons/utils';
import { fetchSalesForceObjects } from '../../Http/salesforce/triggerObject';
import { delegateSaleForce } from '../../Http/salesforce/delegate';

const CustomFindQuoteWithTrigger = () => {
	const { appName, next } = useContext(StepContext);
	const { sampleData, setSampleData, setSampleData2, setAdditionalQuery, SObject, setSObject } = useContext(
		WorkflowContext
	);
	const [loading, setLoading] = useState(true);
	const [options, setOptions] = useState([]);
	const [fields, setFields] = useState([]);
	const [keys, setKeys] = useState([]);
	const [form] = Form.useForm();

	const optionsnew = ['QuoteID', 'QuoteNumber', 'Reference']

	/*Load Sobjects from delegate url onLoad */
	useEffect(() => {
		getSalesForceObjects();
	}, []);


	useEffect(() => {
		getAllDelegateSalesForce();
	}, [SObject]);


	const params = (uri) => ({ uri: uri });

	const getAllDelegateSalesForce = async () => {
		// TODO: null validation
		const delegateSalesForceRes = await delegateSaleForce(params(SObject?.urls?.sobject));
		const data = delegateSalesForceRes?.data?.recentItems;
		if (data && !data[0]) return message.error('no data found');
		const delegateSalesForceAttrRes = await delegateSaleForce(params(data && data[0].attributes.url));
		if (delegateSalesForceAttrRes) setSampleData(delegateSalesForceAttrRes.data);
	};

	const getSalesForceObjects = async () => {
		const salesforceObjectsRes = await fetchSalesForceObjects();
		if (isSuccess(salesforceObjectsRes)) {
			setOptions(salesforceObjectsRes.data);
			setLoading(false);
			return;
		} else getSalesForceObjects();
	};

	useEffect(() => {
		setKeys(keycreate(sampleData));
	}, [sampleData]);

	const handleSelect = (k) => {
		let item = options.findIndex((v, i) => k === i);
		setSObject(options[item]);
		const params = { uri: options[k].urls.describe };
		delegateSaleForce(params)
			.then((resp) => {
				if (isSuccess(resp)) {
					let fields = resp.data.fields.map((v) => v.name);
					setFields(fields);
				}
			})
			.catch((delegateError) => console.log({ delegateError }));
	};


	const handleSubmit = () => {
		form
			.validateFields()
			.then((val) => {
				console.log({ val })
				setSampleData2(val);
				// let strFields = fields.join(',');
				// let tableName = options[val.sobjects].name;
				// let fieldName = val.field;
				// let fieldVal = val.searchValue;
				// console.log({fieldVal},{tableName},{fieldName})

				// let query = `/services/data/v49.0/query/?q=SELECT+${strFields}+FROM+${tableName}+WHERE+${fieldName}+=+`;
				// const params = { uri: `${query}'${sampleData[fieldVal]}'` };
				// delegateSaleForce(params)
				// 	.then((resp) => {
				// 		console.log(resp);
				// 		if (!isSuccess(resp)) return;

				// 		let data = resp.data;
				// 		if (data && data.totalSize > 0) {
				// 			
				// 			setAdditionalQuery(`${query}$${fieldVal}`);
				// 		}


				// 	})
				// 	.catch((delegateError) => console.log({delegateError}));
				next();
			})
			.catch((e) => console.log(e));
	};

	const CustomSelect = (props) => {
		const { title, name, onSelect } = props;
		return (
			<Row>
				<div>
					{title}
					<span className='required'>(required)</span>
				</div>
				<Col span={24}>
					<Form.Item name={name} rules={[requiredRule]}>
						<Select
							className='fill-width rounded'
							loading={loading}
							onSelect={onSelect}
							placeholder='Select'
							showSearch
							optionFilterProp='children'>
							{props.children}
						</Select>
					</Form.Item>
				</Col>
			</Row>
		);
	};

	return (
		<StepContainer title='Customize Record to Find'>
			<Form form={form} layout='horizontal'>
				<CustomSelect
					name='sobjects'
					title={`${appName} Object: `}
					onSelect={handleSelect}>
					{options.map((v, k) => (
						<Select.Option value={k} key={k}>
							{v.label}
						</Select.Option>
					))}
				</CustomSelect>

				<CustomSelect name='field' title='Select from xero field :'>
					{optionsnew.map((v, k) => (
						<Select.Option value={v} key={k}>
							{v}
						</Select.Option>
					))}
				</CustomSelect>

				<CustomSelect name='searchValue' title='Search value :'>
					{keys.map(
						(v, k) =>
							!isObject(sampleData[v]) && (
								<Select.Option value={v} key={k}>
									<div className='opt-div'>
										<span className='key'>{`1.${v}:  `}</span>
										{`${sampleData[v]}`}
									</div>
								</Select.Option>
							)
					)}
				</CustomSelect>
			</Form>

			<Row justify='center' className='mt-2'>
				<PrimaryButton className='btn-long' size='large' onClick={handleSubmit}>
					Continue
				</PrimaryButton>
			</Row>
		</StepContainer>
	);
};

export default CustomFindQuoteWithTrigger;
