export class Connection {
        #connections = {
                id: null,
                key: null,
                app: null,
                accessToken: null,
                refreshToken: null,
                instanceURL: null,
                instanceName: null,
                instanceConnectionId: null,
                status: null,
        }
        constructor(data) {
                if(!data) data = this.#connections;
                this.id = data.id;
                this.key = data.id;
                this.app = data.app;
                this.accessToken = data.accessToken;
                this.refreshToken = data.refreshToken;
                this.instanceURL = data.instanceURL;
                this.instanceName = data.instanceName;
                this.instanceConnectionId = data.instanceConnectionId;
                this.status = data.status;
        }

        static List(initialValue = []) {
                const arr = [new this()];
                arr.shift();
                if(initialValue && initialValue.length > 0) initialValue.map(v => arr.push(v));
                return arr;
        }
}

