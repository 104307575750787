import { api, FormatResponse } from '../index';
import { OrganizationXero } from '../models/xero/Organization.model';

const ORG_URL = '/api/xero/organisations';
const ACC_URL = '/api/xero/accounts';
const CURRENCY_URL = '/api/xero/currencies';
const BRANDING_THEME_URL = '/api/xero/branding-themes';
const ITEMS_URL = '/api/xero/items';
const TAX_RATES_URL = '/api/xero/tax-rates';
const CONNECTION_URL = '/api/xero/allconnections';
const ITEM_OBJECT_URL = '/api/xero/items-object'
const XERO_CONNECTION_REVOKE_URL = '/api/xero/revokeConnection';

export const fetchXeroOrgs = async () => {
        const xeroOrgsRes = await api.get(`${ORG_URL}`);
        return new OrgListResFormat(xeroOrgsRes);
};

export const fetchXeroAccounts = async () => {
        const xeroOrgsRes = await api.get(`${ACC_URL}`);
        return new FormatResponse(xeroOrgsRes);
};

export const fetchXeroCurrencies = async () => {
        const xeroOrgsRes = await api.get(`${CURRENCY_URL}`);
        return new FormatResponse(xeroOrgsRes);
};

export const fetchXeroBrandingTheme = async () => {
        const xeroOrgsRes = await api.get(`${BRANDING_THEME_URL}`);
        return new FormatResponse(xeroOrgsRes);
};

export const fetchXeroItems = async () => {
        const xeroOrgsRes = await api.get(`${ITEMS_URL}`);
        return new FormatResponse(xeroOrgsRes);
};

export const fetchXeroTaxRates = async () => {
        const xeroOrgsRes = await api.get(`${TAX_RATES_URL}`);
        return new FormatResponse(xeroOrgsRes);
};

export const fetchAllXeroConnections = async () => {
        const xeroOrgsRes = await api.get(`${CONNECTION_URL}`);
        return new FormatResponse(xeroOrgsRes);
};

export const findXeroItemObject = async() => {
        const xeroItemObject = await api.get(`${ITEM_OBJECT_URL}`);
        return new FormatResponse(xeroItemObject)
}
export const removeXeroConnectionByAdmin = async (id) => {
        const connectionRes = await api.delete(`${XERO_CONNECTION_REVOKE_URL}/${id}`);
        return new FormatResponse(connectionRes);
};

class OrgListResFormat extends FormatResponse {
        constructor(response){
                super(response);
                this.data = OrganizationXero.List(this.data);
        }
}