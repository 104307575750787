import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Form, Select, message} from 'antd';
import './style.scss';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { isObject, keys as keycreate} from 'lodash';
import { PrimaryButton } from '../../Commons';
import { requiredRule, flatten } from '../../Commons/utils';

const InvoicePdfForm = (props) => {
  const { sampleData, sampleData2, setActionData, mappings, setMappings, form } = useContext(WorkflowContext);
  const { id, next } = useContext(StepContext);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (sampleData) {
      setKeys(keycreate(sampleData));
    }
    let tmp = {};
    if (sampleData) {

      mappings?.forEach((v) =>
        (v.type === 'TRIG')
          ?
          (
            tmp[v.field] = [`${v.valueStepOrder}.${v.value}`],
            tmp[v.label] = (v.valueStepOrder === 1) ? `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}` : `${v.valueStepOrder}.${v.value}: ${sampleData2[v.value]}`
          )
          :
          (v.type === 'ACT')
            ?
            (

              tmp[v.value] = [`${v.valueStepOrder}.${v.field}`],
              tmp[v.label] = (v.valueStepOrder === 1) ? `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}` : `${v.valueStepOrder}.${v.value}: ${sampleData2[v.value]}`
            )
            :
            (tmp[v.field] = `${v.value}`)
      );
    } else {
      if (!props.showerror){
        return
      }else{
        message.error('Please proceed step by step from the beginning', 3)
      }
    }
    if (tmp) {
      console.log({temFieldValue:tmp})
      form.setFieldsValue(tmp);
    }
  }, [sampleData]);



  const handleSubmit = () => {
    form
      .validateFields()
      .then((rawFormData) => {
        let ACTION_DATA = {}; //Data to be send as a product of the trigger
        let MAPPINGS = [];

        let flatFormData = flatten({ ...rawFormData }); 

        keycreate(flatFormData)
          .filter((k) => flatFormData[k] && !Array.isArray(flatFormData[k]))
          .map((key) => { //key is xero field like Contact.ContactID or LineItems[0].UnitAmount or LineItemID
            let val = flatFormData[key].split('.');// val is salesforce field like ['1', 'Contact_Xero_Id__c']
            let kname = val.length > 1 ? val[1] : val[0];
            let snum = val.length > 1 ? val[0] : undefined;

            let kval = snum && parseInt(snum) === 1 ? sampleData[kname]
              : snum && parseInt(snum) === 2 ? sampleData2[kname]
                : kname;

            ACTION_DATA = { ...ACTION_DATA, [key]: kval };
            MAPPINGS = snum ?
              (key === 'InvoiceID')
                ?
                [...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum }]
                :
                [...MAPPINGS, { type: 'TRIG', field: key, value: kname, stepId: id, valueStepOrder: snum }]
                :
                [...MAPPINGS, { type: 'CON', field: key, value: kname, stepId: id }];
            return key
          });
          setActionData(ACTION_DATA);
          setMappings(MAPPINGS);
          next();
      })
      .catch((e) => console.log(e));
  };

  const RoundedSelect = (props) => {
    const { label, placeholder, name, required, specialOptions, specialOptionsOnly, hint, onSelect } = props;

    return (
      <Col span={22}>
        <div className='label'>
          {label} <span className={required ? 'fr' : 'hidden'}>(required)</span>
        </div>
        <Form.Item name={name} required={required} rules={required ? [requiredRule] : null}>
          <Select className='rounded' mode={specialOptions ? null : 'tags'}
            placeholder={placeholder} allowClear onSelect={onSelect}>

            {specialOptions && specialOptions.map((v) => <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>)}

            {!specialOptionsOnly &&
              keys.map(
                (v, k) =>
                  !isObject(sampleData[v]) && (
                    <Select.Option value={`1.${v}`} key={`1.${k}`}>
                      <div className='opt-div'>
                        <span className='key'>{`1.${v}:  `}</span>
                        {`${sampleData[v]}`}
                      </div>
                    </Select.Option>
                  )
              )}
          </Select>
        </Form.Item>
        {hint && <div className='hint'>{hint}</div>}
      </Col>
    );
  };

  const onChange = (event) => {
    console.log({ event, form: form.getFieldsValue() });
  };
  return (
    <Row className='padded xinvform'>
      <div className='title'>Customize Sales Invoice</div>
      <Col span={24}>
        <Form layout='vertical' form={form} scrollToFirstError={true} onFinish={handleSubmit} onValuesChange={onChange}>
          <Row justify='center'>
            <RoundedSelect label='InvoiceID' name='InvoiceID' required={true} placeholder='Type or insert Xero_Id' />
            <RoundedSelect label='InvoiceNumber' name='InvoiceNumber' required={true} placeholder='Type or insert Invoice_Number' />
          </Row>
          <PrimaryButton className='btn-long' size='large' htmlType='submit' >
            Continue
          </PrimaryButton>
        </Form>
      </Col>
    </Row>
  );
};

export default InvoicePdfForm;
