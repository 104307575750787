import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Spin } from 'antd';
import { PrimaryButton, SecondaryButton } from '../../Commons';
import { api } from '../../Http';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { unflatten } from '../../Commons/utils';
import './style.scss';
import { useHistory } from 'react-router-dom';
const SendData = ({ delegateToUri }) => {

  const { id, next } = useContext(StepContext);
  const { form, actionData, mappings, SObject, workflowId, organisation, additionalQuery } = useContext(WorkflowContext);
  const [message, setMessage] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    console.log({ formsFromSendData: form.getFieldsValue() });
  });

  const handleSubmit = () => {

    if (message) {
      history.push('/system/workflows');
      return;
    }

    let unflat = unflatten(actionData);
    console.log({ unflat })
    setLoading(true);
    api
      .post(`${delegateToUri}/${id}`, { ...unflat, tenantId: organisation })
      .then((resp) => {
        setLoading(false);
        if (resp.data.code === 0) {
          setMessage('Data successfully sent.');
          return api.post('/api/v1/workflows/mappings', { mappings: mappings, sObject: SObject.name, workflowId: workflowId, additionalQuery: additionalQuery });
        } else {
          let errorMsg = `${resp.data.message} : ${resp.data.description}. `;
          if (resp.data.data !== null) {
            resp.data.data.length > 0 && resp.data.data[0].ValidationErrors.forEach((v) => (errorMsg += `${v.Message}`));
          }
          setError(errorMsg);
        }
      })
      .then((resp) => {
        console.log(resp);
        next();
      })
      .catch((e) => console.log(e));
  };


  const handleSkip = () => {
    if (message) {
      history.push('/system/workflows');
      return;
    }
    api.post('/api/v1/workflows/mappings', { mappings: mappings, sObject: SObject.name, workflowId: workflowId, additionalQuery: additionalQuery })
      .then(resp => setMessage(resp.data.message))
      .catch(e => console.log(e));
  };

  return (
    <Row className='padded with-prompt'>
      <Col span={24}>
        <Spin spinning={loading}>
          <div className='wf-title'>Send Data</div>

          <Row className={message || error ? null : 'hidden'} justify='center'>
            <Col span={22}>
              <Card className={error ? 'error' : 'message'}>{error ? error : message}</Card>
            </Col>
          </Row>

          <Row justify='center' className='mt-2'>
            <Col span={22} className='mt-2 json-view'>
              <pre>{JSON.stringify(unflatten(actionData), null, 2)}</pre>
            </Col>
          </Row>

          <Row justify='center'>
            <PrimaryButton className='btn-long' size='large' onClick={handleSubmit}>
              {message ? 'Done' : 'Continue'}
            </PrimaryButton>
          </Row>

          {
            !message && (
              <Row justify='center'>
                <SecondaryButton className='btn-long' size='large' onClick={handleSkip}>
                  Skip Test
                </SecondaryButton>
              </Row>
            )
          }


        </Spin>
      </Col>
    </Row>
  );
};

export default SendData;
