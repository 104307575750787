export class Invoice {
        #default = {
                id: '',
                customerName: '',
                customerEmail: '',
                status: 0,
                amountPaid: 0,
                amountRemaining: 0,
                hostedInvoiceUrl: '',
                invoicePdf: '',
                created: 0,
                dueDate: 0
        }

        constructor(data){
                if(!data) data = this.#default;
                this.id = data.id;
                this.customerName = data.customerName;
                this.customerEmail = data.customerEmail;
                this.status = data.status;
                this.amountPaid = data.amountPaid;
                this.amountRemaining = data.amountRemaining;
                this.hostedInvoiceUrl = data.hostedInvoiceUrl;
                this.invoicePdf = data.invoicePdf;
                this.created = data.created;
                this.dueDate = data.dueDate;
        }

        static List(initialValue = []) {
                const arr = [new this()];
                arr.shift();
                if(initialValue && initialValue.length > 0) initialValue.map(v => arr.push(v));
                return arr;
        }
}