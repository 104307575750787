import { createSlice } from '@reduxjs/toolkit';
import { WorkflowData } from '../../../Http/models/WorkflowData.model';


const workflowSlice = createSlice({
        name: 'workflow',
        initialState: {
                workflows: WorkflowData.List()
        },
        reducers: {
                addWorkflow: (state, {payload}) => {
                        state.workflows = payload;
                },
                updateStatus: (state, {payload}) => {
                        // ! needs to test
                        const {id} = payload;
                        const index = state.workflows.findIndex(workflow => workflow.workflowId === id);
                        if(index !== -1) {
                                if(state.workflows[index].status === '01') state.workflows[index].status = '02';
                                else if (state.workflows[index].status === '02') state.workflows[index].status = '01';
                        }
                },
                deleteWorkflow: (state, {payload}) => {
                        const {id} = payload;
                        state.workflows = state.workflows.filter(workflow => workflow.workflowId !== id);
                }
        }
});

export const getAllWorkflow = (state) => {
        return WorkflowData.List(state.workflow.workflows);
};

export const {addWorkflow, deleteWorkflow} = workflowSlice.actions;

export default workflowSlice.reducer;