export class Mapping {
        constructor(mapping = null){
                this.id = mapping?.id;
                this.field = mapping?.field;
                this.value = mapping?.value;
                this.stepId = mapping?.stepId;
                this.valueStepOrder = mapping?.valueStepOrder;
                this.type = mapping?.type;
                this.status = mapping?.status;
        }

        
        static List(initialValue = []) {
                const arr = [new this()];
                arr.shift();
                if(initialValue && initialValue.length > 0) initialValue.map(v => arr.push(v));
                return arr;
        }
}