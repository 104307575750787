import React from 'react';
import { Row, Select, Button, Divider } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const TableWrapper = styled.div`
	width: 100%;
	padding: 0 5px 50px 5px;
  	margin-bottom: 80px;
	border-radius: 5px;
	box-shadow: 0px 7.5px 15px 0.1px rgba(0, 0, 0, 0.2);
`;

const CustomDropdown = styled(Select)`
	width: 300px !important;
`;

const PrimaryButton = styled(Button)`
	background-color: #093849 !important;
	color: #ffff !important;
`;

const SecondaryButton = styled(Button)`
	background-color: #cf4500 !important;
	color: #ffff !important;
`;
const SuccessButton = styled(Button)`
	background-color: #5cb85c !important;
	color: #ffff !important;
`;

const DangerButton = styled(Button)`
	background-color: #bb2124 !important;
	color: #ffff !important;
`;

const DisabledButton = styled(Button)`
	background-color: #6c757d !important;
	color: #ffff !important;
`;

const Title = styled.div`
	text-align: center;
	font-size: 2rem;
`;

const DashedLine = styled(Divider)`
	border: 1px #2b3990 dashed;
`;

const ArrowDown = () => (
	<Row justify='center'>
		<ArrowDownOutlined style={{ fontSize: '2rem' }} className='down-arrow' />
	</Row>
);

export {
	CustomDropdown,
	TableWrapper,
	PrimaryButton,
	SuccessButton,
	DangerButton,
	DisabledButton,
	Title,
	DashedLine,
	SecondaryButton,
	ArrowDown,
};
