import { Button, Col, Input, Row, Timeline } from 'antd';
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import BackgroundImage from '../../images/backgroundimageofpayment.jpg'
import "./style.scss"
import { api } from '../../Http';
import { useEffect, useState } from 'react';
import { addSubscriptions } from '../../redux/slices/subscriptions';
import { useHistory } from 'react-router-dom';
import { getActiveSubscription } from '../../Http/pricing';
import { useDispatch } from 'react-redux';
import { useCookies, Cookies } from 'react-cookie';
import { StripePaymentCard } from '../../Components/StripePaymentCard';
import { ENVIRONMENT } from '../../Http/API';
const cookie = new Cookies();


export const InitialPayment = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardEmail, setCardEmail] = useState(localStorage.getItem("email"));
    const [cardName, setCardName] = useState('');

    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [cookies] = useCookies(['Authorization']);
    const plan = JSON.parse(localStorage.getItem("plan"));
    const subs = JSON.parse(localStorage.getItem("subscription"));
    const planObj = JSON.parse(localStorage.getItem("subscriptionPlan"));

    useEffect(() => {
        if (!cookies.Authorization) {
            history.replace('/login');
        }
        else if (subs && subs.paymentgatewayref) {
            console.log(subs)
            history.push("/system/dashboard")
        }
    }, []);
    



    const handleLogout = () => {
        cookie.remove('Authorization', { path: '/' });
        history.push('/login');
        dispatch(addSubscriptions({}))
        localStorage.clear()
    };

    const handleSubmit = async () => {
        setLoading(true)
        if (!stripe || !elements) {
            return;
        } else {
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    email: cardEmail,
                }
            })

            if (result.error) {
                console.log(result.error.message)
            }
            else {
                let body = {}
                if (subs?.userSubscriptionId) {
                    body = {
                        paymentMethodId: result?.paymentMethod.id,
                        priceId: plan?.subplanid,
                        cardEmail,
                        cardName
                    }
                } else {
                    body = {
                        paymentMethodId: result?.paymentMethod.id,
                        priceId: "price_1Lbzj3IZhopB8TKz96ZpcxmS",
                        cardEmail,
                        cardName
                    }
                }

                const response = await api.post('/api/v1/stripe/create-subscription', body)
                if (response && response.status) {
                    const activesub = await getActiveSubscription()
                    dispatch(addSubscriptions(activesub.data))
                    localStorage.setItem("subscription", JSON.stringify(activesub.data.subscription))
                    localStorage.setItem("plan", JSON.stringify(activesub.data.plan))
                    setLoading(false)
                    history.push("/system/dashboard")
                }
            }
        }
    }

    // const handleSubmit = async () => {
    //     setLoading(true)
    //     if (!stripe || !elements) {
    //         return;
    //     } else {
    //         const result = await stripe.createPaymentMethod({
    //             type: 'card',
    //             card: elements.getElement(CardElement),
    //             billing_details: {
    //                 email: cardEmail,
    //             }
    //         })

    //         if (result.error) {
    //             console.log(result.error.message)
    //         }
    //         else {
    //             let body = {}
    //             if (subs?.userSubscriptionId) {
    //                 body = {
    //                     paymentMethodId: result?.paymentMethod.id,
    //                     priceId: plan?.subplanid,
    //                     cardEmail,
    //                     cardName
    //                 }
    //             } else {
    //                 body = {
    //                     paymentMethodId: result?.paymentMethod.id,
    //                     priceId: "price_1Lbzj3IZhopB8TKz96ZpcxmS",
    //                     cardEmail,
    //                     cardName
    //                 }
    //             }

    //             const response = await api.post('/api/v1/stripe/create-subscription', body)
    //             if (response && response.status) {
    //                 const activesub = await getActiveSubscription()
    //                 dispatch(addSubscriptions(activesub.data))
    //                 localStorage.setItem("subscription", JSON.stringify(activesub.data.subscription))
    //                 localStorage.setItem("plan", JSON.stringify(activesub.data.plan))
    //                 setLoading(false)
    //                 history.push("/system/dashboard")
    //             }
    //         }
    //     }
    // }

    const handleInputChange = (input) => {
        switch(input.target.name){
            case 'cardEmail':
                setCardEmail(input.target.value)
                break;
            case 'cardName':
                setCardName(input.target.value)
                break;
            default:
                break;
        }
    }

    return (
        <div className='initial__div' >
            <Row>
                <Col md={9} offset={3}>
                    <img src={BackgroundImage} alt="" className='image-fluid' />
                    {subs?.userSubscriptionId ?
                        <div className='main-div'>
                            <h1 className='trial_text'>Custom Plan</h1>
                            <p className='description-text'>Get Unlimited Transaction</p>
                            <Timeline>
                                <Timeline.Item>On Demand Transactions</Timeline.Item>
                                <Timeline.Item>Sync Salesforce Contact to Xero Customer</Timeline.Item>
                                <Timeline.Item>Sync Product to Item</Timeline.Item>
                                <Timeline.Item>Create Invoices from Opportunities or Orders</Timeline.Item>
                            </Timeline>
                        </div>
                        : 
                        planObj?.planName === "Professional"?
                        <div className='main-div'>
                            <h1 className='trial_text'>Premium Plan</h1>
                            {/* <p className='description-text'>Get Unlimited Transaction</p> */}
                            <Timeline>
                                <Timeline.Item>10000 Transactions</Timeline.Item>
                                <Timeline.Item>Sync Salesforce Contact to Xero Customer</Timeline.Item>
                                <Timeline.Item>Sync Product to Item</Timeline.Item>
                                <Timeline.Item>Create Invoices from Opportunities or Orders</Timeline.Item>
                            </Timeline>
                        </div>
                        :
                        <div className='main-div' style={{overflowY:"scroll"}}>
                            <h1 className='trial_text'>Start your free trial</h1>
                            <p className='description-text'>Try our Starter free trial pack for 30 days totally free</p>
                            <Timeline>
                                <Timeline.Item>5000 Transactions</Timeline.Item>
                                <Timeline.Item>Sync Salesforce Contact to Xero Customer</Timeline.Item>
                                <Timeline.Item>Sync Product to Item</Timeline.Item>
                                <Timeline.Item>Create Invoices from Opportunities or Orders</Timeline.Item>
                            </Timeline>
                        </div>}

                </Col>
                <Col md={9} offset={1}>
                    {/* <div className='main-div'>
                        <h1 className='trail_text'>Enter Payment Information</h1>
                        <form autoComplete='off'>
                            <Input onChange={handleInputChange} name="cardEmail" placeholder='Email Addresss' size='large' prefix={<MailOutlined />} defaultValue={cardEmail} />
                            <br/>
                            <br/>
                            <Input onChange={handleInputChange} name="cardName" placeholder='Name On Card' size='large' prefix={<UserOutlined />} />
                            <br/>
                            <br/>
                            <div className="card_details">
                                <CardInput />
                            </div>
                            <div className='button-margin'>
                                <Button onClick={handleSubmit} loading={loading} type="primary">Subscribe</Button>
                                <Button onClick={() => handleLogout()} style={{ marginLeft: "1rem" }} type="default">Logout</Button>
                            </div>
                        </form>
                    </div> */}

    
                    <StripePaymentCard 
                        //priceId={subs?.userSubscriptionId ? plan?.subplanid : ENVIRONMENT.trial_starter}
                        priceId={subs?.userSubscriptionId ? plan?.subplanid: planObj?.planName === "Professional" ? ENVIRONMENT.professional : ENVIRONMENT.trial_starter}
                        showSubscriptionButton={true}
                        showLogOutButton={true}
                        onComplete={(success, result)=> {console.log({success, result})}}
                    />
                </Col>
            </Row>
        </div>
    )
}