export class Log {
        id = '';
        actionId = '';
        remarks = '';
        responseData = '';
        sourceData = '';
        time = '';
        triggerId = '';

        constructor(data = null) {
                if(data) {
                        this.id = data.id;
                        this.actionId = data.actionId;
                        this.remarks = data.remarks;
                        this.responseData = data.responseData;
                        this.sourceData = data.sourceData;
                        this.time = data.time;
                        this.triggerId = data.triggerId;  
                }
        }

        static List(initialValue = []) {
                const arr = [new this()];
                arr.shift();
                if(initialValue && initialValue.length > 0) initialValue.map(v => arr.push(v));
                return arr;
        }
}