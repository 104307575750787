import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Spin, Button } from 'antd';
import { SecondaryButton } from '../../Commons';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
const CreditNoteFindData = ({ showNext }) => {
    const { sampleData2, SObject, setSObject } = useContext(WorkflowContext);
    const { next } = useContext(StepContext);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (sampleData2) {
            SObject.name = SObject.name + "|" + sampleData2?.attributes?.type
            console.log({ SObject })
            setSObject(SObject);
        }
    }, [sampleData2])


    return (
        <StepContainer title='Find Data'>
            <Spin spinning={loading}>
                <Row justify='center'>
                    {visible && sampleData2 ? (
                        <Col span={24} className='mt-2 json-view'>
                            <pre>{JSON.stringify(sampleData2, null, 2)}</pre>
                            {/* <Button hidden={!showNext} onClick={()=>next()}>Continue</Button> */}
                        </Col>

                    ) : visible ? (
                        <Col span={24}>No Data Found</Col>
                    ) : null}
                </Row>
            </Spin>

            <Row justify='center' className={visible ? 'hidden' : null}>
                <SecondaryButton
                    size='large'
                    onClick={() => {
                        setLoading(true);
                        setTimeout(() => {
                            setLoading(false);
                            setVisible(true);
                        }, 2000);
                    }}>
                    Find Data
                </SecondaryButton>

            </Row>
            <Row justify='center' style={{ marginTop: '10px' }} className={visible ? null : 'hidden'}>
                <SecondaryButton hidden={!showNext} size='large' onClick={() => next()}>
                    Continue
                </SecondaryButton>
            </Row>
        </StepContainer>
    );
};

export default CreditNoteFindData;
