import { Table, Button, Result, Modal, Divider, Form, Input, Select, Row, Switch } from 'antd';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TableWrapper } from '../../Commons';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { startCase } from 'lodash';
import './style.scss';
import { copyWorkFlow, deleteWorkFlowByAdmin, fetchWorkflowsByUserId, toggleStatus } from '../../Http/workflow';
import { fetchAllUsersByAdmin } from '../../Http/users';
import { ArrowLeftOutlined, DeleteFilled, WarningFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { addWorkflow } from '../../redux/slices/workflow/workflowList';


      
const WorkFlows = (props) => {

	const [data, setData] = useState([]);
	const [users, setUsers] = useState([]);
	const [refresh, setRefresh] = useState(true);
	const dispatch = useDispatch();
	const [accountStatus, setAccountStatus] = useState("active");
	const [workflowId, setWorflowId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const history = useHistory();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [form] = Form.useForm();
    const userId = props.match.params.id;

	useEffect(() => {
        getAllWorkFlows();
	}, [refresh]);

	useEffect(()=>{
		getAllUsersByAdmin();
	},[])

    const getAllWorkFlows = async()=>{
        const res = await fetchWorkflowsByUserId(userId)
        //console.log({res:res})
        if(res?.data?.code === 0){
			setAccountStatus(res.data.description)
            setData(res.data.data);
            setLoading(false)
            setError(false);
        } else {
            setError(true);
        }
    }

	const getAllUsersByAdmin = async()=>{
		const res = await fetchAllUsersByAdmin();
		if(res.code === 0){
            setUsers(res?.data?.map(user=>{return {label: user.email, value: user.id}}));
            setError(false);
        } else {
            setError(true);
        }
	}

	const extractCols = () => {
		if (!data || data.length === 0) return [];

		let jsonKeys = Object.keys(data[0]);
		let columns = [];

		jsonKeys.forEach((v) => {
			if(v === 'sourceData' || v === 'responseData' || v === 'status') {
				// escape
			} else {
				let cell = {
					title: startCase(v) ,
					dataIndex: v,
					key: v,
					align: 'center',
					ellipsis: true,
				};
				columns.push(cell);
			}
		});
		const action = {
			title: 'Action',
			key: 'operation',
			align:'center',
			width: 300,
			render: (record) => {
				return(
					<div style={{display:"flex",justifyContent:"space-around"}}>
						<Button
						onClick={() => confirmDelete(record?.workflowId)}
						type='link'
						style={{ color: 'red' }}
						icon={
							<DeleteFilled style={{ fontSize: '1.5rem' }} />
							}></Button>
						<Link to={`/system/workflows/${record?.workflowId}/logs`}>
							View Logs
						</Link>
						<Button disabled={accountStatus === 'active' ? false : true} style={{background: '#252B6A', borderColor: '#252B6A'}} type="primary" danger onClick={()=>copy(record)}>Copy</Button>
					</div>
				)
			},
		};
		const activateStatus = {
			title: 'Active',
			key: 'status',
			width: 100,
			align:'center',
			render: (record) => {
				return(
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						<Switch
							id={record.workflowId}
							checked={record.status === '02'}
							onChange={() => handleToggle(record.workflowId)}
						/>
					</div>
				)
			},
		};
		columns.push(activateStatus)
		columns.push(action);

		return columns;
	};

	const extractRows = () => {
		if (!data || data.length === 0) return [];
		let rows = [];
		data.forEach((v, k) => {
			rows.push({ ...v, key: k });
		});
		return rows;
	};

	const copy = (record)=>{
		setWorflowId(record?.workflowId)
		setIsModalOpen(true);
		
	}
	const onFinishFilter = (value)=>{
		//console.log({values:value})
		const newValue = {
			userId: value?.id,
			workflowId
		}
		copyWorkFlow(newValue)
		.then(res=>{
			if(res?.code === 0){
				toast.success('Workflow Coppied Successfully.');
				setIsModalOpen(false);
			}
			if(res?.code === 1){
				toast.error(res.message);
				setIsModalOpen(false);				
				//console.log({copy_res:res})
			}
		})
		.catch(err=>{
			//console.log(err)
		})

	}
	const handleToggle = async (id) => {
		const workflowRes = await toggleStatus(id);
		if(workflowRes.code === 0){
			dispatch(addWorkflow(workflowRes.data));
			setRefresh(!refresh);
		}
		
	};
	const confirmDelete = (id) => {
		Modal.confirm({
			title: 'Delete Workflow',
			content: <div>Are you sure you want to delete this workflow?</div>,
			icon: <WarningFilled />,
			onOk: async () => {
				const res = await deleteWorkFlowByAdmin(id);
				if(res.code === 0){
					setRefresh(!refresh)
				}
			},
		});
	};

	return (
		
		<>
			<ToastContainer />
			<TableWrapper style={{marginBottom:"70px", padding:0, marginTop:0}} className='wrapper'>
				{!error ? (
					<>
						<Modal className="log-modal" footer={false} title={<Divider orientation="center">Copy the workflow for the user</Divider>} width={720} open={isModalOpen} onOk={()=>setIsModalOpen(false)} onCancel={()=>setIsModalOpen(false)}>
							<Form
								form={form}
								name="advanced_search"
								className="ant-advanced-search-form"
								onFinish={onFinishFilter}
								style={{width: '100%'}}
							>
								<Form.Item name="id" >
									<Select
										allowClear
										showSearch
										optionFilterProp="children"
    									// onChange={onChange}
										// onSearch={onSearch}
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										options={users}
										>
									</Select>
								</Form.Item>
								<Button type="primary" htmlType="submit">
									Confirm
								</Button>
								
								
							</Form>
								
						</Modal>
						<Table 
							loading={loading}
							scroll={{ x: 'max-content' }}
							pagination={false}
							dataSource={extractRows()}
							columns={extractCols()}
						>
						</Table>
					</>
				) : (
					<Result
						status='404'
						title='404'
						subTitle='Sorry, the page you visited does not exist.'
						extra={
							<Button
								type='primary'
								onClick={() => history.replace('/system/workflows')}>
								Back Home
							</Button>
						}
					/>
				)}
			</TableWrapper>
			<Fab
			 event='click'
			 icon={<ArrowLeftOutlined/>}
			 mainButtonStyles={{background: '#252B6A'}}
			 onClick={()=>history.replace("/system/users")}
			>
			</Fab>

			
		</>
	);
};

export default WorkFlows;
