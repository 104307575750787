import React from 'react';
import { useSelector } from 'react-redux';
import { ArrowDown } from '../../../Commons';
import { AppMap } from '../../../Commons/AppMapping';
import { getLatestWorkflow } from '../../../redux/slices/workflow/latestWorkflow';
import Process from '../../Process';

/*
    Basic 2 Process Workflow
*/
const BasicWorkflow = () => {
	const latestWorkflow = useSelector(getLatestWorkflow);
	return (
		<>
			<Process
				app={AppMap[latestWorkflow?.step1.eventCode]}
				stepData={latestWorkflow.step1}
				message='When this happens...'
				order={1}
			/>
			<ArrowDown />
			<Process
				app={AppMap[latestWorkflow.step2.eventCode]}
				stepData={latestWorkflow.step2}
				message='then do this'
				order={2}
			/>
		</>
	);
};

export default BasicWorkflow;
