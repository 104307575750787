import React, { useEffect } from 'react';
import { api } from '../../Http';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {  getCallback, disableCallback } from '../../redux/slices/callback';
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';

const CallBackPage = () => {
	const dispatch = useDispatch();
	const getCallbackState = useSelector(getCallback);
	const history = useHistory();
	const [cookie, setCookie] = useCookies(['Authorization']);


	const isFrom = (appName) => {
		console.log(window.location)
		return window.location.pathname === appName;
	};

	const closeWindowOpener = () => {
		// window.opener.onSuccess(true);
		window.close();
	};

	const extractArg = (argName) => {
		return new URLSearchParams(window.location.search).get(argName);
	};

	const CALLBACK_DICT = {
		SFDC: new CallBack(
			'/api/salesforce/token',
			{
				withCredentials: true,
				params: { code: extractArg('code'), env: window.opener ? window.opener.SFDC_ENV : null },
			},
			closeWindowOpener
		),
		XERO: new CallBack(
			'/api/xero/token',
			{
				withCredentials: true,
				params: { code: extractArg('code'), origin: extractArg('origin') },
			},
			closeWindowOpener
		),
		QCKB: new CallBack(
			'/api/quickbooks/token',
			{
				withCredentials: true,
				params: { code: extractArg('code'), realmId: extractArg('realmId') },
			},
			closeWindowOpener
		),
		CONFIRM: new CallBack(
			'/account/confirm',
			{
				withCredentials: false,
				params: { email: extractArg('email') },
			},
			() => history.replace('/login')
		),
		SIGNIN_XERO: new CallBack(
			'/account/sign-in-xero',
			{
				withCredentials: true,
				params: { code: extractArg('code'), origin: extractArg('origin') },
			},
			closeWindowOpener
		),
		SIGNIN_QUICKBOOKS: new CallBack(
			'/account/sign-in-quickbooks',
			{
				withCredentials: true,
				params: { code: extractArg('code'), realmId: extractArg('realmId') },
			},
			closeWindowOpener
		),
	};

	useEffect(() => {
		dispatch(disableCallback());
		console.log("In callback")
		if (!getCallbackState) {
			console.log({getCallbackStateInner: getCallbackState})
			if (isFrom('/salesforce/callback') || isFrom('/xero/callback') || isFrom('/confirm') || isFrom('/quickbooks/callback')){
				console.log("first called element")
				let cb = isFrom('/salesforce/callback')
					? CALLBACK_DICT['SFDC']
					: isFrom('/xero/callback')
					? CALLBACK_DICT['XERO']
					: isFrom('/quickbooks/callback')
					? CALLBACK_DICT['QCKB']
					: CALLBACK_DICT['CONFIRM'];

				console.log({callback: cb});
				console.log(cb.params);
				api.get(cb.url, cb.params).then((resp) => {
					console.log({ callbackData: resp });
					window.XERO_DATA = 'DATA';
					if (resp.data.code === 0) {
						cb.callback();
					}
				});
			}else if(isFrom('/quickbooks-signin/callback')){

				let cb = CALLBACK_DICT['SIGNIN_QUICKBOOKS']
				api.get(cb.url, cb.params).then((resp) => {
					console.log({signincallback: resp})
					window.QUICKBOOKS_DATA = 'DATA';
					console.log({responsedata: resp.data})
					if(resp.data.code === 0){
						const userdata = resp.data.data
						localStorage.setItem("Authorization", userdata.token)
						setCookie('Authorization', userdata.token, { path: '/' });
						window.localStorage.setItem("token", userdata.token)
						window.localStorage.setItem("email", jwt_decode(userdata.token).email)
						if (userdata.subscription === null) {
							history.replace("/payment")
						} else {
							localStorage.setItem("subscription", JSON.stringify(userdata.subscription))
							localStorage.setItem("plan", JSON.stringify(userdata.plan))
							history.replace('/system/dashboard')
						}
					}
				})

			}else if(isFrom('/xero-signin/callback')){
				let cb = CALLBACK_DICT['SIGNIN_XERO']
				api.get(cb.url, cb.params).then((resp) => {
					console.log({signincallback: resp})
					window.XERO_DATA = 'DATA';
					console.log({responsedata: resp.data})
					if(resp.data.code === 0){
						const userdata = resp.data.data
						localStorage.setItem("Authorization", userdata.token)
						setCookie('Authorization', userdata.token, { path: '/' });
						window.localStorage.setItem("token", userdata.token)
						console.log(jwt_decode(userdata.token))
						window.localStorage.setItem("email", jwt_decode(userdata.token).email)
						if (userdata.subscription === null) {
							history.replace("/payment")
						} else {
							localStorage.setItem("subscription", JSON.stringify(userdata.subscription))
							localStorage.setItem("plan", JSON.stringify(userdata.plan))
							history.replace('/system/dashboard')
						}
					}
				})
			}
			
		}
	}, [getCallbackState]);

	return <div className='title'>Redirecting...</div>;
};

class CallBack {
	constructor(url, params, callback) {
		this.url = url;
		this.params = params;
		this.callback = callback;
	}
}

export default CallBackPage;
