import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Form, Select } from 'antd';
import './style.scss';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { keys as keycreate, isObject } from 'lodash';
import { PrimaryButton } from '../../Commons';
import { api } from '../../Http';
import { requiredRule, flatten } from '../../Commons/utils';
const QuickbooksItemForm = () => {
  const { sampleData, sampleData2, setActionData, mappings, setMappings, setOrganisation, form } = useContext(WorkflowContext);
  const { id, next } = useContext(StepContext);
  const [keys, setKeys] = useState([]);
  const [returnMap, setReturnMap] = useState(false);

  const [isInventory, setIsInventory] = useState(false)


  const typeOptions = [
    { label: 'Inventory', value: 'Inventory' },
    { label: 'Service or NonInventory', value: 'Service' },
  ];
  const boolOptions = [
    { label: 'True', value: true }
  ];

  useEffect(() => {
    sampleData !== undefined && setKeys(keycreate(sampleData));
    let tmp = {};

    if(sampleData !== undefined){
      mappings?.forEach((v) =>
        (v?.type === 'TRIG')
          ?
          (
            tmp[v?.field] = [`${v?.valueStepOrder}.${v?.value}`],
            tmp[v?.label] = `${v?.valueStepOrder}.${v?.value}: ${sampleData[v?.value]}`
          )
          :
          (v.type === 'ACT')
            ?
            (
              tmp[v?.value] = [`${v?.valueStepOrder}.${v?.field}`],
              tmp[v?.label] = `${v?.valueStepOrder}.${v?.field}: ${sampleData[v?.value]}`
            )
            :
            (tmp[v?.field] = `${v?.value}`)
      );
      if (tmp) {
        form.setFieldsValue(tmp);
      }

    }
    form.setFieldValue('Type', trackedInventoryValueController());
  }, []);
  
  const trackedInventoryValueController = ()=>{
    let tmp = mappings?.length > 0 && mappings.find(m=>m.field === 'TrackQtyOnHand') ? 'Inventory' : 'Service';
    if(tmp === 'Inventory'){
      setIsInventory(true);
    }
    return tmp;
  }
  
  const handleSubmit = () => {
    form
      .validateFields()
      .then((rawFormData) => {
        let ACTION_DATA = {}; //Data to be send as a product of the trigger
        let MAPPINGS = [];

        let flatFormData = flatten({ ...rawFormData });
        console.log({flatFormData, rawFormData})
        keycreate(flatFormData)
          .filter((k) => flatFormData[k])
          .map((key) => {
            if ((Array.isArray(rawFormData[key]) && rawFormData[key].length === 0)) {
              // skip
            } else {
              let val = typeof flatFormData[key] !== 'boolean' && flatFormData[key]?.split('.');
              let kname = val.length > 1 ? val[1] : val[0];
              let snum = val.length > 1 ? val[0] : undefined;
              console.log({val, kname, snum})

              let kval = snum && parseInt(snum) === 1 ? sampleData[kname]
                : snum && parseInt(snum) === 2 ? sampleData2[kname]
                  : kname;

              ACTION_DATA = { ...ACTION_DATA, [key]: kval };
              MAPPINGS = snum ?
                (key === 'Id' || key === 'SyncToken')
                  ? [...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum }]
                  : [...MAPPINGS, { type: 'TRIG', field: key, value: kname, stepId: id, valueStepOrder: snum }]

                :
                (key === 'Id')
                  ? [...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: id, valueStepOrder: snum }]
                  : [...MAPPINGS, { type: 'CON', field: key, value: kname, stepId: id }];
            }

            return key
          });
          if (flatFormData.TrackQtyOnHand
            !== undefined && flatFormData.TrackQtyOnHand === true) {
            ACTION_DATA.TrackQtyOnHand = true
            const newmappings = MAPPINGS.find(value => value.field === "TrackQtyOnHand")
            newmappings.value = true;
            newmappings.valueStepOrder = '1'
  
          }
        console.log({ ACTION_DATA, MAPPINGS });
        setActionData(ACTION_DATA);
        setMappings(MAPPINGS);
        next();
      })
      .catch((e) => console.log(e));
  };

  const RoundedSelect = (props) => {
    const { label, placeholder, name, required, specialOptions, specialOptionsOnly, hint, onSelect, disabled } = props;
    return (
      <Col span={22}>
        <div className='label'>
          {label} <span className={required ? 'fr' : 'hidden'}>(required)</span>
        </div>
        <Form.Item name={name} required={required} rules={required ? [requiredRule] : null} >
          <Select disabled = {disabled ? true : false} className='rounded' mode={specialOptions ? null : 'tags'}
            placeholder={placeholder} allowClear onSelect={onSelect}>

            {specialOptions && specialOptions.map((v) => <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>)}

            {!specialOptionsOnly &&
              keys.map(
                (v, k) =>
                  !isObject(sampleData[v]) && (
                    <Select.Option value={`1.${v}`} key={`1.${k}`}>
                      <div className='opt-div'>
                        <span className='key'>{`1.${v}:  `}</span>
                        {`${sampleData[v]}`}
                      </div>
                    </Select.Option>
                  )
              )}
          </Select>
        </Form.Item>
        {hint && <div className='hint'>{hint}</div>}
      </Col>
    );
  };

  return (
    <Row className='padded xinvform'>
      <div className='title'> {returnMap ? 'Return Mapping' : 'Customize Item'} </div>
      <Col span={24}>
        <Form layout='vertical'
          form={form}
          scrollToFirstError={true}
          onFinish={handleSubmit}
          onValuesChange={() => {

            if (form.getFieldsValue().Type === 'Service') {
              form.resetFields(['AssetAccountRef.name', 'AssetAccountRef.value', 'QtyOnHand', 'TrackQtyOnHand','InvStartDate'])
              setIsInventory(false)
            } 
            else {
              setIsInventory(true)
            }
          }}

        >
          <Row hidden={returnMap} justify='center'>
            <RoundedSelect label='Item Type' name='Type' placeholder='Choose one..' specialOptions={typeOptions} specialOptionsOnly />
            {isInventory === true && <RoundedSelect label='Track Quantity On Hand' name='TrackQtyOnHand' placeholder='Type or insert..'specialOptions={boolOptions} specialOptionsOnly required />}
            {isInventory === true && <RoundedSelect label='Qunatity On Hand' name='QtyOnHand' placeholder='Type or insert..' required disabled = {!isInventory}/>}
            {isInventory === true && <RoundedSelect label='Inventory Start Date' name='InvStartDate' placeholder='Type or insert..' required disabled = {!isInventory}/>}
            {isInventory === true && <RoundedSelect label='Asset Account Id' name='AssetAccountRef.value' placeholder='Type or insert..' required disabled = {!isInventory}/>}
            <RoundedSelect label='Name' name='Name' placeholder='Type or insert..' required/>
            <RoundedSelect label='Income Account Id' name='IncomeAccountRef.value' placeholder='Type or insert..' required />
            <RoundedSelect label='Expense Account Id' name='ExpenseAccountRef.value' placeholder='Type or insert..' required />
            <PrimaryButton onClick={() => setReturnMap(true)} className='btn-long' size='large' htmlType='button'>
              Continue
            </PrimaryButton>
          </Row>
          <Row hidden={!returnMap} justify='center'>
            <RoundedSelect
              label='Item Id'
              name='Id'
              placeholder='Type or insert..'
			        required = {true}
            />
            <RoundedSelect
              label='Sync  Token'
              name='SyncToken'
              placeholder='Type or insert..'
              required={true}
            />
            <PrimaryButton className='btn-long' size='large' htmlType='submit'>
              Continue
            </PrimaryButton>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default QuickbooksItemForm;