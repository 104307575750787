import React from 'react';
import { useSelector } from 'react-redux';
import { ArrowDown } from '../../../Commons';
import { AppMap } from '../../../Commons/AppMapping';
import { getLatestWorkflow } from '../../../redux/slices/workflow/latestWorkflow';
import Process from '../../Process';


/*
    Basic 2 Process Workflow
*/
const BasicWorkflowXero = () => {
    const latestWorkflow = useSelector(getLatestWorkflow);
    const steps = AppMap[latestWorkflow?.step1.eventCode]
    console.log({step: latestWorkflow.step1})
    //latestWorkflow.step1.accountAuthorized = true
    return (
        <> 
            {console.log({steps})}
            <Process
                app={{steps: (latestWorkflow.step1.eventCode === "XIUP" || latestWorkflow.step1.eventCode === "XQUP") ? steps.steps.filter((v, i) => i === 0) : steps.steps}}
                stepData={latestWorkflow.step1}
                message='When this happens...'
                order={1}
            />
            <ArrowDown />
            <Process
                app={AppMap[latestWorkflow.step2.eventCode]}
                stepData={latestWorkflow.step2}
                message='then do this'
                order={2}
            />
            {/* <ArrowDown/> */}
            {/* {latestWorkflow.step1.eventCode === "XNREC" ? <>
                <Process
                    app={AppMap[FIND_SFDC_RECORD]}
                    stepData={{
                        appName: 'Salesforce',
                        shortDesc: 'Find Record',
                        appCode: 'SFDC',
                        accountAuthorized: latestWorkflow.step1.accountAuthorized,
                    }}
                    message='then do this'
                    order={2}
                />
            </> : null} */}
            {/* {latestWorkflow.step1.accountAuthorized ? 
            <Process
                // app={{steps:steps.steps.filter((v,i) => i !== 0)}}
                app={{steps:steps.steps}}
                stepData={latestWorkflow.step1}
                message='When this happens...'
                order={2}
            />: null}  */}
        </>
    );
};

export default BasicWorkflowXero;
