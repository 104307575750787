import { api, FormatResponse } from '../index';
import { Invoice } from '../models/invoice.model';
const STRIPE_INVOICE_URL = '/api/v1/stripe/invoices';

export const fetchInvoices = async () => {
        const invoicesRes = await api.get(`${STRIPE_INVOICE_URL}`);
        return new InvoiceListResFormat(invoicesRes);
};

class InvoiceListResFormat extends FormatResponse {
        constructor(response){
                super(response);
                this.data = Invoice.List(this.data);
        }
}