import { api, FormatResponse } from '../index';
import { Transaction } from '../models/Transaction.model';
const BASE_ENDPOINT ='/api/v1/workflows/transactions';

export const fetchTransactions = async () => {
        const response = await api.get(BASE_ENDPOINT);
        return new TransactionResponseFormat(response);
}

class TransactionResponseFormat extends FormatResponse {
        constructor(response){
                super(response);
                this.data = new Transaction(this.data);
        }
}
