import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Row, Col, Select, Form } from 'antd';
import { PrimaryButton } from '../../Commons';
import { isSuccess } from '../../Http';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { keys as keycreate, isObject } from 'lodash';
import './style.scss';
import { requiredRule } from '../../Commons/utils';
import { fetchSalesForceObjects } from '../../Http/salesforce/triggerObject';
import { delegateSaleForce } from '../../Http/salesforce/delegate';

const CreditNoteCustomFindWithTrigger = () => {
    const { appName, next } = useContext(StepContext);
    const { sampleData, setSampleData2, setAdditionalQuery } = useContext(
        WorkflowContext
    );
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [fields, setFields] = useState([]);
    const [keys, setKeys] = useState([]);
    const [form] = Form.useForm();
    const optionsnew = ['Contact.ContactID','Allocations[0].Invoice.InvoiceID']

    /*Load Sobjects from delegate url onLoad */
    // useEffect(() => {
    // 	const salesforceObjectsRes = fetchSalesForceObjects();
    // 	console.log({salesforceObjectsRes})
    // 	if (isSuccess(salesforceObjectsRes)) {
    // 		setOptions(salesforceObjectsRes.data);
    // 		setLoading(false);
    // 		//return;
    // 	}

    // 	// getSalesForceObjects();
    // }, []);


    //old code
    const getSalesForceObjects = useCallback(async () => {
        const salesforceObjectsRes = await fetchSalesForceObjects();
        console.log({ salesforceObjectsRes })
        if (isSuccess(salesforceObjectsRes)) {
            setOptions(salesforceObjectsRes.data);
            setLoading(false);
            return;
        }
        // } else getSalesForceObjects();
    }, []);

    useEffect(() => {
        getSalesForceObjects()
    }, [getSalesForceObjects])
    //old code

    useEffect(() => {
        setKeys(keycreate(sampleData));
    }, [sampleData]);

    const handleSelect = (k) => {
        const params = { uri: options[k].urls.describe };
        delegateSaleForce(params)
            .then((resp) => {
                if (isSuccess(resp)) {
                    let fields = resp.data.fields.map((v) => v.name);
                    setFields(fields);
                }
            })
            .catch((delegateError) => console.log({ delegateError }));
    };


    const handleSubmit = () => {
        form
            .validateFields()
            .then((val) => {
                let strFields = fields.join(',');
                let tableName = options[val.sobjects].name;
                let fieldName = val.field;
                let fieldVal = val.searchValue;

                console.log(val);
                console.log(options[val.sobjects].name)
                const newQuery = `${options[val.sobjects].name},${val.field},${val.searchValue}`

                // let query = `/services/data/v56.0/query/?q=SELECT+${strFields}+FROM+${tableName}+WHERE+${fieldName}+=+'${fieldVal}'`;

                // console.log(query);

                // const params = { uri: `${query}'${sampleData[fieldVal]}'` };

                // console.log(params)
                // delegateSaleForce(params)
                //     .then((resp) => {
                //         console.log(resp);
                //         if (!isSuccess(resp)) return;

                //         let data = resp.data;
                //         if (data && data.totalSize > 0) {
                //             setSampleData2(data.records[0]);

                //         }

                //     })
                //     .catch((delegateError) => console.log({ delegateError }));
                setAdditionalQuery(newQuery);
                next();
            })
            .catch((e) => console.log(e));
    };

    const CustomSelect = (props) => {
        const { title, name, onSelect } = props;
        return (
            <Row>
                <div>
                    {title}
                    <span className='required'>(required)</span>
                </div>
                <Col span={24}>
                    <Form.Item name={name} rules={[requiredRule]}>
                        <Select
                            className='fill-width rounded'
                            loading={loading}
                            onSelect={onSelect}
                            placeholder='Select'
                            showSearch
                            optionFilterProp='children'>
                            {props.children}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    return (
        <StepContainer title='Find Credit Note Parent Record'>
            <Form form={form} layout='horizontal'>
                <CustomSelect
                    name='sobjects'
                    title={`Parent ${appName} Object: `}
                    onSelect={handleSelect}>
                    {options.map((v, k) => (
                        <Select.Option value={k} key={k}>
                            {v.label}
                        </Select.Option>
                    ))}
                </CustomSelect>

                <CustomSelect name='field' title='Parent Field to search by :'>
                    {fields.map((v, k) => (
                        <Select.Option value={v} key={k}>
                            {v}
                        </Select.Option>
                    ))}
                </CustomSelect>
                <CustomSelect name='searchValue' title='Xero field '>
                    {optionsnew.map((v, k) => (
                        <Select.Option value={v} key={k}>
                            {v}
                        </Select.Option>
                    ))}
                </CustomSelect>

                {/* <CustomSelect name='searchValue' title='Search value :'>
                    {keys.map(
                        (v, k) =>
                            !isObject(sampleData[v]) && (
                                <Select.Option value={v} key={k}>
                                    <div className='opt-div'>
                                        <span className='key'>{`1.${v}:  `}</span>
                                        {`${sampleData[v]}`}
                                    </div>
                                </Select.Option>
                            )
                    )}
                </CustomSelect> */}
            </Form>

            <Row justify='center' className='mt-2'>
                <PrimaryButton className='btn-long' size='large' onClick={handleSubmit}>
                    Continue
                </PrimaryButton>
            </Row>
        </StepContainer>
    );
};

export default CreditNoteCustomFindWithTrigger;
