import React, { useEffect, useState, useContext } from 'react';
import { Row, Select } from 'antd';
import { PrimaryButton } from '../../Commons';
import { StepContext, WorkflowContext } from '../../Commons/Context';
import StepContainer from '../../Components/StepContainer';
import { fetchSalesForceObjects, fetchSalesForceStepObject } from '../../Http/salesforce/triggerObject';
import { isSuccess } from '../../Http';
const CreditNoteSelectTriggerObject = () => {
    const { SObject, setSObject } = useContext(WorkflowContext);
    const { appName, next, id } = useContext(StepContext);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [objectName, setObjectName] = useState(
        // SObject ? SObject.name : undefined
    ); //default for this layout



    useEffect(() => {
        if (SObject && SObject?.name){
            const newname = SObject?.name.split("|").length > 1 ? SObject.name.split("|")[0] : SObject?.name
            setObjectName(newname)
        }else{
            setObjectName("")
        }
    }, [SObject])

    //1.Get all salesforce objects
    useEffect(() => {
        getSalesForceObjects();
    }, []);

    const getSalesForceObjects = async () => {
        const saleforceObjectRes = await fetchSalesForceObjects();
        console.log({ saleforceObjectRes });
        if (!isSuccess(saleforceObjectRes)) {
            getSalesForceObjects();
            return;
        }
        setOptions(saleforceObjectRes.data);
        setLoading(false);
    };

    //2. When retrieved, fetch the old selected sobject if any
    useEffect(() => {
        if (!SObject && options.length > 0) getSalesForceStepObjects();
    }, [options]);

    const getSalesForceStepObjects = async () => {
        const saleforceStepObjectRes = await fetchSalesForceStepObject(id);
        console.log({ saleforceStepObjectRes });
        if (isSuccess(saleforceStepObjectRes)) {
            let objname = saleforceStepObjectRes.data.sobject;
            if (objname) handleSelect(objname);
            setObjectName(objname);
        }
    };


    const handleSelect = (name) => {
        const newname = name.split("|").length > 1 ? name.split("|")[0] : name
        console.log(name);
        console.log({ options });
        let k = options.findIndex((v) => newname === v.name);
        console.log({optionname: options[k]});
        console.log({option: options[k]});
        setObjectName(options[k]?.name);
        setSObject(options[k]);
    };


    return (
        <StepContainer title='Select Trigger Object'>
            <div>
                {`${appName} Parent Object: `} <span className='required'>(required)</span>
            </div>

            <Row justify='center'>
                <Select
                    className='fill-width rounded'
                    loading={loading}
                    onSelect={handleSelect}
                    placeholder='Select'
                    showSearch
                    value={objectName}
                    optionFilterProp='children'>
                    {options.map((v, k) => (
                        <Select.Option value={v.name} key={k}>
                            {v.label}
                        </Select.Option>
                    ))}
                </Select>
            </Row>

            <Row justify='center'>
                <PrimaryButton
                    className='btn-long'
                    size='large'
                    onClick={() => {
                        if (SObject) next();
                    }}>
                    Continue
                </PrimaryButton>
            </Row>
        </StepContainer>
    );
};

export default CreditNoteSelectTriggerObject;
