import React from 'react';
import { Column } from '@ant-design/plots';

const ColumnChart = ({data, xField, yField, sliderStart, sliderEnd}) => {
              
              const config = {
                data: data ? data : [],
                xField,
                yField,
                label: {
                  // 可手动配置 label 数据标签位置
                  position: 'middle',
                  // 'top', 'bottom', 'middle',
                  // 配置样式
                  style: {
                    fill: '#FFFFFF',
                    opacity: 0.6,
                  },
                },
                xAxis: {
                  label: {
                    autoHide: true,
                    autoRotate: false,
                  },
                },
                slider: {
                  start: sliderStart,
                  end: sliderEnd,
                },
                meta: {
                  type: {
                    alias: '类别',
                  },
                  sales: {
                    alias: '销售额',
                  },
                },
              };
              return <Column {...config} />;
};

export default ColumnChart;