import { useEffect, useState } from 'react'
import axios from 'axios';

function useIpAddress() {
    const [ip, setIp] = useState('');
    useEffect(()=>{
        axios.get('https://api.ipify.org/?format=json')
        .then(res=>setIp(res?.data.ip));

    },[])
  return [ip]
}

export default useIpAddress