import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrowDown } from '../../../Commons';
import { getLatestWorkflow } from '../../../redux/slices/workflow/latestWorkflow';
import Process from '../../Process';
import { getActionApp, getTriggerApp } from '../../Quickbooks/utils/quickbooksUtils';


/*
    Basic 2 Process Workflow
*/
const BasicWorkflowQuickbooks = () => {
    const latestWorkflow = useSelector(getLatestWorkflow);
    return (
        <> 
            <Process
                app={getTriggerApp(latestWorkflow.step1.eventCode)}
                stepData={latestWorkflow.step1}
                message='When this happens...'
                order={1}
            />
            <ArrowDown />
            <Process
                app={getActionApp(latestWorkflow.step1.eventCode)}
                stepData={latestWorkflow.step2}
                message='then do this'
                order={2}
            />
        </>
    );
};

export default BasicWorkflowQuickbooks;
